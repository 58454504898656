import "../../App.css";
import { get, post } from "../../utils/http";
import { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { CompDomicilio, CompIngresos } from "../../components/Cards";
import {
  ModalVerificarRegistroCrediteka,
  ModalVerificarEdad,
  ModalLoading,
  ModalErrorVerificar,
  ModalRespuestaPreAutorizaSinBancos,
} from "../../components/Modals";
import { useSetDataPublic } from "../../providers/dataPublic/PublicProvider";
import { AprobadoLogueado } from "../../components/Msjs";
import { obtEmpresaUrl } from "../../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <--------------------------------------------- Pantalla con cuestionario preAutoriza -------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function InformacionGeneral() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const actionSetDataPublic = useSetDataPublic();
  const { handleSubmit } = useForm();
  const [tipoProyecto, setTipoProyecto] = useState();
  const [etapaProyecto, setEtapaProyecto] = useState();
  const [actividadEconomica, setActividadEconomica] = useState();
  const [banco, setBanco] = useState();
  const [estado, setEstado] = useState();
  const [creditoAdicional, setCreditoAdicional] = useState(null);
  const [creditoConyugue, setCreditoConyugue] = useState(null);
  const [beneficioAfiliacionMonto, setBeneficioAfiliacionMonto] =
    useState(null);
  const [creditoAdicionalNombre, setCreditoAdicionalNombre] = useState(null);
  const [sumarIngresos, setSumarIngresos] = useState();
  const [sumarIngresosNombre, setSumarIngresosNombre] = useState(null);
  const [demostrarIngresos, setDemostrarIngresos] = useState();
  const [demostrarIngresosCoacreditado, setDemostrarIngresosCoacreditado] =
    useState(null);
  const [ingresoDemostrableCoacreditado, setIngresoDemostrableCoacreditado] =
    useState(null);
  const [valorEstimadoInmueble, setValorEstimadoInmueble] = useState();
  const [ahorroCubrirEnganche, setAhorroCubrirEnganche] = useState();
  const [isOpenModalSinBancos, setIsOpenModalSinBancos] = useState(false);
  const [sinBancoId, setSinBancoId] = useState();
  const [referencia] = useState("Saturn 5");
  const [
    isOpenModalVerificarRegistroCrediteka,
    setIsOpenModalVerificarRegistroCrediteka,
  ] = useState(false);
  const [isOpenModalVerificarEdad, setIsOpenModalVerificarEdad] =
    useState(false);
  const [edadActual, setEdadActual] = useState();
  const [plazoSeleccionado, setPlazoSeleccionado] = useState();
  const [mostrarMsgAceptado, setMostrarMsgAceptado] = useState(false);
  const [plazoCotizacion, setPlazoCotizacion] = useState();
  const [valorPropiedad, setValorPropiedad] = useState();
  const [engancheMinimo, setEngancheMinimo] = useState(false);
  const [porcentajeProyecto, setPorcentajeProyecto] = useState(false);
  const [obtenerLiquidez, setObtenerLiquidez] = useState(false);
  const [plazoPermitidoTipoProyecto, setPlazoPermitidoTipoProyecto] =
    useState(true);

  const [isOpenModalError, setIsOpenModalError] = useState(false);
  const [isOpenModalLoading, setIsOpenModalLoadiing] = useState(false);
  const navigate = useNavigate();

  // <---------- Aceptar crédito Infonavit o Fovissste ---------->
  function AceptarCreditoFormulario() {
    const seleccion = document.getElementById("aceptarCreditoFormulario");
    seleccion.className = "btn btn_morado";
    const seleccion2 = document.getElementById("negarCreditoFormulario");
    seleccion2.className = "button_YN button";
    const seleccion3 = document.getElementById("creditoAdicionalNobre");
    seleccion3.className = "col3 campo-form estado marginEspacio";
    const seleccion4 = document.getElementById("selectCreditoAdicional");
    seleccion4.required = true;
    const seleccion5 = document.getElementById("beneficioAfiliacionMonto");
    seleccion5.className = "col3 campo-form estado marginEspacio";
    setCreditoAdicionalNombre("");
    setBeneficioAfiliacionMonto("");
  }

  // <---------- No aceptar crédito Infonavit o Fovissste ---------->
  function NegarCreditoFormulario() {
    const seleccion = document.getElementById("negarCreditoFormulario");
    seleccion.className = "btn btn_morado";
    const seleccion2 = document.getElementById("aceptarCreditoFormulario");
    seleccion2.className = "button_YN button";
    const seleccion3 = document.getElementById("creditoAdicionalNobre");
    seleccion3.className = "ocultarDiv";
    const seleccion4 = document.getElementById("selectCreditoAdicional");
    seleccion4.required = false;
    const seleccion5 = document.getElementById("beneficioAfiliacionMonto");
    seleccion5.className = "ocultarDiv";
    setCreditoAdicionalNombre("");
    setBeneficioAfiliacionMonto("");
  }

  // <---------- Aceptar coacreditado ---------->
  function AceptarCoacreditadoFormulario() {
    const seleccion = document.getElementById("AceptarCoacreditadoFormulario");
    seleccion.className = "btn btn_morado";
    const seleccion2 = document.getElementById("NegarCoacreditadoFormulario");
    seleccion2.className = "button_YN button";
    const seleccion3 = document.getElementById("sumarIngresosNombre");
    seleccion3.className = "col3 campo-form estado marginEspacio";
    const seleccion4 = document.getElementById("selectSumarIngresosNombre");
    seleccion4.required = true;
    const seleccion5 = document.getElementById("DemostrarIngresosCoacreditado");
    seleccion5.className = "col3 campo-form estado marginEspacio";
    const seleccion6 = document.getElementById(
      "ingresosDemostrablesCoacreditado"
    );
    seleccion6.className = "col3 campo-form estado marginEspacio";
    setCreditoAdicional(1);
    setCreditoConyugue(1);
    setSumarIngresos(1);
    setDemostrarIngresosCoacreditado("");
    setIngresoDemostrableCoacreditado("");
  }

  // <---------- No aceptar coacreditado ---------->
  function NegarCoacreditadoFormulario() {
    const seleccion = document.getElementById("NegarCoacreditadoFormulario");
    seleccion.className = "btn btn_morado";
    const seleccion2 = document.getElementById("AceptarCoacreditadoFormulario");
    seleccion2.className = "button_YN button";
    const seleccion3 = document.getElementById("sumarIngresosNombre");
    seleccion3.className = "ocultarDiv";
    const seleccion4 = document.getElementById("selectSumarIngresosNombre");
    seleccion4.required = false;
    const seleccion5 = document.getElementById("DemostrarIngresosCoacreditado");
    seleccion5.className = "ocultarDiv";
    const seleccion6 = document.getElementById(
      "ingresosDemostrablesCoacreditado"
    );
    seleccion6.className = "ocultarDiv";
    setCreditoAdicional(0);
    setCreditoConyugue(0);
    setSumarIngresos(0);
    setDemostrarIngresosCoacreditado("");
    setIngresoDemostrableCoacreditado("");
  }

  function verificarAsalariada(valor) {
    if (valor === "1") {
      serAsalariado();
    } else {
      NoSerAsalariado();
      NegarCreditoFormulario();
    }
  }
  // <---------- Ser asalariado ---------->
  function serAsalariado() {
    const seleccion = document.getElementById("creditoVivienda");
    seleccion.className = "col3 campo-form estado marginEspacio";
  }

  // <---------- No ser  asalariado ---------->
  function NoSerAsalariado() {
    const seleccion = document.getElementById("creditoVivienda");
    seleccion.className = "ocultarDiv";
  }

  function FuncionAuxiliar() {
    setIsOpenModalVerificarRegistroCrediteka(false);
    setTimeout(() => {
      VerificarRegistroCredi();
    }, 500);
  }

  function EnviarFormulario() {
    setIsOpenModalError(false);
    setTimeout(() => {
      bancos();
    }, 500);
  }

  function calcularEdad(fecha) {
    let hoy = new Date();
    let cumpleanos = new Date(fecha);
    let edad = hoy.getFullYear() - cumpleanos.getFullYear();
    let m = hoy.getMonth() + 1 - cumpleanos.getMonth() + 1;
    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }
    return edad;
  }

  function porcentajeAhorro(sueldo, tipoProyecto2) {
    let ahorro = parseInt(
      sueldo
        .replaceAll("$ ", "")
        .replaceAll(" MXN", "")
        .replaceAll(".", "")
        .replaceAll(",", "")
    );
    let casa = parseInt(
      valorEstimadoInmueble
        .replaceAll("$ ", "")
        .replaceAll(" MXN", "")
        .replaceAll(".", "")
        .replaceAll(",", "")
    );
    let minimoEnganche;

    if (tipoProyecto2 === "1" || tipoProyecto2 === "3") {
      minimoEnganche = casa * 0.1;
      setPorcentajeProyecto(10);
    } else if (tipoProyecto2 === "2") {
      minimoEnganche = casa * 0.2;
      setPorcentajeProyecto(20);
    } else if (tipoProyecto2 === "4") {
      minimoEnganche = casa * 0.3;
      setPorcentajeProyecto(30);
    }

    if (ahorro < minimoEnganche) {
      setEngancheMinimo(true);
    } else {
      setEngancheMinimo(false);
    }
  }

  const VerificarLiquidez = async () => {
    let dataUser = await get("infoUser", cookies.get("correo"));
    let dataCotizacion = await get(
      "obtDatosCotizacionPorId",
      parseInt(localStorage.getItem("idResPaso1Cuestionario"))
    );
    let edadCalculada = calcularEdad(dataUser.user.fNacimiento);
    let plazo = dataCotizacion.cotizacion.plazo;
    let totalPlazoEdad = parseInt(edadCalculada) + parseInt(plazo);

    if (totalPlazoEdad <= 80) {
      setObtenerLiquidez(true);
    } else {
      setObtenerLiquidez(false);
    }
  };

  const VerificarRegistroCredi = async () => {
    let verificarRegistro = await get(
      "verificarRegistroUsuarioCrediteka",
      cookies.get("id")
    );
    if (verificarRegistro.statuscode === 200) {
      setIsOpenModalVerificarRegistroCrediteka(false);
      getInfoCatalogos();
    } else {
      setIsOpenModalVerificarRegistroCrediteka(false);
    }
  };

  let actividades = null,
    bancos = null,
    demuestraIngresos = null,
    proyectos = null,
    etapas = null,
    estados = null,
    creditosAdicionales = null,
    catSumIngresos = null;

  // <---------- Obtener catalogos desde base de datos y renderizar inputs ---------->
  const getInfoCatalogos = async () => {
    let catalogos = await get("getCatalogosPrecalifica", "");
    if (catalogos.statuscode === 200) {
      let root;
      let opcionDefault = (
        <option value="" selected="selected" disabled>
          Seleccione una opción...{" "}
        </option>
      );

      actividades = catalogos.catalogosPrecalifica.actividadesEconomicas.map(
        (proyecto) => (
          <option
            value={proyecto.idActividadEconomica}
            key={proyecto.idActividadEconomica}
          >
            {proyecto.nombreActividad}
          </option>
        )
      );
      actividades.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectActividadEconomica")
      );
      root.render(actividades);

      bancos = catalogos.catalogosPrecalifica.bancos.map((proyecto) => (
        <option value={proyecto.idBanco} key={proyecto.idBanco}>
          {proyecto.nombreBanco}
        </option>
      ));
      bancos.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectBanco"));
      root.render(bancos);

      demuestraIngresos = catalogos.catalogosPrecalifica.demoCoIngresos.map(
        (proyecto) => (
          <option
            value={proyecto.idDemoCoIngreso}
            key={proyecto.idDemoCoIngreso}
          >
            {proyecto.nombreDemoCi}
          </option>
        )
      );
      demuestraIngresos.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectDemostrarIngresos")
      );
      root.render(demuestraIngresos);

      creditosAdicionales =
        catalogos.catalogosPrecalifica.creditosAdicionales.map((proyecto) => (
          <option value={proyecto.idCredito} key={proyecto.idCredito}>
            {proyecto.nombreCredito}
          </option>
        ));
      creditosAdicionales.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectCreditoAdicional")
      );
      root.render(creditosAdicionales);

      estados = catalogos.catalogosPrecalifica.estados.map((proyecto) => (
        <option value={proyecto.idEstado} key={proyecto.idEstado}>
          {proyecto.nombre}
        </option>
      ));
      estados.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectEstado"));
      root.render(estados);

      proyectos = catalogos.catalogosPrecalifica.tiposPRoyectos.map(
        (proyecto) => (
          <option value={proyecto.idTipoProyecto} key={proyecto.idTipoProyecto}>
            {proyecto.nombreTproyecto}
          </option>
        )
      );
      proyectos.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectTipoProyecto"));
      root.render(proyectos);

      etapas = catalogos.catalogosPrecalifica.etapasProyectos.map(
        (proyecto) => (
          <option
            value={proyecto.idEtapaProyecto}
            key={proyecto.idEtapaProyecto}
          >
            {proyecto.nombreEtapa}
          </option>
        )
      );
      etapas.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectEtapaProyecto")
      );
      root.render(etapas);

      catSumIngresos = catalogos.catalogosPrecalifica.catSumIngresos.map(
        (proyecto) => (
          <option value={proyecto.idSumIngresos} key={proyecto.idSumIngresos}>
            {proyecto.nombreSumIngresos}
          </option>
        )
      );
      catSumIngresos.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectSumarIngresosNombre")
      );
      root.render(catSumIngresos);

      demuestraIngresos = catalogos.catalogosPrecalifica.demoCoIngresos.map(
        (proyecto) => (
          <option
            value={proyecto.idDemoCoIngreso}
            key={proyecto.idDemoCoIngreso}
          >
            {proyecto.nombreDemoCi}
          </option>
        )
      );
      demuestraIngresos.unshift(opcionDefault);
      root = ReactDOM.createRoot(
        document.getElementById("selectDemostrarIngresosCoacreditado")
      );
      root.render(demuestraIngresos);
    }
  };

  const checarRespuestaBuro = async () => {
    let checkBuro;
    if (localStorage.getItem("idResPaso1Cuestionario") === null) {
      let obtIdResP1porAPI = await get("userCotizaciones", cookies.get("id"));
      if (obtIdResP1porAPI.statucode === 200) {
        checkBuro = parseInt(obtIdResP1porAPI.cotizaciones[0].id);
        setPlazoCotizacion(obtIdResP1porAPI.cotizaciones[0].plazo);
        setValorPropiedad(obtIdResP1porAPI.cotizaciones[0].valorPropiedad);
      }
    } else {
      checkBuro = parseInt(localStorage.getItem("idResPaso1Cuestionario"));
      let obtIdResP1porAPI = await get("obtDatosCotizacionPorId", checkBuro);
      if (obtIdResP1porAPI.statuscode === 200) {
        setPlazoCotizacion(obtIdResP1porAPI.cotizacion.plazo);
        setValorPropiedad(obtIdResP1porAPI.cotizacion.valorPropiedad);
      }
    }

    let responseBtBuro = await get(
      "obtDatosPaso2PorIdResPaso1",
      parseInt(checkBuro)
    );
    if (responseBtBuro.statuscode === 200) {
      setMostrarMsgAceptado(true);
    } else {
      setMostrarMsgAceptado(false);
    }

    getInfoCatalogos();
  };

  const obtDatosCotizacion = async () => {
    let cotizacion = await get(
      "obtDatosCotizacionPorId",
      localStorage.getItem("idResPaso1Cuestionario")
    );

    let valorcasa = new Intl.NumberFormat("es-MX").format(
      cotizacion.cotizacion.valorPropiedad
    );
    let valorenganche = new Intl.NumberFormat("es-MX").format(
      cotizacion.cotizacion.enganche
    );

    setValorEstimadoInmueble("$ " + valorcasa + " MXN");
    setAhorroCubrirEnganche("$ " + valorenganche + " MXN");
  };

  function egancheProyecto(tipo) {
    setTipoProyecto(tipo);
    VerificarLiquidez();
    porcentajeAhorro(ahorroCubrirEnganche, tipo);

    if (
      (plazoCotizacion === 20 && tipo === "4") ||
      (plazoCotizacion === 20 && tipo === "2")
    ) {
      setPlazoPermitidoTipoProyecto(false);
    } else {
      setPlazoPermitidoTipoProyecto(true);
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    VerificarLiquidez();
    checarRespuestaBuro();
    VerificarRegistroCredi();
    obtDatosCotizacion();
  }, []);

  // <---------- Enviar cuestionario preAutoriza ---------->
  const enviarCuestionario = async () => {
    if (
      creditoConyugue != null &&
      creditoAdicional != null &&
      engancheMinimo === false &&
      plazoPermitidoTipoProyecto === true
    ) {
      setIsOpenModalLoadiing(true);

      let idP1;
      if (localStorage.getItem("idResPaso1Cuestionario") === null) {
        let obtIdResP1porAPI = await get("userCotizaciones", cookies.get("id"));
        if (obtIdResP1porAPI.statucode === 200) {
          idP1 = parseInt(obtIdResP1porAPI.cotizaciones[0].id);
          localStorage.setItem(
            "plazoVal",
            obtIdResP1porAPI.cotizaciones[0].plazo
          );
        }
      } else {
        idP1 = parseInt(localStorage.getItem("idResPaso1Cuestionario"));
      }

      let dataCuestionario = {};
      dataCuestionario.solicitud = {
        nameAgent: "Saturn 5",
        emailAgent: "contacto@saturn5.mx",
        realStateDevelopment: "Desarrollo inmobiliario",
        mobileAgent: "0000000000",
        afiliacion:
          creditoAdicionalNombre === null || creditoAdicionalNombre === ""
            ? 0
            : parseInt(creditoAdicionalNombre), //a todos los que son nulo les agrego un tercer igual y ''
        beneficio_afiliacion:
          beneficioAfiliacionMonto === null || beneficioAfiliacionMonto === ""
            ? 0
            : parseInt(
                beneficioAfiliacionMonto
                  .replaceAll("$ ", "")
                  .replaceAll(" MXN", "")
                  .replaceAll(".", "")
                  .replaceAll(",", "")
              ),
        con_coacreditado: creditoConyugue,
        quien:
          sumarIngresosNombre === null || sumarIngresosNombre === ""
            ? 0
            : parseInt(sumarIngresosNombre),
        valor_credito: parseInt(
          valorEstimadoInmueble
            .replaceAll("$ ", "")
            .replaceAll(" MXN", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
        ),
        ahorro_credito: parseInt(
          ahorroCubrirEnganche
            .replaceAll("$ ", "")
            .replaceAll(" MXN", "")
            .replaceAll(".", "")
            .replaceAll(",", "")
        ),
        demuestro_ingresos_coa:
          demostrarIngresosCoacreditado === null ||
          demostrarIngresosCoacreditado === ""
            ? 0
            : parseInt(demostrarIngresosCoacreditado),
        tile: "string",
        conBuro: false,
      };
      dataCuestionario.cuestionario = {
        idUser: parseInt(cookies.get("id")),
        idTipoProyecto: parseInt(tipoProyecto),
        idEtapaProyecto: parseInt(etapaProyecto),
        idActividadEconomica: parseInt(actividadEconomica),
        idBanco: parseInt(banco),
        idEstado: parseInt(estado),
        idCredito: creditoAdicional == null ? 0 : parseInt(creditoAdicional),
        idDemoCI: demostrarIngresos == null ? 0 : parseInt(demostrarIngresos),
        sumaIngresos: Boolean(sumarIngresos),
        coIngreso:
          ingresoDemostrableCoacreditado == null ||
          ingresoDemostrableCoacreditado === ""
            ? 0
            : parseInt(
                ingresoDemostrableCoacreditado
                  .replaceAll("$ ", "")
                  .replaceAll(" MXN", "")
                  .replaceAll(".", "")
                  .replaceAll(",", "")
              ),
        referencia: referencia,
        idResPaso1: idP1,
      };
      dataCuestionario.idUser = parseInt(cookies.get("id"));

      //console.log("Información enviada:  ", dataCuestionario);
      let dataPost = await post("agregarCuestionario", dataCuestionario);

      if (dataPost.statuscode === 200) {
        let pasarIdCuestionario = await get(
          "obtIdCuestionarioLlenarPreAutoriza",
          dataPost.cuestionarioId
        );
        if (pasarIdCuestionario.statuscode === 200) {
          actionSetDataPublic({
            cuestionarioId: pasarIdCuestionario.idCuestionario,
          });

          //localStorage.removeItem("idResPaso1Cuestionario")
          setIsOpenModalLoadiing(false);
          Swal.fire({
            icon: "success",
            title: "¡Listo! Ya tenemos tus resultados.",
            text: "Datos guardados correctamente",
            showCancelButton: false,
            confirmButtonText: "Ver ofertas disponibles",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/Bancos"); //solo cambia la ventana
            }
          });
        }
      } else if (dataPost.statuscode === 400) {
        setIsOpenModalError(true);
        setIsOpenModalLoadiing(false);
      } else if (dataPost.statuscode === 201) {
        setIsOpenModalLoadiing(false);

        let responseMsg = await get(
          "obtResPrecalificaSinBancosPorIdCuestionario",
          dataPost.idCuestionario
        );
        if (responseMsg.statucode === 200) {
          setSinBancoId(responseMsg.msg);
          setIsOpenModalSinBancos(true);
        }
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <p>
          {" "}
          <Link to="/cotizaciones" className="link linkGrande">
            {" "}
            {"<"} Regresar a mis cotizaciones
          </Link>{" "}
        </p>
        {mostrarMsgAceptado === true ? <AprobadoLogueado /> : false}
        <br />
        <br />
        <h1 className="titt">Para brindarte las mejores opciones... </h1>
        <p>
          Necesitamos conocerte mejor. Ayúdanos a completar el siguiente
          cuestionario..
        </p>
        <form onSubmit={handleSubmit(enviarCuestionario)}>
          <div className="Resum">
            <div className="header">
              <h3>Sobre ti</h3>
            </div>
            <div className="cont_flex cont-form formInfoGeneral">
              <div className="col3 campo-form estado marginEspacio">
                <label>¿Qué tipo de proyecto es? </label>
                <select
                  name="selectTipoProyecto"
                  id="selectTipoProyecto"
                  value={tipoProyecto}
                  onChange={(ev) => {
                    egancheProyecto(ev.target.value);
                  }}
                  required
                ></select>
                {obtenerLiquidez === false && tipoProyecto === "4" && (
                  <small className="span">
                    Al seleccionar esta opción, probablemente su solicitud sea
                    rechazada, la suma del plazo más su edad debe de ser menor o
                    igual a 80 años.{" "}
                  </small>
                )}
                {plazoPermitidoTipoProyecto === false && (
                  <small className="span">
                    {" "}
                    Esta opción solo se puede seleccionar con los plazos 5, 10 y
                    15 años.{" "}
                  </small>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿En qué etapa de tu proyecto te encuentras?</label>
                <select
                  name="selectEtapaProyecto"
                  id="selectEtapaProyecto"
                  value={etapaProyecto}
                  onChange={(ev) => {
                    setEtapaProyecto(ev.target.value);
                  }}
                  required
                ></select>
              </div>
              <div
                className="col3 campo-form estado marginEspacio"
                id="actividadEconomica"
              >
                <label>¿Cuál es tu actividad económica?</label>
                <select
                  name="selectActividadEconomica"
                  id="selectActividadEconomica"
                  value={actividadEconomica}
                  onChange={(ev) => {
                    setActividadEconomica(ev.target.value);
                    verificarAsalariada(ev.target.value);
                  }}
                  required
                ></select>
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿Cómo demuestras tus ingresos?</label>
                <select
                  name="selectDemostrarIngresos"
                  id="selectDemostrarIngresos"
                  value={demostrarIngresos}
                  onChange={(ev) => {
                    setDemostrarIngresos(ev.target.value);
                  }}
                  required
                >
                  {/* ... (opciones cargadas desde la BD) */}
                </select>
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿De qué banco eres cliente?</label>
                <select
                  name="selectBanco"
                  id="selectBanco"
                  value={banco}
                  onChange={(ev) => {
                    setBanco(ev.target.value);
                  }}
                  required
                ></select>
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>¿En qué estado vas a comprar?</label>
                <select
                  name="selectEstado"
                  id="selectEstado"
                  value={estado}
                  onChange={(ev) => {
                    setEstado(ev.target.value);
                  }}
                  required
                ></select>
              </div>
              <div
                className="col3 campo-form estado marginEspacio"
                id="creditoVivienda"
              >
                <label>
                  ¿Desea ocupar el credito de vivienda del Infonavit o
                  Fovissste?{" "}
                  {creditoAdicional == null && (
                    <span className="span"> Requerido</span>
                  )}
                </label>
                <div className="botones" name="DeseaCreditoViviendaIF">
                  <button
                    type="button"
                    id="aceptarCreditoFormulario"
                    className="button_YN button"
                    onClick={AceptarCreditoFormulario}
                  >
                    {" "}
                    Si
                  </button>
                  <button
                    type="button"
                    id="negarCreditoFormulario"
                    className="button_YN button btn_A"
                    onClick={NegarCreditoFormulario}
                  >
                    {" "}
                    No
                  </button>
                </div>
              </div>
              <div
                id="creditoAdicionalNobre"
                name="creditoAdicionalNobre"
                className="col3 campo-form estado marginEspacio ocultarDiv"
              >
                <label>¿Cúal credito desea ocupar?</label>
                <select
                  name="selectCreditoAdicional"
                  id="selectCreditoAdicional"
                  value={creditoAdicionalNombre}
                  onChange={(ev) => {
                    setCreditoAdicionalNombre(ev.target.value);
                  }}
                ></select>
              </div>
              <div
                id="beneficioAfiliacionMonto"
                name="beneficioAfiliacionMonto"
                className="col3 campo-form estado marginEspacio ocultarDiv"
              >
                <label>Monto disponible de su credito</label>
                <input
                  align="right"
                  type="tel"
                  name="beneficioAfiliacionMonto"
                  id="beneficioAfiliacionMonto"
                  style={{ textAlign: "right", marginBottom: "5px" }}
                  value={beneficioAfiliacionMonto}
                  onKeyDown={(ev) => {
                    if (ev.key === "Backspace") {
                      let sueldo = ev.target.value;
                      sueldo = sueldo.replaceAll(",", "");
                      sueldo = sueldo.replace(" MXN", "");
                      sueldo = sueldo.replace(" MX", "");
                      sueldo = sueldo.replace(" M", "");
                      sueldo = sueldo.replaceAll(" ", "");
                      sueldo = sueldo.replace("$", "");
                      sueldo = sueldo.slice(0, sueldo.length - 1);
                      setBeneficioAfiliacionMonto("$ " + sueldo + " MXN");
                    }
                  }}
                  onInput={(ev) => {
                    let sueldo = ev.target.value;
                    sueldo = sueldo.replaceAll(",", "");
                    sueldo = sueldo.replace(" MXN", "");
                    sueldo = sueldo.replace(" MX", "");
                    sueldo = sueldo.replace(" M", "");
                    sueldo = sueldo.replaceAll(" ", "");
                    sueldo = sueldo.replace("$", "");
                    if (sueldo === 0 || sueldo === "") {
                      setBeneficioAfiliacionMonto("");
                    } else {
                      sueldo = new Intl.NumberFormat("es-MX").format(sueldo);
                      if (sueldo !== "NaN") {
                        setBeneficioAfiliacionMonto("$ " + sueldo + " MXN");
                      }
                    }
                  }}
                />
              </div>
              <div className="col3 campo-form estado marginEspacio ">
                <label>
                  ¿Deseas sumar ingresos con alguien para contar un crédito
                  mayor?{" "}
                  {creditoConyugue == null && (
                    <span className="span"> Requerido</span>
                  )}
                </label>
                <div className="botones">
                  <button
                    type="button"
                    id="AceptarCoacreditadoFormulario"
                    className="button_YN button"
                    onClick={AceptarCoacreditadoFormulario}
                  >
                    {" "}
                    Si
                  </button>
                  <button
                    type="button"
                    id="NegarCoacreditadoFormulario"
                    className="button_YN button "
                    onClick={NegarCoacreditadoFormulario}
                  >
                    {" "}
                    No
                  </button>
                </div>
              </div>
              <div
                id="sumarIngresosNombre"
                name="sumarIngresosNombre"
                className="col3 campo-form estado marginEspacio ocultarDiv"
              >
                <label>¿Con qui&eacute;n deseas sumar ingresos?</label>
                <select
                  name="selectSumarIngresosNombre"
                  id="selectSumarIngresosNombre"
                  value={sumarIngresosNombre}
                  onChange={(ev) => {
                    setSumarIngresosNombre(ev.target.value);
                  }}
                ></select>
              </div>
              <div
                id="DemostrarIngresosCoacreditado"
                className="col3 campo-form estado marginEspacio ocultarDiv"
              >
                <label>¿Cómo demuestra ingresos tu coacreditado?</label>
                <select
                  name="selectDemostrarIngresosCoacreditado"
                  id="selectDemostrarIngresosCoacreditado"
                  value={demostrarIngresosCoacreditado}
                  onChange={(ev) => {
                    setDemostrarIngresosCoacreditado(ev.target.value);
                  }}
                ></select>
              </div>
              <div
                id="ingresosDemostrablesCoacreditado"
                className="col3 campo-form marginEspacio ocultarDiv"
              >
                <label>
                  ¿Cuál es el ingreso demostrable de tu coacreditado?
                </label>
                <input
                  align="right"
                  type="tel"
                  name="ingresosDemostrablesCoacreditado"
                  id="ingresosDemostrablesCoacreditado"
                  style={{ textAlign: "right", marginBottom: "5px" }}
                  value={ingresoDemostrableCoacreditado}
                  onKeyDown={(ev) => {
                    if (ev.key === "Backspace") {
                      let sueldo = ev.target.value;
                      sueldo = sueldo.replaceAll(",", "");
                      sueldo = sueldo.replace(" MXN", "");
                      sueldo = sueldo.replace(" MX", "");
                      sueldo = sueldo.replace(" M", "");
                      sueldo = sueldo.replaceAll(" ", "");
                      sueldo = sueldo.replace("$", "");
                      sueldo = sueldo.slice(0, sueldo.length - 1);
                      setIngresoDemostrableCoacreditado("$ " + sueldo + " MXN");
                    }
                  }}
                  onInput={(ev) => {
                    let sueldo = ev.target.value;
                    sueldo = sueldo.replaceAll(",", "");
                    sueldo = sueldo.replace(" MXN", "");
                    sueldo = sueldo.replace(" MX", "");
                    sueldo = sueldo.replace(" M", "");
                    sueldo = sueldo.replaceAll(" ", "");
                    sueldo = sueldo.replace("$", "");
                    if (sueldo === 0 || sueldo === "") {
                      setIngresoDemostrableCoacreditado("");
                    } else {
                      sueldo = new Intl.NumberFormat("es-MX").format(sueldo);
                      if (sueldo !== "NaN") {
                        setIngresoDemostrableCoacreditado(
                          "$ " + sueldo + " MXN"
                        );
                      }
                    }
                  }}
                />
              </div>
            </div>
          </div>

          <div className="header">
            <h3>Confirma los datos para tu crédito</h3>
            <hr />
          </div>
          <div className="cont_flex cont-form formInfoGeneral">
            <div className="col3 campo-form estado marginEspacio">
              <label>¿Cuál es el valor estimado del inmueble que deseas?</label>
              <input
                align="right"
                type="tel"
                name="valorEstimadoInmueble"
                id="valorEstimadoInmueble"
                required
                style={{ textAlign: "right", marginBottom: "5px" }}
                value={valorEstimadoInmueble}
                onKeyDown={(ev) => {
                  if (ev.key === "Backspace") {
                    let sueldo = ev.target.value;
                    sueldo = sueldo.replaceAll(",", "");
                    sueldo = sueldo.replace(" MXN", "");
                    sueldo = sueldo.replace(" MX", "");
                    sueldo = sueldo.replace(" M", "");
                    sueldo = sueldo.replaceAll(" ", "");
                    sueldo = sueldo.replace("$", "");
                    sueldo = sueldo.slice(0, sueldo.length - 1);
                    setValorEstimadoInmueble("$ " + sueldo + " MXN");
                  }
                }}
                onInput={(ev) => {
                  let sueldo = ev.target.value;
                  sueldo = sueldo.replaceAll(",", "");
                  sueldo = sueldo.replace(" MXN", "");
                  sueldo = sueldo.replace(" MX", "");
                  sueldo = sueldo.replace(" M", "");
                  sueldo = sueldo.replaceAll(" ", "");
                  sueldo = sueldo.replace("$", "");
                  if (sueldo === 0 || sueldo === "") {
                    setValorEstimadoInmueble("");
                  } else {
                    sueldo = new Intl.NumberFormat("es-MX").format(sueldo);
                    if (sueldo !== "NaN") {
                      setValorEstimadoInmueble("$ " + sueldo + " MXN");
                    }
                  }
                }}
              />
            </div>
            <div className="col3 campo-form estado marginEspacio">
              <label>¿Cuál es tu ahorro para cubrir el enganche?</label>
              <input
                align="right"
                type="tel"
                name="ahorroCubrirEnganche"
                id="ahorroCubrirEnganche"
                required
                style={{ textAlign: "right", marginBottom: "5px" }}
                value={ahorroCubrirEnganche}
                onKeyDown={(ev) => {
                  if (ev.key === "Backspace") {
                    let sueldo = ev.target.value;
                    sueldo = sueldo.replaceAll(",", "");
                    sueldo = sueldo.replace(" MXN", "");
                    sueldo = sueldo.replace(" MX", "");
                    sueldo = sueldo.replace(" M", "");
                    sueldo = sueldo.replaceAll(" ", "");
                    sueldo = sueldo.replace("$", "");
                    sueldo = sueldo.slice(0, sueldo.length - 1);
                    setAhorroCubrirEnganche("$ " + sueldo + " MXN");
                  }
                }}
                onInput={(ev) => {
                  let sueldo = ev.target.value;
                  porcentajeAhorro(sueldo, tipoProyecto);
                  sueldo = sueldo.replaceAll(",", "");
                  sueldo = sueldo.replace(" MXN", "");
                  sueldo = sueldo.replace(" MX", "");
                  sueldo = sueldo.replace(" M", "");
                  sueldo = sueldo.replaceAll(" ", "");
                  sueldo = sueldo.replace("$", "");
                  if (sueldo === 0 || sueldo === "") {
                    setAhorroCubrirEnganche("");
                  } else {
                    sueldo = new Intl.NumberFormat("es-MX").format(sueldo);
                    if (sueldo !== "NaN") {
                      setAhorroCubrirEnganche("$ " + sueldo + " MXN");
                    }
                  }
                }}
              />
              {engancheMinimo === true && (
                <small className="span">
                  {" "}
                  El enganche debe ser mayor al {porcentajeProyecto}% del valor
                  de la propiedad a comprar{" "}
                </small>
              )}
            </div>
          </div>
          <div className="col2 campo-form">
            <button type="submit" className="btn btn_morado">
              Siguiente
            </button>
          </div>
        </form>

        {/* <CompIngresos demostrarIngresos={demostrarIngresos} /> */}
        <ModalVerificarEdad
          open={isOpenModalVerificarEdad}
          edad={edadActual}
          plazo={plazoSeleccionado}
        ></ModalVerificarEdad>
        <ModalVerificarRegistroCrediteka
          open={isOpenModalVerificarRegistroCrediteka}
          close={() => FuncionAuxiliar()}
        ></ModalVerificarRegistroCrediteka>
        <ModalErrorVerificar
          open={isOpenModalError}
          close={() => EnviarFormulario()}
        ></ModalErrorVerificar>
        <ModalLoading
          open={isOpenModalLoading}
          close={() => setIsOpenModalLoadiing(false)}
        ></ModalLoading>
        <ModalRespuestaPreAutorizaSinBancos
          open={isOpenModalSinBancos}
          msg={sinBancoId}
          close={() => setIsOpenModalSinBancos(false)}
        ></ModalRespuestaPreAutorizaSinBancos>
      </div>
    </>
  );
}

export default InformacionGeneral;
