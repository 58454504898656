import ReactDOM from "react-dom/client";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { set, useForm } from "react-hook-form";
import Swal from "sweetalert2";

//import validate, { isEmpty } from 'validate.js';

import { Link } from "react-router-dom";
import "../../App.css";
import Rectangle from "../../images/Rectangle-3.jpg";
import {
  DocIniPasaporte,
  CompIngresos,
  CompDomicilio,
  DocInicial,
  DocInicialMultiple
} from "../../components/Cards";
import { get, post, put, getCP, postDocs } from "../../utils/http";
import {
  ruleRFC,
  ruleTelefono,
  ruleNacimiento,
  ruleCURP,
  ruleNumeroSeguridad,
} from "../../assets/validateRules";
import {
  ModalCodigoSms,
  ModalCasiListos,
  ModalLoadingGeneral,
} from "../../components/Modals";

import {
  obtEmpresaUrl,
  verificarCoacreditado,
} from "../../utils/global_functions";

export function InfoDocumentacionInicialCoAcreditado() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [recargarDocumentos, setRecargarDocumentos] = useState(false);

  //JPB 13-11-2023
  const [coAcreditado, setcoAcreditado] = useState(false);

  //  localStorage.setItem("idCuestionarioSolicitud", idCuestionario);
  let idCuestionarioRecuperado = localStorage.getItem(
    "idCuestionarioSolicitud"
  );

  /*JPB 27-11-2023 Revisa si tiene coacerditado*/
  async function revisaCoacreditado() {
    const tieneCoAcrediado = await verificarCoacreditado(
      idCuestionarioRecuperado
    );
    if (tieneCoAcrediado === true) {
      setcoAcreditado(true);
    } else {
      setcoAcreditado(false);
    }
  }

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const [documentacionVivienda, setDocumentacionVivienda] = useState({
    documento: null, // Estado para almacenar el archivo seleccionado
  });

  const handleDocumentoChange = async (event) => {
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;
    // console.log("Archivo seleccionado:", selectedFile);
    // Verifica si muestra el archivo en la consola

    if (selectedFile) {
      setDocumentacionVivienda((prevInfo) => ({
        ...prevInfo,
        documento: selectedFile,
      }));

      const formData = new FormData();
      formData.append("Archivo", selectedFile);
      formData.append("IdUser", cookies.get("id"));
      formData.append(
        "IdResPrecalifica",
        localStorage.getItem("idResPrecalifica")
      );
      formData.append("IdTipoDocInicial", 1);

      try {
        let insertDocumento = await postDocs("subirArchivo", formData);

        if (insertDocumento.statuscode === 200) {
          Swal.fire({
            title: "Datos guardados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });

          //navigate("/solicitudAceptada");
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      } catch (error) {
        // Maneja errores aqui
      }
    } else {
      // console.log("No se seleccionó ningún archivo");
    }
  };

  const handleDocumentoChange2 = async (event) => {
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;
    // console.log("Archivo seleccionado:", selectedFile);
    // Verifica si muestra el archivo en la consola

    if (selectedFile) {
      setDocumentacionVivienda((prevInfo) => ({
        ...prevInfo,
        documento: selectedFile,
      }));

      const formData = new FormData();
      formData.append("Archivo", selectedFile);
      formData.append("IdUser", 137);
      formData.append(
        "IdResPrecalifica",
        localStorage.getItem("idResPrecalifica")
      );
      formData.append("IdTipoDocInicial", 2);

      try {
        let insertDocumento = await postDocs("subirArchivo", formData);

        if (insertDocumento.statuscode === 200) {
          Swal.fire({
            title: "Datos guardados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });

          //navigate("/solicitudAceptada");
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      } catch (error) {
        // Maneja errores aqui
      }
    } else {
      // console.log("No se seleccionó ningún archivo");
    }
  };

  const handleDocumentoChange3 = async (event) => {
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;
    // console.log("Archivo seleccionado:", selectedFile);
    // Verifica si muestra el archivo en la consola

    if (selectedFile) {
      setDocumentacionVivienda((prevInfo) => ({
        ...prevInfo,
        documento: selectedFile,
      }));

      const formData = new FormData();
      formData.append("Archivo", selectedFile);
      formData.append("IdUser", 137);
      formData.append(
        "IdResPrecalifica",
        localStorage.getItem("idResPrecalifica")
      );
      formData.append("IdTipoDocInicial", 3);

      try {
        let insertDocumento = await postDocs("subirArchivo", formData);

        if (insertDocumento.statuscode === 200) {
          Swal.fire({
            title: "Datos guardados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });

          //navigate("/solicitudAceptada");
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      } catch (error) {
        // Maneja errores aqui
      }
    } else {
      // console.log("No se seleccionó ningún archivo");
    }
  };

  const handleDocumentoCambios = async (event, errorFunction = () => {}) => {
    const selectedFile = event.target.files[0];
    //console.log("select file: "+selectedFile);
    const inputName = event.target.name;
    if (inputName == "2") {
      let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
      const selectedFiles = event.target.files;
      if (selectedFiles.length > 0) {
        try {
          
          let insertDocumento;
          for (let i = 0; i < selectedFiles.length; i++) {
            const formData = new FormData();
            const file = selectedFiles[i];

            formData.append("Archivo", file);
            formData.append("IdUser", cookies.get("id"));
            formData.append(
              "IdResPrecalifica",
              idCuestionario.infoCalifica[0].cuestionarioColeccion[0]
                .resPrecalificas.idResPrecalifica
            );
            formData.append("IdTipoDocInicial", inputName);
            formData.append("escoAcreditado", true); //JPB 14-11-2023 Para saber si es co Acreditado
            insertDocumento = await postDocs("subirArchivo", formData);
          }

          if (insertDocumento.statuscode === 200) {
            setRecargarDocumentos(true);
          } else {
            errorFunction();
            Swal.fire({
              title: "Datos ingresados erróneos",
              text: "Verifica que tus datos sean correctos ",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } catch (error) {
          // Maneja errores aquí
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    } else {
      let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
      if (selectedFile) {
        setDocumentacionVivienda((prevInfo) => ({
          ...prevInfo,
          documento: selectedFile,
        }));

        const formData = new FormData();
        formData.append("Archivo", selectedFile);
        formData.append("IdUser", cookies.get("id"));
        formData.append(
          "IdResPrecalifica",
          idCuestionario.infoCalifica[0].cuestionarioColeccion[0]
            .resPrecalificas.idResPrecalifica
        );
        formData.append("IdTipoDocInicial", inputName);
        formData.append("escoAcreditado", true); //JPB 14-11-2023 Para difernciar si es co Acreditado
        try {
          let insertDocumento = await postDocs("subirArchivo", formData);

          if (insertDocumento.statuscode === 200) {
            setRecargarDocumentos(true);
            //navigate("/solicitudAceptada");
          } else {
            errorFunction();
            Swal.fire({
              title: "Datos ingresados erroneos",
              text: "Verifica que tus datos sean correctos ",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } catch (error) {
          // Maneja errores aqui
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    }
  };

  //Para verificar si ya tiene un documento
  const verificarDocumento = async (idTipoDocumento) => {
    //let idResPrecalifica = localStorage.getItem("idResPrecalifica");
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    let idRes =
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica;
    let params = {
      idResPrecalifica: idRes,
      idUser: parseInt(cookies.get("id")),
      idTipoDocInicial: idTipoDocumento,
      escoAcreditado: true,
    };
    var verificarDocumento = null;
    if (idTipoDocumento == 2){
      verificarDocumento = await post("getInfoDocumentoInicialCoAcreditadoMultiple", params);
    }else{
      verificarDocumento = await post("postInfoDocumentoInicialCoAcreditado", params);
    }

    if (verificarDocumento.statuscode === 200) {

      try {
        if (idTipoDocumento == 2){
          const sonCoAcreditados = verificarDocumento.value.map((archivo) => archivo.esCoAcreditado === true)
          if (!sonCoAcreditados.includes(false)){
            return {
              archivos: verificarDocumento.value,
              info: verificarDocumento.info,
              escoAcreditado: true, //JPB 13-11-2023 Es false ya que estamos subiendo en la seccion de Info Coacreditado  ¿?----------------------
            };
          }else{
            return verificarDocumento.info;
          }
        }else{
          //Nombre del archivo modificado
          let cadena = verificarDocumento.value.archivo;

          // Dividir la cadena por las barras invertidas para obtener partes
          const partes = cadena.split("/");

          //console.log("estas son las partes: "+partes);
          // Obtener la última parte que contiene el nombre de archivo y extensión
          const nombreArchivoConExtension = partes[partes.length - 1];

          if (verificarDocumento.value.esCoAcreditado == true) {
            return {
              nombreDoc: nombreArchivoConExtension,
              info: verificarDocumento.info,
              escoAcreditado: true, //JPB 13-11-2023 Es true ya que estamos subiendo en la seccion de Info Coacreditado
            };
          } else {
            return verificarDocumento.info;
          }
        }
        
      } catch {
        return verificarDocumento.info;
      }
    } else {
      return verificarDocumento.info;
    }
  };

  //Para obtener todos los documentos
  var docs;
  const obtDocumentosInicial = async () => {
    let data = await get("getTipoDocumentos", "");
    if (data.statuscode === 200) {
      let documentos = [];
      let arrDocs = data.documentos;
      //console.log(arrDocs);

      // Coloca el card que permite subir múltiples archivos al final
      arrDocs = arrDocs.concat(arrDocs.splice(1, 1));

      // Crear un array de promesas para verificar los documentos
      const verificarPromises = arrDocs.map(async (documento) => {
        try {
          let resVerDoc = await verificarDocumento(documento.idTipoDocInicial);
          //console.log(resVerDoc);
          let parrafo = "";
          let nota = "";
          let acti = localStorage.getItem("idActividadEconomica"); //JPB 21-09-2023 Extraigo el dato del localstorage para conocer su actividad economica
          let actividad = parseInt(acti);
          //console.log(documento.idTipoDocInicial);
          switch (documento.idTipoDocInicial) {
            case 1:
              parrafo = "INE o pasaporte";
              nota =
                "Aseg\u00FArate que la identificaci\u00F3n sea vigente y legible";
              break;

            case 2:
              parrafo = "Comprobante de ingresos";
              switch (actividad) {
                case 1:
                  nota = "Los últimos 3 recibos de nomina";
                  break;

                case 2:
                  nota =
                    "Ultimas 2 declaraciones anuales y estados de cuenta de los últimos 6 meses.";
                  break;

                case 3:
                  nota =
                    "Ultimas 2 declaraciones anuales y estados de cuenta de los últimos 6 meses.";
                  break;

                case 4:
                  nota =
                    "Ultimas 2 declaraciones anuales y estados de cuenta de los últimos 6 meses";
                  break;

                default:
                  nota = "";
                  break;
              }
              break;

            case 3:
              parrafo = "Comprobante de domicilio";
              nota =
                "Menor a 3 meses de antigüedad - De preferencia CFE, Agua, Predial o Telefonia";
              break;

            default:
              break;
          }
          if (documento.idTipoDocInicial == 2){
            return {
              titulo: documento.nombre,
              parrafo: parrafo,
              tipoDocumento: documento.idTipoDocInicial,
              docVerificado: resVerDoc.info,
              nota: nota,
              archivos: resVerDoc.archivos
            };
          }else{
            return {
              titulo: documento.nombre,
              parrafo: parrafo,
              tipoDocumento: documento.idTipoDocInicial,
              docVerificado: resVerDoc.info,
              nota: nota,
              nombreDocumento: resVerDoc.nombreDoc,
            };
          }
          
        } catch (error) {
          console.error("Error al verificar el documento", error);
          return null;
        }
      });

      // Esperar a que todas las promesas se completen
      const documentosWithVerificaciones = await Promise.all(verificarPromises);

      // Filtrar los resultados nulos en caso de errores
      const documentosValidos = documentosWithVerificaciones.filter(
        (doc) => doc !== null
      );

      if (documentosWithVerificaciones.length === 0) {
        // console.log("No hay documentos");
      } else {
        docs = documentosWithVerificaciones.map((documento) => {

          if (documento.tipoDocumento == 2) {
            return (<DocInicialMultiple
              coAcreditado={false}
              titulo={documento.titulo}
              descripcion={documento.parrafo}
              tipoDocumento={documento.tipoDocumento}
              onDocumentoChange={handleDocumentoCambios}
              documentoSubido={documento.docVerificado}
              nota={documento.nota}
              archivos={documento.archivos}
            />)
          }else{
            return (<DocInicial
              coAcreditado={true}
              titulo={documento.titulo}
              descripcion={documento.parrafo}
              tipoDocumento={documento.tipoDocumento}
              onDocumentoChange={handleDocumentoCambios}
              documentoSubido={documento.docVerificado}
              nota={documento.nota}
              nombreDocumento={documento.nombreDocumento}
            />)
          }
          
      });

        const root = ReactDOM.createRoot(
          document.getElementById("documentosRender")
        );

        root.render(docs);
      }
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    obtInfoCuestionario();
    obtDocumentosInicial();
    revisaCoacreditado();
  }, []);

  useEffect(()=>{
    if (recargarDocumentos){
      obtDocumentosInicial();
      setRecargarDocumentos(false);
    }
  }, [recargarDocumentos]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/InfocoAcreditado">
          {"<"} Regresar a documentación
        </a>

        <h1 className="titt">Documentación Inicial - Co Acreditado</h1>
        <p>
          Aseg&uacute;rate que la identificaci&oacute;n sea vigente y legible
        </p>

        <div className="cont_flex con_flex_wrap responsivoColumn" id="documentosRender"></div>

        {/*<div className="cont_flex con_flex_wrap">
                    
                    
                    <DocIniPasaporte onDocumentoChange={handleDocumentoChange}></DocIniPasaporte>
                    <CompIngresos  onDocumentoChange={handleDocumentoChange2}></CompIngresos>
                    <CompDomicilio onDocumentoChange={handleDocumentoChange3}></CompDomicilio>
                    
                </div>
                */}

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <a
              className="btn btn_bco col3 campo-form "
              href="/InfocoAcreditado"
            >
              {" "}
              Regresar
            </a>
            <a
              className="btn btn_morado col3 campo-form"
              href="/InfocoAcreditado"
            >
              Guardar
            </a>
          </div>
        </div>

        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
      </div>
    </>
  );
}
