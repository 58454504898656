import { useState, useEffect} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "../../App.css";

import { Link } from "react-router-dom";    
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { obtEmpresaUrl } from "../../utils/global_functions";
import { round } from "../../utils/operaciones_formulario";
import { formatoDinero, textoAMoneda, agergarDecimales } from "../../utils/operaciones_formulario";
import { post, put } from "../../utils/http";
import Cookies from "universal-cookie";
import { ModalInstruccinesAmortizacion } from "../../components/Modals";

export default function AmortizacionHipoteca() {
  const [isLoading, setIsLoading] = useState(true);
  const [hipoteca, setHipoteca] = useState({});
  const [registrosAmortizacion, setRegistrosAmortizacion] = useState([]);
  const [aportacionesAmortizacion, setAportacionesAmortizacion] = useState({});
  const [pop, setPop] = useState(true);
  const cookies = new Cookies();
  const {
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      montoLote: "$ 0.00",
    }
  });

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await obtInfoAmortizacion();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
      }
    };
    cargarDatos();
  }, []);

  // Definición de las columnas de las tablas
  const columnDefs = [
    { headerName: "Periodo", field: "periodo", width: 100},
    { 
      headerName: "Fecha", 
      field: "fecha", width: 150, 
      valueFormatter: param => `${param.value.getDate().toString().padStart(2, '0')}/${(param.value.getMonth()+1).toString().padStart(2, '0')}/${param.value.getFullYear()}`
    },
    { headerName: "Saldo Inicial", field: "saldoInicial", valueFormatter: param => formatoDinero(param.value), },
    { headerName: "Intereses", field: "intereses", valueFormatter: param => formatoDinero(param.value), width: 180},
    { headerName: "Pago Mensual Sin Costos Adicionales", field: "pagoMensualSinCostosAd", valueFormatter: param => formatoDinero(param.value), width: 180},
    { headerName: "Seguro de Vida", field: "seguroVida", valueFormatter: param => formatoDinero(param.value), width: 150},
    { headerName: "Seguro de Daños", field: "seguroDanios", valueFormatter: param => formatoDinero(param.value), width: 150},
    { headerName: "Pago Total Mensual", field: "pagoTotalMensual", valueFormatter: param => formatoDinero(param.value), width: 180},
    { 
      headerName: "Aportación Adicional a Capital", 
      field: "aportacionAdCapital", 
      editable: true, 
      valueSetter: param => {
        if (param.newValue === null){
          param.data.aportacionAdCapital = 0;
        }else{
          param.data.aportacionAdCapital = param.newValue;
        }
        return true;
      },
      valueFormatter: param => param.value === 0 ? "" : formatoDinero(param.value),
      width: 180,
      cellClass: "tabla_amort_aportacion_cell"
    },
    { 
      headerName: "Pago a Capital", 
      field: "pagoCapital",
      valueFormatter: param => formatoDinero(param.value),
      width: 150
    },
    { 
      headerName: "Saldo Final", 
      field: "saldoFinal",
      valueFormatter: param => formatoDinero(param.value),
    },
  ];

  async function obtInfoAmortizacion(){
    const idHipoteca = parseInt(localStorage.getItem("idHipoteca"));

    const respuesta = await post("getInfoAmortizacionHipoteca", {
      idUser: parseInt(cookies.get("id")),
      idHipoteca
    });

    let infoHipoteca = {}
    let aportacionesHipoteca = []

    if (respuesta.statuscode === 200){
      const hipoteca = respuesta.respuesta.infoHipoteca;
      const aportaciones = JSON.parse(respuesta.respuesta.aportaciones);

      //console.log("InfoHipoteca: ", hipoteca);
      //console.log("Aportaciones:", aportaciones);

      infoHipoteca = {
        identificador: hipoteca.identificador,
        banco: hipoteca.banco,
        credito: {
          fechaApertura: "2024-09-05",
          montoOriginal: hipoteca.montoOriginalCredito,
          saldoCredito: hipoteca.saldoCredito,
          periodosPendientes: hipoteca.periodosFaltantes,
        },
        parametrosCredito: {
          plazo: hipoteca.plazoCredito,
          capitalizacion: hipoteca.capitalizacion,
          capitalizacionValor: hipoteca.capitalizacionValor,
          periodosTotales: hipoteca.periodosTotales,
          tasaInteresFija: hipoteca.tasaAnual
        },
        pagosPeriodo: {
          pagoMensual: hipoteca.pagoMensual,
          costoDanios: hipoteca.costoSeguroDanios,
          costoSeguroVida: hipoteca.costoSeguroVida
        }
      }

      aportacionesHipoteca = aportaciones != null ? aportaciones : {}

    }else{

    }

    let tasaPorPeriodo = (infoHipoteca.parametrosCredito.tasaInteresFija / 100) / infoHipoteca.parametrosCredito.capitalizacionValor;
    infoHipoteca.pagosPeriodo.tasaPorPeriodo = tasaPorPeriodo;

    let fechaAux = new Date(infoHipoteca.credito.fechaApertura + 'T00:00:00');

    let periodo = 1;
    let fecha = new Date(fechaAux);
    let saldoInicial = round(infoHipoteca.credito.saldoCredito, 2);
    let intereses = round(saldoInicial * tasaPorPeriodo, 2);
    let pagoMensualSinCostosAd = round(infoHipoteca.pagosPeriodo.pagoMensual, 2);
    let seguroVida = round(infoHipoteca.pagosPeriodo.costoSeguroVida, 2);
    let seguroDanios = round(infoHipoteca.pagosPeriodo.costoDanios, 2);
    let pagoTotalMensual = round(pagoMensualSinCostosAd + seguroVida + seguroDanios, 2);
    let aportacionAdCapital = round(aportacionesHipoteca["0"] ? aportacionesHipoteca["0"] : 0, 2);
    let pagoCapital = round(pagoMensualSinCostosAd -  intereses, 2);
    let saldoFinal = round(saldoInicial - pagoCapital - aportacionAdCapital, 2);

    let registros = [{
      periodo,
      fecha,
      saldoInicial,
      intereses,
      pagoMensualSinCostosAd,
      seguroVida,
      seguroDanios,
      pagoTotalMensual,
      aportacionAdCapital,
      pagoCapital,
      saldoFinal 
    }];

    for (let i = 1; i < infoHipoteca.parametrosCredito.periodosTotales ; i++) {

      periodo = i + 1;
      fecha = new Date(fechaAux.setMonth(fechaAux.getMonth() + 1));
      saldoInicial = round(registros[i-1].saldoFinal, 2);
      intereses = round(saldoInicial * tasaPorPeriodo, 2);
      pagoMensualSinCostosAd = round(infoHipoteca.pagosPeriodo.pagoMensual, 2);
      seguroVida = round(infoHipoteca.pagosPeriodo.costoSeguroVida, 2);
      seguroDanios = round(infoHipoteca.pagosPeriodo.costoDanios, 2);
      pagoTotalMensual = round(pagoMensualSinCostosAd + seguroVida + seguroDanios, 2);
      aportacionAdCapital = round(aportacionesHipoteca[i] ? aportacionesHipoteca[i] : 0, 2);
      pagoCapital = round(pagoMensualSinCostosAd - intereses, 2);
      saldoFinal = round(saldoInicial - pagoCapital - aportacionAdCapital, 2);

      // Remate para llegar a cero en saldo final
      if (saldoFinal < 0){
        pagoMensualSinCostosAd = round(saldoInicial + intereses, 2);

        pagoTotalMensual = round(pagoMensualSinCostosAd + seguroVida + seguroDanios, 2);
        pagoCapital = round(pagoMensualSinCostosAd - intereses, 2);
        saldoFinal = round(saldoInicial - pagoCapital - aportacionAdCapital,2);
      }

      registros.push({
        periodo,
        fecha,
        saldoInicial,
        intereses,
        pagoMensualSinCostosAd,
        seguroVida,
        seguroDanios,
        pagoTotalMensual,
        aportacionAdCapital,
        pagoCapital,
        saldoFinal 
      });
    }

    setHipoteca(infoHipoteca);
    setRegistrosAmortizacion(registros);
    setAportacionesAmortizacion(aportacionesHipoteca);
  }

  const guardarAportaciones = async () => {
    //console.log(aportacionesAmortizacion);
    const respuesta = await put("updateAmortizacionHipoteca", {
      idUser: parseInt(cookies.get("id")),
      idHipoteca: parseInt(localStorage.getItem("idHipoteca")),
      aportacionesjson: JSON.stringify(aportacionesAmortizacion)
    });

    //console.log(respuesta);
  }

  function actualizarRegistrosAmortizacion(indiceRegistro, registrosAmort, aportaciones){
    registrosAmort[indiceRegistro].aportacionAdCapital = round(aportaciones[indiceRegistro] ? aportaciones[indiceRegistro] : 0, 2);
    registrosAmort[indiceRegistro].saldoFinal = round(registrosAmort[indiceRegistro].saldoInicial - registrosAmort[indiceRegistro].pagoCapital - registrosAmort[indiceRegistro].aportacionAdCapital,2);
    
    for (let i = indiceRegistro + 1; i < registrosAmort.length; i++) {
      registrosAmort[i].saldoInicial = round(registrosAmort[i-1].saldoFinal, 2);
      registrosAmort[i].intereses = round(registrosAmort[i].saldoInicial * hipoteca.pagosPeriodo.tasaPorPeriodo, 2);
      registrosAmort[i].pagoMensualSinCostosAd = round(hipoteca.pagosPeriodo.pagoMensual, 2);
      registrosAmort[i].seguroVida = round(hipoteca.pagosPeriodo.costoSeguroVida, 2);
      registrosAmort[i].seguroDanios = round(hipoteca.pagosPeriodo.costoDanios, 2);
      registrosAmort[i].pagoTotalMensual = round(registrosAmort[i].pagoMensualSinCostosAd + registrosAmort[i].seguroVida + registrosAmort[i].seguroDanios, 2);
      registrosAmort[i].aportacionAdCapital = round(aportaciones[i] ? aportaciones[i] : 0, 2);
      registrosAmort[i].pagoCapital = round(registrosAmort[i].pagoMensualSinCostosAd - registrosAmort[i].intereses, 2);
      registrosAmort[i].saldoFinal = round(registrosAmort[i].saldoInicial - registrosAmort[i].pagoCapital - registrosAmort[i].aportacionAdCapital, 2);

      // Remate para llegar a cero en saldo final
      if (registrosAmort[i].saldoFinal < 0){
        registrosAmort[i].pagoMensualSinCostosAd = round(registrosAmort[i].saldoInicial + registrosAmort[i].intereses, 2);

        registrosAmort[i].pagoTotalMensual = round(registrosAmort[i].pagoMensualSinCostosAd + registrosAmort[i].seguroVida + registrosAmort[i].seguroDanios, 2);
        registrosAmort[i].pagoCapital = round(registrosAmort[i].pagoMensualSinCostosAd - registrosAmort[i].intereses, 2);
        registrosAmort[i].saldoFinal = round(registrosAmort[i].saldoInicial - registrosAmort[i].pagoCapital,2);

        aportaciones[i.toString()] = 0;
        registrosAmort[i].aportacionAdCapital = 0;
      }
    }
  }

  const aportarEnLote = (datos) => {
    let aportaciones = {};

    for (let i = 0; i < hipoteca.parametrosCredito.periodosTotales; i++) {
      aportaciones[i.toString()] = Number(datos.montoLote.replace(/[^0-9.]/g, "")); 
    }

    actualizarRegistrosAmortizacion(0, registrosAmortizacion, aportaciones);

    setAportacionesAmortizacion(aportaciones);
  }

  return (
    <>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      ) : (
        <div className=" cont_Info">
          {
            pop && (
              <ModalInstruccinesAmortizacion
                onClose={() => setPop(false)}
              />
            )
          }
          <Link to="/miHipoteca" className="link linkGrande back">
            {" "}
            {"<"} Regresar a Mis Hipotecas
          </Link>
          <br></br>
          <h1 className="titt">{hipoteca.identificador}</h1>

          <div className="Resum">

            <ul className="lista_dos_col">
              <li>
                <span className="negritas">Valor Original del crédito:</span> {formatoDinero(hipoteca.credito.montoOriginal)}
              </li>
              <li>
              <span className="negritas">Tasa:</span> {hipoteca.parametrosCredito.tasaInteresFija}%
              </li>
              <li>
              <span className="negritas">Plazo:</span> {hipoteca.parametrosCredito.plazo} años
              </li>
              <li>
              <span className="negritas">Capitalización:</span> {hipoteca.parametrosCredito.capitalizacion}
              </li>
              <li>
              <span className="negritas">Banco:</span> {hipoteca.banco}
              </li>
            </ul>
            
            <br/>
            <hr/>

            <form onSubmit={handleSubmit(aportarEnLote)}>
              <div className="lista_dos_col">
                <li>
                  <label>Aportación en lote</label>
                  <div className="lista_dos_col">
                    <li>
                      <input
                        type="text"
                        autoComplete="off"
                        {...register("montoLote")}
                        onInput={(e) => {
                          e.target.value = textoAMoneda(e.target.value);
                        }}
                        onBlur={(e) => {
                          e.target.value = agergarDecimales(e.target.value);
                        }}
                      />
                    </li>
                    <li>
                      <button 
                        type="submit"
                        id="btnAmortizacionLote"
                        className="btn btn_bco"
                        >
                        Aplicar
                      </button>
                    </li>
                  </div>
                </li>
              </div>
            </form>
            
            <h3 className="encabezado_empresa">Tabla de amortización</h3>
            <div id="cont_tabla_amortizacion" className="ag-theme-material" style={{ height: 400 }}>
              <AgGridReact
                singleClickEdit={true}
                defaultColDef={{
                  suppressMovable: true,
                  minWidth: 100,
                }}
                columnDefs={columnDefs}
                rowData={registrosAmortizacion.filter(registro => registro.saldoInicial > 0)}
                onCellValueChanged={(cell) => {
                  let aportaciones = {...aportacionesAmortizacion, ...{[cell.rowIndex.toString()]: cell.data.aportacionAdCapital} };
                  
                  actualizarRegistrosAmortizacion(cell.rowIndex, registrosAmortizacion, aportaciones);

                  setAportacionesAmortizacion(aportaciones);
                }}
              />
            </div>

            <div class="dosCuttonFormularios">
              <Link 
                class="btn btn_bco campo-form" 
                to="/miHipoteca" 
                onClick={() => {
                  localStorage.removeItem("idHipoteca");
                }}>
                  Regresar
                </Link>
              <button class="btn btn_morado campo-form"
              onClick={guardarAportaciones}>
                Guardar
              </button>
            </div>

            <h3>
              Si realizaste algun cambio, recuerda <br />{" "}
              <span className="morado"> guardar </span> antes de salir.
            </h3>
          </div>

          <div className="copyright copy_cont">
            <p>
              La presente información es únicamente para efectos de cálculo ilustrativo, 
              no representa ningún ofrecimiento formal por SATURN5 
            </p>
            <p>
              © 2024 SATURN5 | All rights reserved
            </p>
          </div>
        </div>
      )}
      
    </>
  );
}
