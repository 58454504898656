import '../../App.css'
import HeaderHome from '../../components/HeaderHome'
import NavHome from '../../components/NavHome'
import FooterHome from '../../components/FooterHome'

// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------------------- Home con menú lateral ---------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export function Home({children,active}){
    return(
        <>
            <HeaderHome/>
            <div className="cont_flex">
                <NavHome active={active}/>
                {children}
            </div> 
            <FooterHome/>
        </>
    )
}