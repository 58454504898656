import React, { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { put, postPaypal } from "../utils/http";
import Swal from "sweetalert2";
import { obtEmpresaUrl } from "../utils/global_functions";

function PayPalButton({ precio }) {

  console.log(precio);
  const [result, setResult] = useState("");

  // Función para crear una orden
  const createOrder = async () => {
    let montoAvaluo = localStorage.getItem("pagoAvaluo");
    try {
      // Verificar que precio esté definido
      if (!montoAvaluo) {
        throw new Error("El precio no está definido");
      }

      const params = {
        items: [
          {
            name: "YOUR_PRODUCT_ID",
            price: parseFloat(montoAvaluo), // Asegurarse de que el precio sea un número
          },
        ],
      };

      // Llamada a la API para crear la orden
      console.log("Enviando parámetros a la API para crear la orden:", params);
      const res = await postPaypal("api/orders", params);

      console.log("imprimiendo");
      console.log(res.id);

      if (res.id) {
        return res.id;
      } else {
        // Manejar errores en la creación de la orden
        const errorDetail = res?.details?.[0];
        const errorMessage = errorDetail
          ? `${errorDetail.issue} ${errorDetail.description} (${res.debug_id})`
          : JSON.stringify(res);

        throw new Error(errorMessage);
      }
    } catch (error) {
      console.error("Error creating order:", error);
      setResult(`Could not initiate PayPal Checkout...<br><br>${error.message}`);
    }
  };

  // Función que se llama cuando el pago es aprobado
  const onApprove = async (data, actions) => {
    try {
      const apiUrl = `api/orders/${data.orderID}/capture`;
      const orderData = await postPaypal(apiUrl, {});

      console.log("Respuesta de la API al capturar la orden:", orderData);

      // Verificar si la captura fue exitosa
      if (orderData.statuscode === 200) {
        const idResPrecalifica = localStorage.getItem("idResPrecalifica");
        const idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");

        Swal.fire({
          title: "Pago completado correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });

        const params = {
          idResPrecalifica: idResPrecalifica,
          idCuestionario: idCuestionarioSolicitud,
          pagado: 1,
        };

        const updatePago = await put("updateAvaluoPagado", params);
        if (updatePago.statuscode === 200) {
          console.log("Pago actualizado correctamente en la base de datos.");
        }
      }
    } catch (error) {
      console.error("Error approving order:", error);
      setResult(`Sorry, your transaction could not be processed...<br><br>${error.message}`);
    }
  };

  useEffect(() => {
    obtEmpresaUrl();
  }, []);

  const resultMessage = (message) => {
    setResult(message);
  };

  return (
    <div>
      <div id="result-message" dangerouslySetInnerHTML={{ __html: result }} />
      <PayPalScriptProvider
        options={{
          "client-id": "AS3LWRfnFwIsgE4YGz7CL_NFKOuc_rzGKf2ETuJajKgd91lPN_hXUU9LtBmf1PJqeWkcnI-GX70vf5cl",
          currency: "MXN",
        }}
      >
        <PayPalButtons
          createOrder={createOrder}
          onApprove={onApprove}
          onError={(error) => {
            console.error("Error with PayPal transaction:", error);
            resultMessage(`Sorry, your transaction could not be processed...<br><br>${error.message}`);
          }}
          style={{ layout: "horizontal" }}
          fundingSource="card"
        />
      </PayPalScriptProvider>
    </div>
  );
}

export default PayPalButton;
