import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, put, post } from "../../utils/http";
import { InfoCotizacion, PasosAseguir } from "../../components/Cards";
import { Link } from "react-router-dom";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { TarjetaSubirDocumentacion } from "../../components/Cards";
import { TarjetaCalculoDias } from "../../components/Cards";
import { TarjetaDocumentosVivienda } from "../../components/Cards";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import { guardarPaso, obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial documentos de vivienda --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function Avaluo() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  let idResPrecalifica = localStorage.getItem("idResPrecalifica");
  let idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");
  const [avaluo, setAvaluo] = useState({});
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 4, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // let nombrePantalla = "4.Avaluo";
      // var urlActual = window.location.pathname;
      // let credito = localStorage.getItem("idCredito");

      // let pantallaActualMenu = (
      //   <PasosAseguir
      //     nombrePantalla={nombrePantalla}
      //     urlActual={urlActual}
      //     credito={credito}
      //   />
      // );
      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = "4. ";
      let descripcionPaso = "Avaluo";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={<span className="numero-paso">{numeroPantalla}</span>}
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  //Obtenemos informacion general
  const obtInformacion = async () => {
    //Parametro fijo hasta que determinemos como se obtiene
    //let data = await get('getAvaluo', 294);
    let data = await get("getAvaluo", idResPrecalifica);
    // console.log(data);
    if (data.statuscode === 200) {
      setAvaluo(() => ({
        ...avaluo,
        ...data.avaluo[0],
      }));
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtInformacion();
    obtPantallaActual();
  }, []);

  //Formatear números a pesos
  const GetNF = (value) => {
    if (value != undefined) {
      return value.toLocaleString("es-MX", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      return 0;
    }
  };

  //Formatear fecha a una fecha legible
  const formatDate = (isoDate) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
    };

    return new Date(isoDate).toLocaleString("es-MX", options);
  };

  const siguiente = async () => {
    await guardarPaso(5, idCuestionarioSolicitud, idResPrecalifica);
    window.location.href = "/notariaAsignada";
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/home" className="link linkGrande back">
          {" "}
          {"<"} Regresar a mis solicitudes
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>

        <h1>Aval&uacute;o</h1>
        <p>
          Ha comenzado la etapa de aval&uacute;o, en esta secci&oacute;n
          podr&aacute;s dar seguimiento a c&oacute;mo va avanzando el proceso y
          cu&aacute;l es el resultado.
        </p>

        <hr />

        <h3 className="titt">Notas</h3>
        <hr />
        <div id="notas"></div>

        <div className="Resum">
          <div className="header">
            <h3>Asignaci&oacute;n de unidad de evaluaci&oacute;n</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>Fecha de asignaci&oacute;n </label>
                <input type="text" value={formatDate(avaluo.fechaAsignacion)} />
              </div>

              <div className="col3 campo-form">
                <label>Valor de compraventa estimado</label>
                <input
                  type="text"
                  value={"$" + GetNF(avaluo.valorCompraVentaEstimado)}
                />
              </div>
              <div className="col3 campo-form">
                <label>Nombre unidad evaluadora </label>
                <input type="text" value={avaluo.nombreUnidad} />
              </div>
            </form>
          </div>
        </div>

        <div className="Resum">
          <div className="header">
            <h3>Domicilio de garant&iacute;a</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>Estado </label>
                <input type="text" value={avaluo.estado} />
              </div>

              <div className="col3 campo-form">
                <label>Municipio </label>
                <input type="text" value={avaluo.municipio} />
              </div>

              <div className="col3 campo-form">
                <label>Colonia </label>
                <input type="text" value={avaluo.colonia} />
              </div>

              <div className="col3 campo-form">
                <label>C&oacute;digo postal </label>
                <input type="number" value={avaluo.cp} />
              </div>

              <div className="col3 campo-form">
                <label>Calle </label>
                <input type="text" value={avaluo.calle} />
              </div>

              <div className="col3 campo-form">
                <label>Num. Exterior e interior </label>
                <input type="text" value={avaluo.numExt} />
              </div>
            </form>
          </div>
        </div>

        <hr />

        <div className="Resum">
          <div className="header">
            <h3>Cita para elaboraci&oacute;n de aval&uacute;o</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>Fecha de cita de aval&uacute;o </label>
                <input type="text" value={formatDate(avaluo.fechaCita)} />
              </div>
            </form>
          </div>
        </div>

        <hr />

        <div className="Resum">
          <div className="header">
            <h3>Resultado de aval&uacute;o</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>Valor de aval&uacute;o </label>
                <input
                  type="text"
                  value={"$" + GetNF(parseInt(avaluo.valorAvaluo))}
                />
              </div>

              <div className="col3 campo-form">
                <label>Fecha de cierre del aval&uacute;o </label>
                <input type="text" value={formatDate(avaluo.fechaCierre)} />
              </div>

              <div className="col3 campo-form">
                <label>Vigencia del aval&uacute;o </label>
                <input type="text" value={formatDate(avaluo.vigencia)} />
              </div>

              <div className="col3 campo-form">
                <label>Resultado del aval&uacute;o </label>
                <input type="text" value={avaluo.resAvaluo} />
              </div>
            </form>
          </div>
        </div>

        {
          /* 
            <a href="/documentosVivienda" className="btn ">
              Regresar
            </a>
            <a
              href="#"
              className="btn btn_morado"
              onClick={() => {
                siguiente();
              }}
            >
              Siguiente
            </a>
          */
        }
        
      </div>
    </>
  );
}
