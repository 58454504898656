import "../../App.css";
import {
  InfoDefault,
  TarjetaBancosGuardados,
  TarjetaBancosGuardadosCarrusel,
} from "../../components/Cards";
import { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { get, post } from "../../utils/http";
import { SinCotizacion } from "../../components/Msjs";
import * as ReactDOM from "react-dom/client";
import { ModalLoadingGeneral } from "../../components/Modals";
import { ModalFavorLlenarInformacion } from "../../components/Modals";
import Swal from "sweetalert2";
//JPB Importo la libreria para el carrusel
import React from "react";
import { GeneradorBancosSeleccionados } from "../../components/Cards";
import Slider from "react-slick";
import "../../sass/index.scss";
import {
  verificarCoacreditado,
  obtEmpresaUrl,
} from "../../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Pantalla donde se muestran las cotizaciones APLICADAS del usuario ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function ResumenCardsBancos() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(true);
  const cookies = new Cookies();
  const [bancosSubM, setBancosSubM] = useState([]);
  let infoCompleta = false;
  let infoCompletaCoAcreditado = false;
  let esCoAcreditado = false;
  // let isOpenInformacionSubida = false;

  const info = async () => {
    setIsOpenModalLoading(true);

    try {
      const idCuestionario = await get("getInfoCalifica", cookies.get("id"));

      if (
        idCuestionario.statuscode === 200 &&
        idCuestionario.infoCalifica.length > 0
      ) {
        const arrbancos = (
          await Promise.all(
            idCuestionario.infoCalifica.map(async (infoCalifica) => {
              return Promise.all(
                infoCalifica.cuestionarioColeccion.map(async (cuestionario) => {
                  const pantalla = await get(
                    "getPantallaActual",
                    cuestionario.resPrecalificas.idResPrecalifica
                  );
                  let estadoBanco =
                    pantalla.statuscode === 200
                      ? (
                          await get(
                            "getEstadoBanco",
                            pantalla.infoProcesoPantalla.idCatPantallas
                          )
                        ).pantallas.nombre
                      : "Proceso no iniciado";

                  return {
                    key: cuestionario.resPrecalificas.idResPrecalifica,
                    banco: cuestionario.resPrecalificas.productoNombre,
                    idResPrecalifica:
                      cuestionario.resPrecalificas.idResPrecalifica,
                    idCuestionario: cuestionario.resPrecalificas.idCuestionario,
                    idCredito: cuestionario.idCredito,
                    idActividadEconomica: cuestionario.idActividadEconomica,
                    Mensualidad: cuestionario.resPrecalificas.pagoMensual,
                    tasa: cuestionario.resPrecalificas.tasa,
                    tasaPromedio: cuestionario.tasaPromedio,
                    bancoSeleccionado:
                      cuestionario.resPrecalificas.bancoSeleccionado,
                    creditoOfertado: cuestionario.resPrecalificas.prestamoMax,
                    idTarjeta: cuestionario.resPrecalificas.idResPrecalifica,
                    descripcion: "",
                    estado: estadoBanco,
                    plazo: cuestionario.plazo,
                  };
                })
              );
            })
          )
        ).flat();
        setBancosSubM(arrbancos.flat());
      } else {
        setBancosSubM([]);
      }
    } catch (error) {
      console.error("Error en la función info:", error);
    }

    setIsOpenModalLoading(false);
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    info();
  }, []);

  const settings1 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      //minimumFractionDigits: 2, JPB 16-01-2024 Se quitan los decimales tarea jira
      maximumFractionDigits: 2,
    });
  };

  //JPB 11-01-2024 Para verificar que haya llenado informacion general
  const [isOpenInformacionSubida, setIsOpenInformacionSubida] = useState(false); //Inicia true para que no se muestre el mensaje automaticamente

  const verificarSecciones = async (idRes, idCues, idCred, idActEco) => {
    try {
      /*-----------------Evalua si ya subio la informacion general del usuario y del coacreditado----------------------*/
      // const tieneCoAcrediado = await verificarCoacreditado(cookies.get('id'));
      const tieneCoAcrediado = await get(
        "verificarSiExisteCoAcreditado",
        cookies.get("id")
      );
      if (tieneCoAcrediado.coAcreditado === true) {
        // setEsCoAcreditado(true)
        esCoAcreditado = true;
        //JPB Para informacion del usuario
        let dataInfoGeneral = await get(
          "getVerificarSeccionInformacion",
          cookies.get("id")
        );
        if (dataInfoGeneral.statuscode === 200) {
          if (
            dataInfoGeneral.respuestas.infoPersonal &&
            dataInfoGeneral.respuestas.infoLaboral &&
            dataInfoGeneral.respuestas.infoMedica &&
            dataInfoGeneral.respuestas.infoDocInicial
          ) {
            // setInfoCompleta(true)
            infoCompleta = true;
          } else {
            // setInfoCompleta(false)
            infoCompleta = false;
          }
        }
        //JPB Para informacion del coACrditado
        let dataInfoGeneralCoAcreditado = await get(
          "getVerificarSeccionInformacionCoAcreditado",
          cookies.get("id")
        );
        if (dataInfoGeneralCoAcreditado.statuscode === 200) {
          if (
            dataInfoGeneralCoAcreditado.respuestas.infoPersonal &&
            dataInfoGeneralCoAcreditado.respuestas.infoLaboral &&
            dataInfoGeneralCoAcreditado.respuestas.infoMedica &&
            dataInfoGeneralCoAcreditado.respuestas.infoDocInicial &&
            dataInfoGeneralCoAcreditado.respuestas.infoDatosExtras
          ) {
            // setInfoCompletaCoAcreditado(true)
            infoCompletaCoAcreditado = true;
          } else {
            // setInfoCompletaCoAcreditado(false)
            infoCompletaCoAcreditado = false;
          }
        }
        /*-----------------Esta parte se encarga de redireccinar una vez que se evaluo si cuentan con coAcreditado----------------------*/

        /*-----------------En caso de si tener coacreditado----------------------*/
        //Redireccina al paso en que se quedo el usuario
        if (infoCompleta && infoCompletaCoAcreditado) {
          setIsOpenInformacionSubida(false);
          // isOpenInformacionSubida = true;
          infoPantalla(idRes, idCues, idCred, idActEco);
        } else {
          setIsOpenInformacionSubida(true);
          // isOpenInformacionSubida = false;
        }
        // console.log(infoCompleta);
        // console.log(infoCompletaCoAcreditado);
        // console.log(isOpenInformacionSubida);
      } else {
        // console.log("entro al else que no deberia");
        // setEsCoAcreditado(false)
        esCoAcreditado = false;
        /*-----------------En caso de no tener coacreditado----------------------*/
        let dataInfoGeneral = await get(
          "getVerificarSeccionInformacion",
          cookies.get("id")
        );
        if (dataInfoGeneral.statuscode === 200) {
          if (
            dataInfoGeneral.respuestas.infoPersonal &&
            dataInfoGeneral.respuestas.infoLaboral &&
            dataInfoGeneral.respuestas.infoMedica &&
            dataInfoGeneral.respuestas.infoDocInicial
          ) {
            // setInfoCompleta(true)
            infoCompleta = true;
          } else {
            infoCompleta = false;
            // setInfoCompleta(false)
          }
        }

        /*-----------------Esta parte se encarga de redireccinar una vez que se evaluo si cuentan con coAcreditado----------------------*/
        if (infoCompleta) {
          setIsOpenInformacionSubida(false);
          // isOpenInformacionSubida = true;
          infoPantalla(idRes, idCues, idCred, idActEco);
        } else {
          setIsOpenInformacionSubida(true);
          // isOpenInformacionSubida = false;
        }
      }
    } catch {
      //fin del try
      // console.log("Error al verificar secciones");
    }
  }; //fin funcion verificar secciones

  const infoPantalla = async (idResPre, idCuesti, idCredito, idActividadEconomica) => {
    localStorage.setItem("idResPrecalifica", idResPre);
    localStorage.setItem("idCuestionarioSolicitud", idCuesti);
    localStorage.setItem("idCredito", idCredito);
    localStorage.setItem("idActividadEconomica", idActividadEconomica);

    let dataPantalla = await get("getPantallaActual", idResPre);

    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;

        if(
          localStorage.getItem('idResPrecalifica') == null && 
          localStorage.getItem('idCuestionarioSolicitud') == null &&
          localStorage.getItem("idCredito", idCredito) == null &&
          localStorage.getItem("idActividadEconomica", idActividadEconomica) == null
        ){
          infoPantalla(idResPre,idCuesti);
        }

        switch (idPantallaActual) {
          case 1:
            window.location.href = "../../descargarSolicitud";
            break;

          case 2:
            window.location.href = "../../SolicitudAceptada";
            break;

          case 3:
            window.location.href = "../../documentosVivienda";
            break;

          case 4:
            window.location.href = "../../avaluo";
            break;

          case 5:
            window.location.href = "../../institucionExterna";
            break;

          case 6:
            window.location.href = "../../formalizacionFirma";
            break;

          case 7:
            window.location.href = "../../felicidades";
            break;

          case 11:
            window.location.href = "../../notariaAsignada";
            break;

          default:
            break;
        }
      }
    } else {

      const response = await get("getPantallaActual", idResPre);
      //console.log("respuesta: ", response);

      if(response.statuscode === 200){
        window.location.href = "../../descargarSolicitud";
      }else{
        Swal.fire({
          title: "Solicitud en proceso",
          text: "Espere a que un ejecutivo lo atienda ",
          icon: "info",
          confirmButtonText: "Aceptar",
        });
      }
      
      /*Swal.fire({
        title: "Upss...",
        html: "<b>No hay tramites iniciados con ese banco <br> ¿Deseas iniciar el proceso?</b>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Iniciar",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          iniciarProceso();
        }
      });*/
    }
  };
  
  const iniciarProceso = async () => {
    const cookies = new Cookies();
    let idCuestionarioSolicitud = localStorage.getItem(
      "idCuestionarioSolicitud"
    );
    let idResPrecalifica = localStorage.getItem("idResPrecalifica");
    // Obtén la fecha actual
    let fechaActual = new Date();
    // Obtiene el año, mes y día
    var año = fechaActual.getFullYear();
    var mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0"); // El mes se indexa desde 0, por lo que se suma 1
    var dia = fechaActual.getDate().toString().padStart(2, "0");
    // Formatea la fecha en "YYYY-MM-DD"
    let fechaFormateada = año + "-" + mes + "-" + dia;
    const params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idCatPantallas: 1,
      fechaCreacion: fechaFormateada,
      fechaAct: fechaFormateada,
    };

    let insertProcesoPantalla = await post("addPantallaActual", params);
    if (insertProcesoPantalla.statuscode === 200) {
      Swal.fire({
        title: "Proceso iniciado",
        icon: "success",
        confirmButtonText: "Aceptar",
      }).then(() => {
        window.location.href = "../../descargarSolicitud";
      });
    } else {
      Swal.fire({
        title: "Datos ingresados erroneos",
        text: "Verifica que tus datos sean correctos ",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  return (
    <>
      <>
        <h3>Mis solicitudes de cr&eacute;dito</h3>
        <div id="cardsBancosSeleccionados">
          <Slider {...(bancosSubM.length === 1 ? settings1 : settings2)}>
            {bancosSubM.map((grupoDos, index) => (
              <div
                key={index}
                className={
                  grupoDos.bancoSeleccionado === true
                    ? "cont_loop bancoSeleccionadoTarjeta anchoCardBancoSeleccionado"
                    : "cont_loop"
                }
                id={"TarjetaBanco:" + grupoDos.idTarjeta}
                onClick={() =>
                  verificarSecciones(
                    grupoDos.idResPrecalifica,
                    grupoDos.idCuestionario,
                    grupoDos.idCredito,
                    grupoDos.idActividadEconomica
                  )
                }
              >
                {
                  <div className="imagenBancoCard">
                    <img
                      className="imgBancos"
                      alt="Nombre del banco"
                      src={require(`../../images/${grupoDos.banco.toUpperCase()}.png`)}
                    />
                  </div>
                }
                <p className="precio"> {grupoDos.descripcion}</p>
                <hr />
                <div className="datoBanco">
                  <p className="descripcion">Crédito ofertado</p>
                  <p className="precio">
                    ${GetNF(Math.floor(grupoDos.creditoOfertado))} MXN
                  </p>
                </div>
                <p className="descripcion">Plazo </p>
                <p className="precio">{grupoDos.plazo} años</p>
                <p className="descripcion">Mensualidad </p>
                <p className="precio">${GetNF(grupoDos.Mensualidad)} MXN</p>
                <p className="descripcion">Tasa promedio</p>
                <p className="precio">{grupoDos.tasaPromedio}%</p>
                <p className="descripcion">Tasa </p>
                <p className="precio">{grupoDos.tasa} </p>

                <div className="msgBancoAplicado">
                  <h4>
                    {grupoDos.bancoSeleccionado === true && (
                      <div className="btn_validandoBanco">
                        {/*<p> Oferta seleccionada </p>*/}
                        <p> Estado: {grupoDos.estado}</p>
                        <i className="fa fa-check-circle"></i>
                      </div>
                    )}
                  </h4>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </>

      <ModalFavorLlenarInformacion
        tieneCoAcrediado={esCoAcreditado}
        infoUsuario={infoCompleta}
        infoCoAcreditado={infoCompletaCoAcreditado}
        open={isOpenInformacionSubida}
        close={() => setIsOpenInformacionSubida(false)}
      />
      <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
    </>
  );
}
