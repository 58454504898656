import "../../App.css";
import {
  InfoCotizacion,
  GeneradorCotenedoresDiv,
  InfoCotizacionXL,
} from "../../components/Cards";

import { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { get } from "../../utils/http";
import * as ReactDOM from "react-dom/client";
import {
  ModalLoadingGeneral,
  ModalDetallesBancos,
} from "../../components/Modals";
import { ConCotizacionP13 } from "../../components/Msjs";

import React from "react";

//JPB Importo la libreria para el carrusel
import Slider from "react-slick";
import "../../sass/index.scss";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Pantalla donde se muestran las cotizaciones del usuario ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function ResumenCotizaciones() {
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(true);
  const cookies = new Cookies();

  const [arergloSegmentadoCotizaciones, setArergloSegmentadoCotizaciones] =
    useState([]);
  const [cuentaConCotizaciones, setCuentaConCotizaciones] = useState(true);
  const [isOpenModalCotizacion, setIsOpenModalCotizacion] = useState(false);

  //Cuando tiene más de 3 o mas cotizaciones
  const settings1 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings2 = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const settings3 = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const GetNF = (value) => {
    return value.toLocaleString("es-MX", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  };

  const info = async () => {
    try {
      const response3 = await get(
        "verificarUsuarioCuestionarioPreAutorizar",
        cookies.get("id")
      );

      if (
        response3.statuscode === 200 &&
        response3.cuestionarioLlenos === 0 &&
        response3.cotizacionesRealizadas === 1
      ) {
        const dataCotizacionUno = await get(
          "soloUserCotizaciones",
          cookies.get("id")
        );
        localStorage.setItem(
          "idResPaso1Cuestionario",
          dataCotizacionUno.cotizaciones[0].id
        );
        //Revisar para que evalua cotizaciones realizadas===1
        const data = await get("userCotizacionesConProcesoBanco", cookies.get("id"));
        if (data.statucode === 200) {
          const infoCotizaciones = await Promise.all(
            data.cotizaciones.map(async (cotizacion, index) => {
              const datosIdResPaso0 = await get(
                "ObtIdCuestionarioPorIdResPaso1",
                parseInt(cotizacion.id)
              );
              const responseBtBuro = await get(
                "obtDatosPaso2PorIdResPaso1",
                parseInt(cotizacion.id)
              );

              if (
                datosIdResPaso0.statucode === 200 &&
                responseBtBuro.statuscode !== 0
              ) {
                let enviarTotalBancos = 0;
                if (datosIdResPaso0.idCuestionario !== 0) {
                  const dataBLC = await get(
                    "userBancos",
                    datosIdResPaso0.idCuestionario
                  );
                  if (dataBLC.statucode === 200) {
                    enviarTotalBancos = dataBLC.bancos.length;
                  }
                }

                return {
                  key: index + 1 + "_" + cotizacion.id,
                  nombre: "Cotización " + [index + 1],
                  fechaCrecion: cotizacion.fechaCrecion,
                  valorPropiedad: cotizacion.valorPropiedad,
                  plazo: cotizacion.plazo,
                  montoSolicitado: cotizacion.montoSolicitado,
                  enganche: cotizacion.enganche,
                  tasa: cotizacion.tasaPromedio,
                  cuestionarioId: datosIdResPaso0.idCuestionario,
                  idResPaso1: cotizacion.id,
                  longitudBancos: enviarTotalBancos,
                  aprobado: cotizacion.aprobado,
                  statusBuro: responseBtBuro.statuscode,
                  mensualidad: cotizacion.pagoMensual,
                  solicitudEnProceso: cotizacion.solicitudEnProceso
                };
              }
            })
          );
          const filteredInfoCotizaciones = infoCotizaciones.filter(
            (item) => item !== undefined
          );
          setCuentaConCotizaciones(filteredInfoCotizaciones.length !== 0);
          setArergloSegmentadoCotizaciones(filteredInfoCotizaciones);
        }
      } else {
        const data = await get("userCotizacionesConProcesoBanco", cookies.get("id"));
        if (data.statucode === 200) {
          const infoCotizaciones = await Promise.all(
            data.cotizaciones.map(async (cotizacion, index) => {
              const datosIdResPaso0 = await get(
                "ObtIdCuestionarioPorIdResPaso1",
                parseInt(cotizacion.id)
              );
              const responseBtBuro = await get(
                "obtDatosPaso2PorIdResPaso1",
                parseInt(cotizacion.id)
              );

              if (
                datosIdResPaso0.statucode === 200 &&
                responseBtBuro.statuscode !== 0
              ) {
                let enviarTotalBancos = 0;
                if (datosIdResPaso0.idCuestionario !== 0) {
                  const dataBLC = await get(
                    "userBancos",
                    datosIdResPaso0.idCuestionario
                  );
                  if (dataBLC.statucode === 200) {
                    enviarTotalBancos = dataBLC.bancos.length;
                  }
                }

                return {
                  key: index + 1 + "_" + cotizacion.id,
                  nombre: "Cotización " + [index + 1],
                  fechaCrecion: cotizacion.fechaCrecion,
                  valorPropiedad: cotizacion.valorPropiedad,
                  plazo: cotizacion.plazo,
                  montoSolicitado: cotizacion.montoSolicitado,
                  enganche: cotizacion.enganche,
                  tasa: cotizacion.tasaPromedio,
                  cuestionarioId: datosIdResPaso0.idCuestionario,
                  idResPaso1: cotizacion.id,
                  longitudBancos: enviarTotalBancos,
                  aprobado: cotizacion.aprobado,
                  statusBuro: responseBtBuro.statuscode,
                  mensualidad: cotizacion.pagoMensual,
                  solicitudEnProceso: cotizacion.solicitudEnProceso
                };
              }
            })
          );
          const filteredInfoCotizaciones = infoCotizaciones.filter(
            (item) => item !== undefined
          );
          setCuentaConCotizaciones(filteredInfoCotizaciones.length !== 0);
          setArergloSegmentadoCotizaciones(filteredInfoCotizaciones);
        }
      }
    } catch (error) {
      console.error("Error al obtener la información:", error);
    } finally {
      setIsOpenModalLoading(false);
    }
  };

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    info();
  }, []);

  const [informacionCotizacionXL, setInformacionCotizacionXL] = useState({});
  return (
    <>
      {cuentaConCotizaciones === false ? (
        <div id="SinCotizacionResumenb">
          <ConCotizacionP13 />
        </div>
      ) : (
        <>
          <h3>Mis cotizaciones de crédito</h3>
          <Slider
            {...(arergloSegmentadoCotizaciones.length === 1
              ? settings1
              : arergloSegmentadoCotizaciones.length === 2
                ? settings2
                : settings3)}
          >
            {arergloSegmentadoCotizaciones.map((cotizacion) => (
              <>
                <li key={cotizacion.id}  className="cont_loop separacionCards">
                  <h3 className="textoCentrado">{cotizacion.nombre}</h3>
                  <p className="descripcion margin_top_20">Valor de propiedad</p>
                  <p className="precio">
                    ${GetNF(cotizacion.valorPropiedad)} MXN
                  </p>
                  <div className="plazo plazoCardResumen">
                    <div className="datosCardResumen">
                      <p className="widthCompleto">
                        Plazo <span>{cotizacion.plazo} años</span>
                      </p>
                    </div>
                    <div>
                      <p className="widthCompleto">
                        Creado{" "}
                        <span>{cotizacion.fechaCrecion.substr(0, 10)}</span>
                      </p>
                    </div>
                  </div>

                  <a href="#/"
                    onClick={() => {
                      setIsOpenModalCotizacion(true);
                      setInformacionCotizacionXL({
                        key: cotizacion.key,
                        nombre: cotizacion.nombre,
                        valorPropeidad: cotizacion.valorPropiedad,
                        plazo: cotizacion.plazo,
                        fecha: cotizacion.fechaCrecion,
                        montoSolicitado: cotizacion.montoSolicitado,
                        enganche: cotizacion.enganche,
                        cuestionarioId: cotizacion.cuestionarioId,
                        longitudBancos: cotizacion.longitudBancos,
                        idResPaso1: cotizacion.idResPaso1,
                        tasa: cotizacion.tasa,
                        statusBuro: cotizacion.statusBuro,
                        solicitudEnProceso: cotizacion.solicitudEnProceso
                      });
                    }}
                    className="ancho70 btn btn_morado textoCentrado"
                  >
                    Ver detalle
                  </a>
                </li>
              </>
            ))}
          </Slider>
        </>
      )}
      <ModalDetallesBancos
        key={informacionCotizacionXL.key}
        open={isOpenModalCotizacion}
        cardBanco={informacionCotizacionXL}
        close={() => setIsOpenModalCotizacion(false)}
      ></ModalDetallesBancos>
      <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
    </>
  );
}
