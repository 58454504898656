import ReactDOM from "react-dom/client";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import {NoAprobado1Logueado, NoBuroLogueado, Aprobado1 } from '../../components/Msjs'
import { Link, useNavigate } from "react-router-dom";

import "../../App.css";
import { ModalCodigoSms, ModalCasiListos, ModalLoadingGeneral } from '../../components/Modals'
import { useDataPublic } from "../../providers/dataPublic/PublicProvider";
import { ruleRFC, ruleTelefono } from "../../assets/validateRules";
import { get, post, put, getCP} from "../../utils/http";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------------------- Pantalla: Paso 1 Logueado  -------------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function RespuestaBuro(){
  const cookies = new Cookies();
  const navigate = useNavigate();

  function redireccionarCard() {
    navigate("/informacionGeneral");
  }

  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }, []);
  
  return (
    <>
      <div className="widthCompleto">
        <div className="cont_flex wrapper margin100"></div>
        <div className="cont_datos_registro wrapper centrartarjeta">
          <div className="Resum width80">
            {localStorage.getItem("sinRespuestaBuro") === "true" ? (
              <NoBuroLogueado />
            ) : localStorage.getItem("statuscodepaso2") === 200 ? (
              <Aprobado1 />
            ) : (
              <NoAprobado1Logueado />
            )}
          </div>
        </div>
        <div className="cont_datos_registro wrapper centrartarjeta">
          <button onClick={redireccionarCard} className="btn btn_morado">
            Continuar y aplicar.
          </button>
        </div>
      </div>
    </>
  );
}
