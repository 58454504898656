import { Link } from "react-router-dom";
import { Cookies } from "react-cookie";
import ObtUsuarioRemaxComponent from "../../../components/Funciones";
import PDF from "../../../archivos/Esquema_FINMAX.pdf";
import PDFSATURN5 from "../../../archivos/Saturn5-ReferenciadoresV4.pdf";
import Referencia from "../../../archivos/Referencia_FINMAX.pdf";
import { obtEmpresaUrl } from "../../../utils/global_functions";
import { useEffect, useState } from "react";
import "../../../sass/style.css";
import { ModalInicioSocios } from "../../../components/Modals"
import { get, put } from "../../../utils/http";

const Home_Socios = () => {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const idSocios = cookies.get("idSocio");
  const [empresa, setEmpresa] = useState(undefined);
  const [habilitarCarrusel, setHabilitarCarrusel] = useState(false);

  const cierraCarrusel = () => {
    setHabilitarCarrusel(false);
    localStorage.setItem("carruselInicioSesion", false);
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const empresa = await obtEmpresaUrl();
        //console.log(empresa);
        setEmpresa({
          configuracion: empresa.configuracion,
          confiAdicional: empresa.confiAdicional,
          nombre: empresa.configuracion.nombre === "M&M_Consultores" ? "M&M" : empresa.configuracion.nombre,
          logo: empresa.configuracion.nombre === "Saturn5" ? empresa.confiAdicional.logo_adicional2 : empresa.configuracion.img
        });

        if (empresa.configuracion.nombre.toLowerCase() === "remax"){
          if (localStorage.getItem("carruselInicioSesion") === null){
            const respuesta = await get("primerInicioSesion", cookies.get("idSocio"));
            if (respuesta.statuscode === 200){
              setHabilitarCarrusel(!respuesta.primerInicioSesion);
              localStorage.setItem("carruselInicioSesion", !respuesta.primerInicioSesion);
              if (!respuesta.primerInicioSesion){
                put("primerInicioSesion", {idSocio: cookies.get("idSocio"), primerInicioSesion: true});
              }
            }
          }else{
            setHabilitarCarrusel(localStorage.getItem("carruselInicioSesion") === "true" ? true : false);
          }
        }
        
        setIsLoading(false);

      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      ) : (
        <>

          {
            habilitarCarrusel && (
              <ModalInicioSocios logo={empresa.logo} onClose={cierraCarrusel}/>
            )
          }

          <div>
            <script
              type="text/javascript"
              name="ocular-solution-widget"
              src="https://widget.ocularsolution.com/service/ocular/js/ocular-widget.js?v=1.1.1"
              code="9fd80af77996af8002a56241f7e728a75mzfkXEoesiRdafdRgb8G3SLad1u49qe26MIy8ANnI10VtQHhIrBCHIA9KOs"
              data-modules="call"
              async
            ></script>

            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=G-DJWMD6QWMV"
            ></script>
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag() {
                dataLayer.push(arguments);
              }
              gtag("js", new Date());
              gtag("config", "G-DJWMD6QWMV");
            `}
            </script>
          </div>
          <ObtUsuarioRemaxComponent idSocios={idSocios} />
          <div
            className="page oxygen-aos-enabled"
            data-aos-easing="ease"
            data-aos-duration="400"
            data-aos-delay="0"
          >
            <div id="inner_content-30-16" className="ct-inner-content">
              <section id="section-2-75" className="ct-section">
                <div className="ct-section-inner-wrap">
                  <div id="div_block-3-75" className="ct-div-block">
                    <div id="div_block-93-75" className="ct-div-block">
                      <p
                        id="text_block-4-75"
                        className="ct-text-block body-small-bold"
                      >
                        {empresa.nombre}
                      </p>
                      <h1 id="headline-5-75" className="ct-headline">
                        Prevalida a tus clientes y comienza a ganar comisiones
                      </h1>
                      <p id="text_block-6-75" className="ct-text-block">
                        {empresa.nombre} se especializa en ayudar a los clientes a encontrar
                        los mejores creditos hipotecarios del mercado y en ayudarlos
                        durante el proceso de solicitud.
                        <br />
                        <br />
                        Tenemos más de 15 años de experiencia y contamos con los
                        mejores asesores hipotecarios del mercado.
                        <br />
                      </p>
                    </div>
                    <img
                      id="image-96-75"
                      alt=""
                      src="https://saturn5.mx/wp-content/uploads/2022/10/family-with-baby-meeting-financial-advisor-at-home-2021-08-26-16-13-54-utc.jpg"
                      className="ct-image"
                      srcSet="
                    https://saturn5.mx/wp-content/uploads/2022/10/family-with-baby-meeting-financial-advisor-at-home-2021-08-26-16-13-54-utc.jpg           1920w,
                    https://saturn5.mx/wp-content/uploads/2022/10/family-with-baby-meeting-financial-advisor-at-home-2021-08-26-16-13-54-utc-300x200.jpg    300w,
                    https://saturn5.mx/wp-content/uploads/2022/10/family-with-baby-meeting-financial-advisor-at-home-2021-08-26-16-13-54-utc-1024x683.jpg  1024w,
                    https://saturn5.mx/wp-content/uploads/2022/10/family-with-baby-meeting-financial-advisor-at-home-2021-08-26-16-13-54-utc-768x512.jpg    768w,
                    https://saturn5.mx/wp-content/uploads/2022/10/family-with-baby-meeting-financial-advisor-at-home-2021-08-26-16-13-54-utc-1536x1024.jpg 1536w
                  "
                      sizes="(max-width: 1920px) 100vw, 1920px"
                    />
                  </div>
                  <div
                    id="div_block-86-75"
                    className="ct-div-block"
                    data-aos="fade-up"
                    data-aos-duration="500"
                    data-aos-easing="ease-in-quart"
                    data-aos-offset="50"
                    data-aos-anchor-placement="top-bottom"
                    data-aos-once="true"
                  >
                    <div id="" className="ct-div-block">
                      <h4 id="headline-89-75" className="ct-headline">
                        <img className="img-fluid" src={empresa.logo} alt="" />
                      </h4>
                    </div>
                    <div id="shortcode-90-75">
                      <div className="wpcf7" id="wpcf7-f33-o1" lang="en" dir="ltr">
                        <div className="screen-reader-response">
                          <p
                            role="status"
                            aria-live="polite"
                            aria-atomic="true"
                          ></p>
                          <ul></ul>
                        </div>
                        <form>
                          <div className="wrap-field">
                            <p>
                              Con {empresa.nombre} puedes dar el servicio completo a tus clientes en la compra de su propiedad, acelerar el proceso de compra y ganar comisiones adicionales.
                            </p>
                            {
                              empresa.nombre === "Remax" && (
                                <p>
                                  Para conocer mas busca el curso de capacitación en la Universidad Remax.
                                </p>
                              )
                            }
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section id="section-13-75" className="ct-section">
                <div className="ct-section-inner-wrap">
                  <div id="div_block-44-75" className="ct-div-block">
                    <div id="div_block-15-75" className="ct-div-block wrap-pasos">
                      <div id="div_block-16-75" className="ct-div-block block-paso">
                        <h4
                          id="headline-17-75"
                          className="ct-headline headline-paso"
                        >
                          {
                            empresa.nombre === "Remax" ? "1. Invita" : "1. Registrate"
                          }
                        </h4>
                        <p id="text_block-18-75" className="ct-text-block">
                          {
                            empresa.nombre === "Remax" ? (
                              "Invita a todos tus clientes que estén en búsqueda de una propiedad comprada con crédito utilizando el SIG a que se precalifiquen."
                            ):(
                              `Regístrate como partner de ${empresa.nombre} y comienza a identificar quién de tus contactos necesitan o necesitarán de un crédito hipotecario.`
                            )
                          }
                          
                        </p>
                        {
                          empresa.nombre === "Remax" ? (
                            <>
                              <a
                                href={Referencia}
                                id="text_block-97-75"
                                className="ct-link-text"
                                target="_blank"
                                rel="noreferrer"
                              >
                                Como Precalificar Clientes
                              </a>
                              <Link
                                id="text_block-97-75"
                                className="ct-link-text"
                                to="/frmNuevoCliente"
                              >
                                Refiere clientes aquí
                              </Link>
                            </>
                          ):(
                            <Link
                              id="text_block-97-75"
                              className="ct-link-text"
                              to="/CrearCuentaSocios"
                            >
                              Regístrate como Partner aquí
                            </Link>
                          )
                        }
                        
                      </div>
                      <div id="div_block-22-75" className="ct-div-block block-paso">
                        <h4
                          id="headline-23-75"
                          className="ct-headline headline-paso"
                        >
                          {
                            empresa.nombre === "Remax" ? "2. Asegurate" : "2. Recomienda"
                          }
                        </h4>
                        <p id="text_block-24-75" className="ct-text-block">
                          {
                            empresa.nombre === "Remax" ? (
                              "Asegúrate de que tus clientes se hayan precalificado. Si tienen alguna duda invítalos a contactar a alguno de nuestros asesores."
                            ):(
                              `El proceso de solicitud de una hipoteca es estresante y confuso. Recomienda a ${empresa.nombre} como asesor para ayudar a tus clientes a obtener la mejor tasa de interés y facilitar el proceso. `
                            )
                          }
                          
                        </p>
                      </div>
                    </div>
                    <div className="cont-div-img">
                      <img
                        id="image-46-75"
                        alt=""
                        src="https://saturn5.mx/wp-content/uploads/2022/10/img-pareja.png"
                        className="ct-image"
                        srcset="
                      https://saturn5.mx/wp-content/uploads/2022/10/img-pareja.png          1158w,  
                      https://saturn5.mx/wp-content/uploads/2022/10/img-pareja-300x218.png   300w,
                      https://saturn5.mx/wp-content/uploads/2022/10/img-pareja-1024x743.png 1024w,
                      https://saturn5.mx/wp-content/uploads/2022/10/img-pareja-768x557.png   768w
                    "
                        sizes="(max-width: 1158px) 100vw, 1158px"
                      />
                    </div>
                    <div id="div_block-31-75" className="ct-div-block wrap-pasos">
                      <div id="div_block-38-75" className="ct-div-block block-paso">
                        <h4
                          id="headline-39-75"
                          className="ct-headline headline-paso"
                        >
                          {
                            empresa.nombre === "Remax" ? "3. Da Seguimiento" : `3. Dirígelo a ${empresa.nombre}`
                          }
                          
                        </h4>
                        {
                          empresa.nombre === "Remax" ? (
                            <p id="text_block-40-75" className="ct-text-block">
                              Da seguimiento a los clientes durante el proceso de obtención de crédito. 
                              Apóyalos a reunir la información necesaria para el trámite.
                            </p>
                          ):(
                            <>
                              <p id="text_block-40-75" className="ct-text-block">
                                {
                                  `Ingresa sus datos en nuestra plataforma y olvídate. ${empresa.nombre} hará el resto.`
                                }
                              </p>
                              <Link
                                id="text_block-97-75"
                                className="ct-link-text"
                                to="/panelSocios"
                              >
                                Refiere clientes aquí
                              </Link>
                            </>
                          )
                        }
                        
                      </div>
                      <div id="div_block-41-75" className="ct-div-block block-paso">
                        {
                          empresa.nombre === "M&M" ? (
                            <></>
                          ):(
                            <>
                              <h4
                                id="headline-42-75"
                                className="ct-headline headline-paso"
                              >
                                4. Cobra tu lana
                              </h4>
                              <p id="text_block-43-75" className="ct-text-block">
                                {
                                  empresa.nombre === "Remax" ? (
                                    "En el sistema podrás ver el avance de los clientes que nos hayas referenciado; si concluyen satisfactoriamente un crédito cobras tu comisión."
                                  ):(
                                    "Te iremos informando el estatus de los prospectos que nos hayas referenciado; si concluye satisfactoriamente cobras tu lana."
                                  )
                                }
                                
                              </p>
                            </>
                          )
                        }
                        
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {
                empresa.nombre === "M&M" ? (
                  <></>
                ):(
                  <section id="section-7-75" className="ct-section">
                    <div className="ct-section-inner-wrap">
                      <div id="div_block-8-75" className="ct-div-block">
                        <h2 id="headline-9-75" className="ct-headline">
                          Conoce el sistema de comisiones
                        </h2>
                        <div id="div_block-85-75" className="ct-div-block">
                          <a
                            id="link-83-75"
                            className="ct-link btn-ppal-neutro toggle-modal-pdf"
                            href={empresa.nombre === "Remax" ? PDF : PDFSATURN5}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <p id="text_block-84-75" className="ct-text-block">
                              Descarga el PDF
                            </p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </section>
                )
              }
              
            </div>
          </div>
        </>
      )
      }
      
    </>
  );
};

export default Home_Socios;
