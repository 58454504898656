import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, post, put } from "../../utils/http";
import Swal from "sweetalert2";
import {
  ruleCP,
  ruleEmail,
  ruleRFC,
  ruleTelefono,
  ruleNacimiento,
} from "../../assets/validateRules";
import { useForm } from "react-hook-form";
import "../../App.css";
import { obtEmpresaUrl } from "../../utils/global_functions";

export default function InfoFrmMedicaCoAcreditado() {
  const cookies = new Cookies();

  // Utiliza useForm del hook react-hook-form para gestionar el estado del formulario.
  const {
    register,
    setValue,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [isLoading, setIsLoading] = useState(true);
  // Estado para guardar información médica
  const [infoMedica, setInfoMedica] = useState({});
  const [infoEstupefaciente, setInfoEstupefaciente] = useState({});
  const [deportesAr, setDeportesAr] = useState({});

  const [peso, setPeso] = useState("");
  const [estatura, setEstatura] = useState("");
  const [consumoAlcohol, setConsumoAlcohol] = useState("No");
  const [consumoDrogas, setConsumoDrogas] = useState("No");
  const [consumoTabaco, setConsumoTabaco] = useState("No");
  const [deporteAltoRiezgo, setDeporteAltoRiezgo] = useState("No");
  const [tieneEnfermedad, setTieneEnfermedad] = useState("No");
  const [enfermedadDescripcion, setEnfermedadDescripcion] = useState("");
  const [descripcionDeshabilitada, setDescripcionDeshabilitada] = useState(true);

  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  //Para detectar cambios en radios y guardarlos
  const handleRadioChangeDeporteAltoRiezgo = (event) => {
    setDeporteAltoRiezgo(event.target.value);
  };

  const handleRadioChangeAlchol = (event) => {
    setConsumoAlcohol(event.target.value);
  };

  const handleRadioChangeDrogas = (event) => {
    setConsumoDrogas(event.target.value);
  };

  const handleRadioChangeTabaco = (event) => {
    setConsumoTabaco(event.target.value);
  };

  const handleRadioChangeTieneEnfermedad = (event) => {
    setTieneEnfermedad(event.target.value);
    if (event.target.value === "false") {
      setEnfermedadDescripcion("");
    }
  };

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const obtInformacionMedica = async () => {
    let dataMedica = await get("getInfoMedicaCoAcreditado", cookies.get("id"));
    if (dataMedica.statuscode === 200) {
      // console.log(dataMedica);

      //* Establecemos todos los campos para mostrarlos y que las validaciones no fallen
      setPeso(dataMedica.infoMedica[0].peso);
      setValue("peso", dataMedica.infoMedica[0].peso);

      setEstatura(dataMedica.infoMedica[0].estatura);
      setValue("estatura", dataMedica.infoMedica[0].estatura);

      setDeporteAltoRiezgo(dataMedica.infoMedica[0].deportesAr === "0" ? "false" : "true");
      setValue("deporteAltoRiezgo", dataMedica.infoMedica[0].deportesAr === "0" ? "false" : "true");

      //TODO: agregar a la bd
      //!de momento usamos los campos de diabetes
      setConsumoAlcohol(dataMedica.infoMedica[0].diabetis.toString());
      setValue("consumoAlcohol", dataMedica.infoMedica[0].diabetis.toString());

      //TODO: agregar a la bd drogas
      //!de momento usamos los campos de cancer
      setConsumoDrogas(dataMedica.infoMedica[0].cancer.toString());
      setValue("consumoDrogas", dataMedica.infoMedica[0].cancer.toString());

      setConsumoTabaco(dataMedica.infoMedica[0].consumoCigarro.toString());
      setValue(
        "ConsumoTabaco",
        dataMedica.infoMedica[0].consumoCigarro.toString()
      );

      setTieneEnfermedad(dataMedica.infoMedica[0].tieneEnfermedad === 0 ? "false" : "true");
      setValue("tieneEnfermedad", dataMedica.infoMedica[0].tieneEnfermedad === 0 ? "false" : "true");

      setEnfermedadDescripcion(dataMedica.infoMedica[0].enfermedadDescripcion);
      setValue(
        "enfermedadDescripcion",
        dataMedica.infoMedica[0].enfermedadDescripcion
      );
    } else {
      console.log("no hay información medica");
    }
  };

  const guardarInformacionMedica = async () => {
    //TODO: Falta campo consumo alcohol
    //TODO: Falta campo consumo drogas
    let dataMedica = {
      idUser: parseInt(cookies.get("id")),
      estadoSalud: "string",
      tratamientoMedico: "string",
      enfermedadesCv: false,
      diabetis: consumoAlcohol === "true" ? true : false, //Nota: debido a falta de tiempo estos campos quedan para guardar alcohol pero hay que crear su propio campo
      cancer: consumoDrogas === "true" ? true : false, //Nota: debido a falta de tiempo estos campos quedan para guardar drogas pero hay que crear su propio campo
      deportesAr: deporteAltoRiezgo === "true" ? "1" : "0",
      ocupacionAr: "",
      consumoCigarro: consumoTabaco === "true" ? true : false,
      peso: parseFloat(peso),
      estatura: parseFloat(estatura),
      idResPrecalifica: parseInt(idResPrecalifica),
      tieneEnfermedad: tieneEnfermedad === "true" ? 1 : 0,
      enfermedadDescripcion: enfermedadDescripcion,
      escoAcreditado: true,
    };
    //Consulamos si ya hay información previamente guardada
    let eleccionIU = await get("getInfoMedicaCoAcreditado", cookies.get("id"));

    //Insertar
    if (eleccionIU.msg === "El usuario no tiene informacion medica") {
      let guardarInfoMedica = await post("addInfoMedicaCoAcredito", dataMedica);
      if (guardarInfoMedica.statuscode === 200) {
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setTimeout(() => {
          window.location.href = "./InfocoAcreditado";
        }, 1000);
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        let actualizar = await put("updateInfoMedica", dataMedica);

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
          setTimeout(() => {
            window.location.href = "./InfocoAcreditado";
          }, 1000);
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    obtInfoCuestionario();
    obtInformacionMedica();
  }, []);

  useEffect(() => {
    if (tieneEnfermedad === "true" || 
      deporteAltoRiezgo === "true" ||
      consumoAlcohol === "true" ||
      consumoDrogas === "true" ||
      consumoTabaco === "true"){
      setDescripcionDeshabilitada(false);
    }else{
      setEnfermedadDescripcion("");
      setDescripcionDeshabilitada(true);
    }
  }, [deporteAltoRiezgo, consumoAlcohol, consumoDrogas, consumoTabaco, tieneEnfermedad])

  const onSubmit = (data) => {
    guardarInformacionMedica();
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/InfocoAcreditado">
          {"<"} Regresar a documentación
        </a>

        <h1 className="titt">Información Médica del Coacreditado</h1>

        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>

        <div className="Resum">
          <div className="header">
            <h3></h3>
          </div>

          <div className="cont_flex cont-form">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/*Peso */}
              <div className="col3 campo-form">
                <label>¿Peso? (kg)</label>
                <input
                  type="number"
                  {...register("peso", { required: true })}
                  id="peso"
                  name="peso"
                  value={peso || ""}
                  size="14"
                  maxLength={10}
                  autoComplete="off"
                  onInput={(ev) => {
                    setPeso(ev.target.value);
                  }}
                />
                {errors.peso?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*estatura */}
              <div className="col3 campo-form">
                <label>¿Estatura? (mt)</label>
                <input
                  type="number"
                  {...register("estatura", { required: true })}
                  id="estatura"
                  name="estatura"
                  value={estatura}
                  onInput={(ev) => {
                    setEstatura(ev.target.value);
                  }}
                />
                {errors.estatura?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*Deportes alto riesgo */}
              <div className="col3 campo-form">
                <label>¿Deportes de alto riesgo?</label>

                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      {...register("deporteAltoRiezgo", { required: true })}
                      type="radio"
                      name="deporteAltoRiezgo"
                      id="inlineRadio1_deporteAltoRiezgo"
                      value={true}
                      checked={deporteAltoRiezgo === "true"}
                      onChange={handleRadioChangeDeporteAltoRiezgo}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_deporteAltoRiezgo"
                    >
                      Si
                    </label>
                  </div>

                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      {...register("deporteAltoRiezgo", { required: true })}
                      type="radio"
                      name="deporteAltoRiezgo"
                      id="inlineRadio2_deporteAltoRiezgo"
                      value={false}
                      checked={deporteAltoRiezgo === "false"}
                      onChange={handleRadioChangeDeporteAltoRiezgo}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_deporteAltoRiezgo"
                    >
                      No
                    </label>
                  </div>
                </div>
                {errors.deporteAltoRiezgo?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*alcohol */}
              <div className="col3 campo-form">
                <label>¿Consumo de alcohol?</label>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      {...register("consumoAlcohol", { required: true })}
                      type="radio"
                      name="consumoAlcohol"
                      id="inlineRadio1_alcohol"
                      value={true}
                      checked={consumoAlcohol === "true"}
                      onChange={handleRadioChangeAlchol}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_alcohol"
                    >
                      Si
                    </label>
                  </div>

                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      {...register("consumoAlcohol", { required: true })}
                      type="radio"
                      name="consumoAlcohol"
                      id="inlineRadio2_alcohol"
                      value={false}
                      checked={consumoAlcohol === "false"}
                      onChange={handleRadioChangeAlchol}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_alcohol"
                    >
                      No
                    </label>
                  </div>
                </div>
                {errors.consumoAlcohol?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*Drogas */}
              <div className="col3 campo-form">
                <label>¿Consumo de drogas?</label>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      {...register("consumoDrogas", { required: true })}
                      type="radio"
                      name="consumoDrogas"
                      id="inlineRadio1_drogas"
                      value={true}
                      checked={consumoDrogas === "true"}
                      onChange={handleRadioChangeDrogas}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_drogas"
                    >
                      Si
                    </label>
                  </div>

                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      {...register("consumoDrogas", { required: true })}
                      type="radio"
                      name="consumoDrogas"
                      id="inlineRadio2_drogas"
                      value={false}
                      checked={consumoDrogas === "false"}
                      onChange={handleRadioChangeDrogas}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_drogas"
                    >
                      No
                    </label>
                  </div>
                </div>
                {errors.consumoDrogas?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              {/*Cigarro */}
              <div className="col3 campo-form">
                <label>¿Consumo de cigarro?</label>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      {...register("consumoTabaco", { required: true })}
                      type="radio"
                      name="consumoTabaco"
                      id="inlineRadio1_tabaco"
                      value={true}
                      checked={consumoTabaco === "true"}
                      onChange={handleRadioChangeTabaco}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_tabaco"
                    >
                      Si
                    </label>
                  </div>

                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      {...register("consumoTabaco", { required: true })}
                      type="radio"
                      name="consumoTabaco"
                      id="inlineRadio2_tabaco"
                      value={false}
                      checked={consumoTabaco === "false"}
                      onChange={handleRadioChangeTabaco}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_tabaco"
                    >
                      No
                    </label>
                  </div>
                </div>
                {errors.consumoTabaco?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <hr />

              <div className="justificado">
                <label>
                  ¿Padece o ha padecido alguna de las siguientes enfermedades?{" "}
                </label>

                <p>
                  ¿Leucemia, sida, insuficiencia renal, diabetes, epilepsia,
                  enfermedades del hígado (por ejemplo hepatitis crónica,
                  cirrosis, insuficiencia hepática), enfermedades del corazón
                  (por ejemplo angina de pecho, infarto al miocardio, cirugía
                  del corazón), hipertensión, tumores malignos o cáncer,
                  hemorragias o tumor cerebral, padecimientos psiquiátricos,
                  cirugías de columna, glaucoma, sordera, enfermedad pulmonar
                  obstructiva crónica?
                </p>

                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      {...register("tieneEnfermedad", { required: true })}
                      type="radio"
                      name="tieneEnfermedad"
                      id="inlineRadio1_tieneEnfermedad"
                      value={true}
                      checked={tieneEnfermedad === "true"}
                      onChange={handleRadioChangeTieneEnfermedad}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_tieneEnfermedad"
                    >
                      Si
                    </label>
                  </div>

                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      {...register("tieneEnfermedad", { required: true })}
                      type="radio"
                      name="tieneEnfermedad"
                      id="inlineRadio2_tieneEnfermedad"
                      value={false}
                      checked={tieneEnfermedad === "false"}
                      onChange={handleRadioChangeTieneEnfermedad}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_tieneEnfermedad"
                    >
                      No
                    </label>
                  </div>
                </div>
                {errors.tieneEnfermedad?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form">
                <label>
                  En caso de cualquier si, por favor especifique (Opcional)
                </label>

                {/*<input
                                    type="text"
                                    id="cualEnfermedad"
                                    name="cualEnfermedad"
                                    value={enfermedadDescripcion || ''}
                                    onInput={(ev) => {setEnfermedadDescripcion(ev.target.value.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, ""))}}
                                />*/}
                <textarea
                  id="cualEnfermedad"
                  name="cualEnfermedad"
                  value={enfermedadDescripcion || ""}
                  maxLength={200} // Limita a 200 caracteres
                  disabled={descripcionDeshabilitada}
                  onInput={(ev) => {
                    // Asegúrate de que el valor no supere los 200 caracteres
                    const inputValue = ev.target.value.substring(0, 200);
                    setEnfermedadDescripcion(
                      inputValue.replace(/[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g, "")
                    );
                  }}
                ></textarea>
              </div>

              <div className="cont_flex cont-form">
                <div className="dosCuttonFormularios">
                  <a
                    className="btn btn_bco  campo-form "
                    href="/InfocoAcreditado"
                  >
                    {" "}
                    Regresar
                  </a>
                  <button type="submit" className="btn btn_morado campo-form">
                    Guardar
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
      </div>
    </>
  );
}
