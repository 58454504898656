import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get, put, post } from "../../utils/http";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom/client";
import {
  TarjetaDiasVencidos,
  TarjetaSolicitud,
  PasosAseguir,
} from "../../components/Cards";
import {
  TarjetaCalculoDias,
  RenderizadoSolicitudAceptada,
  RenderizadoSolicitudRechazada,
  RenderizadoSolicitudRequireMasInfo,
} from "../../components/Cards";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import { guardarPaso, obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";
import { Cookies } from "react-cookie";

//TODO: Falta verificar que sucedería si es rechazada o vence el plazo de la solicitud

export default function SolicitudAceptada() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const cookies = new Cookies();
  // Estado para almacenar información sobre el estado de la solicitud.
  const [statusSolicitud, setStatusSolicitud] = useState({});
  //IdResPrecalifica
  const idResPrecalifica = localStorage.getItem("idResPrecalifica");
  //IdCuestionarioSolicitud
  const idCuestionarioSolicitud = localStorage.getItem(
    "idCuestionarioSolicitud"
  );
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);
  //Para comprobar el estado del acceso
  const [acceso, setAcceso] = useState(false);
  //Para comprobar el estado si tenemos informacion del banco
  const [informacionBanco, setInformacionBanco] = useState(false);

  //*Para comprobar si se ha iniciado el proceso
  const comprobarAcceso = () => {
    if (
      idResPrecalifica !== undefined &&
      idResPrecalifica !== null &&
      idCuestionarioSolicitud !== undefined &&
      idCuestionarioSolicitud !== null
    ) {
      setAcceso(true);
    }
  };

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // let nombrePantalla  = "2.Aceptar solicitud";
      // var urlActual = window.location.pathname;
      // let credito = localStorage.getItem("idCredito");

      // let  pantallaActualMenu = <PasosAseguir
      //     nombrePantalla ={nombrePantalla}
      //     urlActual = {urlActual}
      //     credito = {credito}
      // />

      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = "2. ";
      let descripcionPaso = " Aceptar solicitud";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 2, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  // Función para obtener información de una solicitud aceptada.
  const infoSolicitudAceptada = async () => {
    let dataSolicitudAceptada = await get(
      "getSolicitudAceptada",
      idResPrecalifica
    );

    if (dataSolicitudAceptada.statuscode === 200) {
      setInformacionBanco(true);

      // Se obtiene información de la solicitud aceptada y se actualiza el estado.
      setStatusSolicitud(dataSolicitudAceptada.solicitud[0].estatus);

      if (dataSolicitudAceptada.solicitud[0].estatus === 0) {
        // Si la solicitud está aceptada, se crea una tarjeta de solicitud y se muestra en el DOM.
        let solicitudAceptada = (
          <TarjetaSolicitud
            producto={dataSolicitudAceptada.solicitud[0].producto}
            prestamoSoli={dataSolicitudAceptada.solicitud[0].prestamoSolicitado}
            mensulidadIni={
              dataSolicitudAceptada.solicitud[0].mensualidadInicial
            }
            tasa={dataSolicitudAceptada.solicitud[0].tasa}
            tipoTasa={dataSolicitudAceptada.solicitud[0].tipoTasa}
            plazo={dataSolicitudAceptada.solicitud[0].plazo}
            costoApertura={dataSolicitudAceptada.solicitud[0].costoApertura}
            avaluo={dataSolicitudAceptada.solicitud[0].avaluo}
            seguros={dataSolicitudAceptada.solicitud[0].seguros}
            costoPagoAnticipado={
              dataSolicitudAceptada.solicitud[0].costoPagoAnticipado
            }
            prestamoMaximo={dataSolicitudAceptada.solicitud[0].prestamoMaximo}
          />
        );

        const root1 = ReactDOM.createRoot(
          document.getElementById("tarjetaSolicitud")
        );
        root1.render(solicitudAceptada);

        if (dataSolicitudAceptada.solicitud[0].fechaSolicitud !== "") {
          // Si hay una fecha de solicitud, se calculan los días y se muestra en el DOM.
          let fechaComparar = dataSolicitudAceptada.solicitud[0].fechaSolicitud;
          // 20-09-2023
          //Vamos a comparar la fecha de solicitud con la fecha actual y en base a eso
          //Mostraremos un componente u otro

          // Parsear la fecha en un objeto Date
          let fechaCom = new Date(fechaComparar);
          // Obtener la fecha actual en milisegundos desde la época
          let fechaActualTimestamp = Date.now();
          // Obtener el timestamp de la fecha a comparar
          var fechaCompararTimestamp = fechaCom.getTime();
          // Comparar los timestamps

          /*if (fechaCompararTimestamp > fechaActualTimestamp) {
            //Si la fecha de solicitud es menor a la fecha actual
            let calculoDias = (
              <TarjetaCalculoDias fechaComparar={fechaComparar} />
            );
            const root2 = ReactDOM.createRoot(
              document.getElementById("calculoDias")
            );
            root2.render(calculoDias);
          } else if (fechaCompararTimestamp < fechaActualTimestamp) {
            //La fecha ya ha pasado
            let calculoDias = <TarjetaDiasVencidos />;
            const root2 = ReactDOM.createRoot(
              document.getElementById("calculoDias")
            );
            root2.render(calculoDias);
          } else {*/
          //Definir que pasa si las fechas son iguales
          //Si la fecha de solicitud es menor a la fecha actual
          let calculoDias = (
            <TarjetaCalculoDias fechaComparar={fechaComparar} />
          );
          const root2 = ReactDOM.createRoot(
            document.getElementById("calculoDias")
          );
          root2.render(calculoDias);
          //}
        }
      }
    } else {
      setInformacionBanco(false);
    }
  };

  // Función para avanzar a la siguiente etapa de la solicitud.
  const siguiente = async () => {
    await aceptarSolicitud();

  };

  const aceptarSolicitud = async () => 
  {
    let params = {
      idResPrecalifica: localStorage.getItem("idResPrecalifica"),
      idCuestionario:localStorage.getItem("idCuestionarioSolicitud"),
    };

    let aceptarSolicitud = await post("aceptarSolicitud", params);
    if (aceptarSolicitud.statuscode === 200){
      await enviarDatosOnbase();
    }
  }

  const enviarDatosOnbase = async () => 
  {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idUser: parseInt(cookies.get("id"))
    };
    let enviarInformacion = await post("enviarInformacionCompletaViviendaOnbase", params);


      if(enviarInformacion.statuscode === 200){
        console.log("Información enviada");
        dispararCorreo();
        dipararCorreoCliente();
        //await guardarPaso(3, idCuestionarioSolicitud, idResPrecalifica);
        //actualizarProceso(idResPrecalifica, cookies.get("id"), null, 1);
        window.location.href = "/documentosVivienda";
      }
      
  }

  const regresar = async () => {
    guardarPaso(1, idCuestionarioSolicitud, idResPrecalifica);
    window.location.href = "/descargarSolicitud";
  };

  const dispararCorreo = async () => {
    //Ejecutivo
    let paramsEjecutivo = {
      email: localStorage.getItem("correoEjecutivo"),
      tipo: 9,
    };

    let mandarCorreo = await post("enviarCorreos", paramsEjecutivo);
    if (mandarCorreo.statuscode === 200)
      // console.log("Correo enviado a ejecutivo");
      console.log("");
  };

  const dipararCorreoCliente = async () => {
    //Cliente
    let paramsCliente = {
      email: cookies.get("correo"),
      tipo: 17,
    };
    console.log(paramsCliente);
    let mandarCorreoCliente = await post("enviarEmail", paramsCliente);
    if (mandarCorreoCliente.statuscode === 200)
      // console.log("Correo enviado a cliente");
      console.log();
  };

  // Efecto que se ejecuta al cargar el componente.
  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    infoSolicitudAceptada();
    obtPantallaActual();
    comprobarAcceso();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      {acceso === true && (
        <div className=" cont_Info">
          <Link to="/home" className="link linkGrande back">
            {" "}
            {"<"} Regresar a mis solicitudes
          </Link>

          <div id="MenuRenderizado" className="MenuRenderizado"></div>

          {statusSolicitud === 0 ? (
            <RenderizadoSolicitudAceptada />
          ) : statusSolicitud === 1 ? (
            <RenderizadoSolicitudRechazada />
          ) : statusSolicitud === 2 ? (
            <RenderizadoSolicitudRequireMasInfo />
          ) : (
            ""
          )}

          <div id="tarjetaSolicitud"></div>

          <br />

          {statusSolicitud === 0 ? <h3 className="titt notas">Notas</h3> : ""}

          <hr />

          {statusSolicitud === 0 ? <div id="notas"></div> : ""}

          <br />

          <div id="calculoDias"></div>

          {statusSolicitud === 0 ? (
            <>
              <a
                href="#"
                onClick={() => {
                  regresar();
                }}
                className="btn btn_big"
              >
                Rechazar
              </a>
              <a
                href="#"
                className="btn btn_morado btn_big"
                onClick={() => {
                  siguiente();
                }}
              >
                Aceptar
              </a>
            </>
          ) : (
            <a
              className="btn btn_morado"
              onClick={() => {
                siguiente();
              }}
            >
              Aceptar
            </a>
          )}
        </div>
      )}
      {acceso === false && (
        <div className=" cont_Info">
          <h1>Debes iniciar el proceso</h1>
          <p>
            Da click en el banco donde quieras iniciar el proceso en el menú del
            lado izquierdo.
          </p>
        </div>
      )}
    </>
  );
}
