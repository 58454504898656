import "../App.css";
import "../sass/style.css";
import { Link } from "react-router-dom";
import Cookies from "universal-cookie";
import { get } from "../utils/http";
import { useState, useEffect } from "react";
import { BancosNavHome } from "./Cards";
import {
  verificarCoacreditado,
  obtEmpresaUrl,
} from "../utils/global_functions";
import { useDataPublic, useSetDataPublic } from "../providers/dataPublic/PublicProvider";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <----------------------------------------- Menú lateral cuando el usuario esta logueado ----------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function NavHome({ active }) {
  const dataPublic = useDataPublic();
  const setDataPublic = useSetDataPublic();
  const cookies = new Cookies();
  const [bancosSubM, setBancosSubM] = useState([]);
  const [resultado, setResultado] = useState(0);
  const [infoCompleta, setInfoCompleta] = useState(false);
  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  //JPB 28-11-2023 Co acredtiado se evalua para saber si mostrar el boton en el nav lateral
  const [esCoAcreditado, setEsCoAcreditado] = useState(false);

  //JPB 27-11-2023 Funcion que revisa si el usuario tiene coacreditado
  async function revisarCoacreditado() {
    try {
      const tieneCoAcrediado = await verificarCoacreditado(cookies.get("id"));
      if (tieneCoAcrediado === true) {
        setEsCoAcreditado(true);
      } else {
        setEsCoAcreditado(false);
      }
    } catch (error) {
      console.error("Ocurrió un error al revisar el coacreditado:", error);
    }
    setDataPublic({actualizarNav: false});
  }

  /*--------------------------------------------------------------------------------------------*/
  /*JPB 28-11-2023 Para revisar si el usuario ha llenado la informacion general de co acreditado*/
  /*--------------------------------------------------------------------------------------------*/
  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idResPrecalifica = await get("getInfoCalifica", cookies.get("id"));
    if (idResPrecalifica.statuscode === 200) {
      setIdResPrecalifica(
        idResPrecalifica.infoCalifica[0].cuestionarioColeccion[0]
          .resPrecalificas.idResPrecalifica
      );
      verificarSecciones(
        idResPrecalifica.infoCalifica[0].cuestionarioColeccion[0]
          .resPrecalificas.idResPrecalifica
      );
    }
  };

  const verificarSecciones = async (idResPrecalifica) => {
    try {
      let dataInfoGeneral = await get(
        "getVerificarSeccionInformacionCoAcreditado",
        idResPrecalifica
      );
      if (dataInfoGeneral.statuscode === 200) {
        if (
          dataInfoGeneral.respuestas.infoPersonal &&
          dataInfoGeneral.respuestas.infoLaboral &&
          dataInfoGeneral.respuestas.infoMedica &&
          dataInfoGeneral.respuestas.infoDocInicial &&
          dataInfoGeneral.respuestas.infoDatosExtras
        ) {
          setInfoCompleta(true);
        } else {
          setInfoCompleta(false);
        }
      }
    } catch {
      // console.log("Error al verificar secciones");
    }
  };

  const info = async () => {
    try {
      const idCuestionario = await get("getInfoCalifica", cookies.get("id"));
      let arrbancos = [];

      if (
        idCuestionario.statuscode === 200 &&
        idCuestionario.infoCalifica.length > 0
      ) {
        idCuestionario.infoCalifica.forEach((infoCalifica) => {
          infoCalifica.cuestionarioColeccion.forEach((cuestionario) => {
            arrbancos.push({
              banco: cuestionario.resPrecalificas.productoNombre,
              idResPrecalifica: cuestionario.resPrecalificas.idResPrecalifica,
              idCuestionario: cuestionario.resPrecalificas.idCuestionario,
              idCredito: cuestionario.idCredito,
              idActividadEconomica: cuestionario.idActividadEconomica,
              idDemoCi: cuestionario.idDemoCi,
            });
          });
        });
      }

      setBancosSubM(arrbancos);
      //setResultado(arrbancos.length);
    } catch (error) {
      console.error("Error en la función info:", error);
    }
  };

  const actualizarDemoCI = async () => {
    let demo = await get("obtenerUltimoIdDemoCI", cookies.get("id"));
    if (demo.statuscode === 200) {
      localStorage.setItem("idDemoCi", demo.ultimoIdDemoCI);
    }
  };
  const estiloConCursorDefault = {
    cursor: "default",
  };

  useEffect(() => {

    async function inizializarPantalla(){
      await revisarCoacreditado();
      await obtInfoCuestionario();
      await verificarSecciones();
      await obtEmpresaUrl();
      await info();
    }

    inizializarPantalla();
    
    //revisarInfoCompleta();
  }, []);

  // Actualiza el contenido del menú lateral 
  useEffect(() => {
    if(dataPublic?.actualizarNav === true){
      revisarCoacreditado();
      info();
    }
  }, [dataPublic?.actualizarNav]);

  return (
    <>
      <div className="menu_lateral">
        <ul>
          <li>
            <Link to="/home" className={active === "01" ? "active" : ""}>
              <i className="fa fa-th-large"></i> Mi Tablero
            </Link>
          </li>
          <li>
            <Link to="/misDatos" className={active === "02" ? "active" : ""}>
              <i className="fa  fa-user"></i> Mis Datos
            </Link>
          </li>
          <li>
            <Link to="/cotizaciones" className={active === "03" ? "active" : ""}>
              <i className="fa  fa-file"></i> Mis Cotizaciones
            </Link>
          </li>
          <li>
            <Link
              //JPB 15-01-2024 Esta opcion ya no te redirige a los bancos seleccionados dejando el cursor con el estilo por defecto
              to="/cotizacionesAplicadas"
              className={active === "04" ? "active" : ""}
            >
              <i className="fa fa-file"></i> Mis Solicitudes de Crédito
            </Link>
          </li>
          {
            /*
             <li>
            <Link to="/miHipoteca" className={active === "07" ? "active" : ""}>
              <i className="fa fa-money"></i> Mi Hipoteca
            </Link>
          </li>
            */
          }
          <li className="subMenu">
            <a
              href="/informacion-general"
              className={active === "05" ? "active" : ""}
              onClick={actualizarDemoCI}
            >
              <i className="fa fa-info"></i> Información General
            </a>
          </li>

          <div>
            <ul>
              {/*JPB 27-11-2023 Solo se muestra informormacion general coacreditado si el usuario tiene coacreditado*/}
              {esCoAcreditado === true ? (
                <li className="subMenu">
                  <a
                    href="/InfocoAcreditado"
                    className={active === "06" ? "active" : ""}
                  >
                    <i className="fa fa-info"></i> Información General
                    Coacreditado
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </div>

          {/* Renderizar BancosNavHome directamente */}
          {bancosSubM.map((banco) => (
            <BancosNavHome
              key={banco.idResPrecalifica}
              // key={banco.idCuestionario}
              banco={banco.banco}
              idCuestionario={banco.idCuestionario}
              idResPrecalifica={banco.idResPrecalifica}
              idCredito={banco.idCredito}
              //idDemoCi={banco.idDemoCi}
              idActividadEconomica={banco.idActividadEconomica}
              infoCompleta={infoCompleta}
              clase={
                String(localStorage.getItem("idResPrecalifica")) ===
                  String(banco.idResPrecalifica) &&
                String(localStorage.getItem("idCuestionarioSolicitud")) ===
                  String(banco.idCuestionario)
                  ? "active"
                  : ""
              }
            />
          ))}
        </ul>
        <div className="copyright">
          <p>
            En caso de que tengas preguntas, dudas o necesites asesoría no dudes
            en contactarnos. Nos encanta atenderte a la hora que lo necesites
          </p>
          <li>
            {" "}
            <Link to="/AvisoPrivacidadLogueado" className="link">
              Aviso de privacidad
            </Link>
          </li>
          <li>
            {" "}
            <Link to="/TerminosCondicionesLogueado" className="link">
              Términos y condiciones
            </Link>
          </li>
          <p>© 2024. Saturn5 Technologies SAPI de CV</p>
        </div>
      </div>
    </>
  );
}
