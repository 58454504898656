import "../../App.css";
import { useState, useEffect } from "react";
import { Cookies } from "react-cookie";
import { get, post } from "../../utils/http";
import {
  ConBancosSeleccionadosP1,
  ConCotizacionP2,
} from "../../components/Msjs";
import * as ReactDOM from "react-dom/client";
import { obtEmpresaUrl, verificarCoacreditado } from "../../utils/global_functions";
import { ModalLoadingGeneral, ModalFavorLlenarInformacion } from "../../components/Modals";
import { InfoCotizacionAplicadaXL } from "../../components/Cards";
import { SinSolicitudes } from "../../components/Msjs";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <------------------------------------ Pantalla donde se muestran las cotizaciones APLICADAS del usuario ----------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function CotizacionesAplicadas() {
  const [isLoading, setIsLoading] = useState(true);
  const [isOpenModalLoading, setIsOpenModalLoading] = useState(true);
  const [tieneSolicitudes, setTieneSolicitudes] = useState(false);
  //JPB 11-01-2024 Para verificar que haya llenado informacion general
  const [isOpenInformacionSubida, setIsOpenInformacionSubida] = useState(false);
  const [btnCoAcreditadoHabilitado, setBtnCoAcreditadoHabilitado] = useState(false);

  let infoCompleta = false;
  let infoCompletaCoAcreditado = false;
  let esCoAcreditado = false;
  
  const cookies = new Cookies();

  localStorage.removeItem("idResPaso1Cuestionario");
  localStorage.removeItem("BancoLS1");
  localStorage.removeItem("BancoLS2");
  localStorage.removeItem("idResPaso1SeleccionarBancos");
  localStorage.removeItem("verBancos");
  localStorage.removeItem("plazoParaBancosSeleccionados");

  localStorage.removeItem("monto");
  localStorage.removeItem("plazoVal");
  localStorage.removeItem("montoPrestar");
  localStorage.removeItem("enganche");
  localStorage.removeItem("tasa");
  localStorage.removeItem("mensualidad");
  localStorage.removeItem("sinRespuestaBuro");

  localStorage.removeItem("statuscodepaso2");

  localStorage.removeItem("idCuestionarioSolicitud");
  localStorage.removeItem("idResPrecalifica");
  localStorage.removeItem("idCredito");
  localStorage.removeItem("idActividadEconomica");

  const info = async () => {
    setIsOpenModalLoading(true);

    try {
      const idCuestionario = await get("getInfoCalifica", cookies.get("id"));

      if (idCuestionario.statuscode === 200 && idCuestionario.infoCalifica.length > 0) {
        setTieneSolicitudes(true);
        const arrbancos = (
          await Promise.all(
            idCuestionario.infoCalifica.map(async (infoCalifica) => {
              return Promise.all(
                infoCalifica.cuestionarioColeccion.map(async (cuestionario) => {
                  const pantalla = await get(
                    "getPantallaActual",
                    cuestionario.resPrecalificas.idResPrecalifica
                  );
                  let estadoBanco =
                    pantalla.statuscode === 200
                      ? (
                          await get(
                            "getEstadoBanco",
                            pantalla.infoProcesoPantalla.idCatPantallas
                          )
                        ).pantallas.nombre
                      : "Proceso no iniciado";

                  return {
                    key: cuestionario.resPrecalificas.idResPrecalifica,
                    banco: cuestionario.resPrecalificas.productoNombre,
                    idResPrecalifica:
                      cuestionario.resPrecalificas.idResPrecalifica,
                    idCuestionario: cuestionario.resPrecalificas.idCuestionario,
                    idCredito: cuestionario.idCredito,
                    idActividadEconomica: cuestionario.idActividadEconomica,
                    Mensualidad: cuestionario.resPrecalificas.pagoMensual,
                    tasa: cuestionario.resPrecalificas.tasa,
                    tasaPromedio: cuestionario.tasaPromedio,
                    bancoSeleccionado:
                      cuestionario.resPrecalificas.bancoSeleccionado,
                    creditoOfertado: cuestionario.resPrecalificas.prestamoMax,
                    idTarjeta: cuestionario.resPrecalificas.idResPrecalifica,
                    descripcion: "",
                    estado: estadoBanco,
                    plazo: cuestionario.plazo,
                  };
                })
              );
            })
          )
        ).flat();

        const bancosSeleccionados = arrbancos.map((banco, index) => {
          return (
            <InfoCotizacionAplicadaXL
              index={index}
              bancoSeleccionado={banco.bancoSeleccionado}
              idTarjeta={banco.idTarjeta}
              idResPrecalifica={banco.idResPrecalifica}
              idCuestionario={banco.idCuestionario}
              idCredito={banco.idCredito}
              idActividadEconomica={banco.idActividadEconomica}
              banco={banco.banco}
              descripcion={banco.descripcion}
              creditoOfertado={banco.creditoOfertado}
              plazo={banco.plazo}
              mensualidad={banco.Mensualidad}
              tasaPromedio={banco.tasaPromedio}
              tasa={banco.tasa}
              estado={banco.estado}
              onClic={verificarSecciones}
            />
          )
        })
   
        const root = ReactDOM.createRoot(
          document.getElementById("todasCotizaciones")
        );
        
        root.render(<>{bancosSeleccionados}</>);

        ReactDOM.createRoot(
          document.getElementById("cont-CotizacionesP1")
        ).render(<ConBancosSeleccionadosP1 />);
        ReactDOM.createRoot(
          document.getElementById("cont-CotizacionesP2")
        ).render(<ConCotizacionP2 />);

        setIsOpenModalLoading(false);

      } else {
        setIsOpenModalLoading(false);
        setTieneSolicitudes(false);
        console.log("No hay bancos seleccionados");
      }
    } catch (error) {
      console.error("Error en la función info:", error);
    }

  };

  const verificarSecciones = async (idRes, idCues, idCred, idActEco) => {
    try {
      /*-----------------Evalua si ya subio la informacion general del usuario y del coacreditado----------------------*/
      // const tieneCoAcrediado = await verificarCoacreditado(cookies.get('id'));
      const tieneCoAcrediado = await get(
        "verificarSiExisteCoAcreditado",
        cookies.get("id")
      );
      if (tieneCoAcrediado.coAcreditado === true) {
        // setEsCoAcreditado(true)
        esCoAcreditado = true;
        //JPB Para informacion del usuario
        let dataInfoGeneral = await get(
          "getVerificarSeccionInformacion",
          cookies.get("id")
        );
        if (dataInfoGeneral.statuscode === 200) {
          if (
            dataInfoGeneral.respuestas.infoPersonal &&
            dataInfoGeneral.respuestas.infoLaboral &&
            dataInfoGeneral.respuestas.infoMedica &&
            dataInfoGeneral.respuestas.infoDocInicial
          ) {
            // setInfoCompleta(true)
            infoCompleta = true;
          } else {
            // setInfoCompleta(false)
            infoCompleta = false;
          }
        }
        //JPB Para informacion del coACrditado
        let dataInfoGeneralCoAcreditado = await get(
          "getVerificarSeccionInformacionCoAcreditado",
          cookies.get("id")
        );
        if (dataInfoGeneralCoAcreditado.statuscode === 200) {
          if (
            dataInfoGeneralCoAcreditado.respuestas.infoPersonal &&
            dataInfoGeneralCoAcreditado.respuestas.infoLaboral &&
            dataInfoGeneralCoAcreditado.respuestas.infoMedica &&
            dataInfoGeneralCoAcreditado.respuestas.infoDocInicial &&
            dataInfoGeneralCoAcreditado.respuestas.infoDatosExtras
          ) {
            // setInfoCompletaCoAcreditado(true)
            infoCompletaCoAcreditado = true;
          } else {
            // setInfoCompletaCoAcreditado(false)
            infoCompletaCoAcreditado = false;
          }
        }
        /*-----------------Esta parte se encarga de redireccinar una vez que se evaluo si cuentan con coAcreditado----------------------*/

        /*-----------------En caso de si tener coacreditado----------------------*/
        //Redireccina al paso en que se quedo el usuario
        if (infoCompleta && infoCompletaCoAcreditado) {
          setIsOpenInformacionSubida(false);
          // isOpenInformacionSubida = true;
          infoPantalla(idRes, idCues, idCred, idActEco);
        } else {
          setIsOpenInformacionSubida(true);
          // isOpenInformacionSubida = false;
        }
        // console.log(infoCompleta);
        // console.log(infoCompletaCoAcreditado);
        // console.log(isOpenInformacionSubida);
      } else {
        // console.log("entro al else que no deberia");
        // setEsCoAcreditado(false)
        esCoAcreditado = false;
        /*-----------------En caso de no tener coacreditado----------------------*/
        let dataInfoGeneral = await get(
          "getVerificarSeccionInformacion",
          cookies.get("id")
        );
        if (dataInfoGeneral.statuscode === 200) {
          if (
            dataInfoGeneral.respuestas.infoPersonal &&
            dataInfoGeneral.respuestas.infoLaboral &&
            dataInfoGeneral.respuestas.infoMedica &&
            dataInfoGeneral.respuestas.infoDocInicial
          ) {
            // setInfoCompleta(true)
            infoCompleta = true;
          } else {
            infoCompleta = false;
            // setInfoCompleta(false)
          }
        }

        /*-----------------Esta parte se encarga de redireccinar una vez que se evaluo si cuentan con coAcreditado----------------------*/
        if (infoCompleta) {
          setIsOpenInformacionSubida(false);
          // isOpenInformacionSubida = true;
          infoPantalla(idRes, idCues, idCred, idActEco);
        } else {
          setIsOpenInformacionSubida(true);
          // isOpenInformacionSubida = false;
        }
      }
    } catch {
      //fin del try
      // console.log("Error al verificar secciones");
    }
  }; //fin funcion verificar secciones

  const infoPantalla = async (idResPre, idCuesti, idCredito, idActividadEconomica) => {
    localStorage.setItem("idResPrecalifica", idResPre);
    localStorage.setItem("idCuestionarioSolicitud", idCuesti);
    localStorage.setItem("idCredito", idCredito);
    localStorage.setItem("idActividadEconomica", idActividadEconomica);

    let dataPantalla = await get("getPantallaActual", idResPre);

    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
        
        if(
          localStorage.getItem('idResPrecalifica') == null && 
          localStorage.getItem('idCuestionarioSolicitud') == null &&
          localStorage.getItem("idCredito", idCredito) == null &&
          localStorage.getItem("idActividadEconomica", idActividadEconomica) == null
        ){
          infoPantalla(idResPre,idCuesti);
        }

        switch (idPantallaActual) {
          case 1:
            window.location.href = "../../descargarSolicitud";
            break;

          case 2:
            window.location.href = "../../SolicitudAceptada";
            break;

          case 3:
            window.location.href = "../../documentosVivienda";
            break;

          case 4:
            window.location.href = "../../avaluo";
            break;

          case 5:
            window.location.href = "../../institucionExterna";
            break;

          case 6:
            window.location.href = "../../formalizacionFirma";
            break;

          case 7:
            window.location.href = "../../felicidades";
            break;

          case 11:
            window.location.href = "../../notariaAsignada";
            break;

          default:
            break;
        }
      }
    } else {
      
      const response = await get("getPantallaActual", idResPre);
      //console.log("respuesta: ", response);

      if(response.statuscode === 200){
        window.location.href = "../../descargarSolicitud";
      }else{
        Swal.fire({
          title: "Solicitud en proceso",
          text: "Espere a que un ejecutivo lo atienda ",
          icon: "info",
          confirmButtonText: "Aceptar",
        });
      }
      
      /*Swal.fire({
        title: "Upss...",
        html: "<b>No hay tramites iniciados con ese banco <br> ¿Deseas iniciar el proceso?</b>",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Iniciar",
        cancelButtonText: "No",
      }).then((result) => {
        if (result.isConfirmed) {
          iniciarProceso();
        }
      });*/
    }
  };
  
  const iniciarProceso = async () => {
    let idCuestionarioSolicitud = localStorage.getItem(
      "idCuestionarioSolicitud"
    );
    let idResPrecalifica = localStorage.getItem("idResPrecalifica");
    // Obtén la fecha actual
    let fechaActual = new Date();
    // Obtiene el año, mes y día
    var año = fechaActual.getFullYear();
    var mes = (fechaActual.getMonth() + 1).toString().padStart(2, "0"); // El mes se indexa desde 0, por lo que se suma 1
    var dia = fechaActual.getDate().toString().padStart(2, "0");
    // Formatea la fecha en "YYYY-MM-DD"
    let fechaFormateada = año + "-" + mes + "-" + dia;
    const params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idCatPantallas: 1,
      fechaCreacion: fechaFormateada,
      fechaAct: fechaFormateada,
    };

    let insertProcesoPantalla = await post("addPantallaActual", params);
    if (insertProcesoPantalla.statuscode === 200) {
      Swal.fire({
        title: "Proceso iniciado",
        icon: "success",
        confirmButtonText: "Aceptar",
      }).then(() => {
        window.location.href = "../../descargarSolicitud";
      });
    } else {
      Swal.fire({
        title: "Datos ingresados erroneos",
        text: "Verifica que tus datos sean correctos ",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  async function revisarCoacreditado() {
    try {
      const tieneCoAcrediado = await verificarCoacreditado(cookies.get("id"));
      if (tieneCoAcrediado === true) {
        setBtnCoAcreditadoHabilitado(true);
      } else {
        setBtnCoAcreditadoHabilitado(false);
      }
    } catch (error) {
      console.error("Ocurrió un error al revisar el coacreditado:", error);
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await revisarCoacreditado();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    info();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className="cont_Info">
        <div className="Resum cont-Cotizaciones" id="cont-CotizacionesP1"></div>
        {
          tieneSolicitudes ? (
            <>
              <div className="cont_informacion_btns">
                <Link to="/informacion-general" className="btn btn_morado">
                  Información general
                </Link>
                {
                  btnCoAcreditadoHabilitado && (
                    <Link to="/InfocoAcreditado" className="btn btn_morado">
                      Información general coacreditado
                    </Link>
                  )
                }
              </div>  

              <div className="cont_flex con_flex_wrap contenido_centrado flex_start" id="todasCotizaciones"></div>
                            
            </>
          ):(
            <>
              <SinSolicitudes />{" "}
            </>
          )
        }
        
        <div className="Resum cont-Cotizaciones" id="cont-CotizacionesP2"></div>
      </div>
      
      <ModalFavorLlenarInformacion
        tieneCoAcrediado={esCoAcreditado}
        infoUsuario={infoCompleta}
        infoCoAcreditado={infoCompletaCoAcreditado}
        open={isOpenInformacionSubida}
        close={() => setIsOpenInformacionSubida(false)}
      />
      
      <ModalLoadingGeneral open={isOpenModalLoading}></ModalLoadingGeneral>
    </>
  );
}
