import ReactDOM from "react-dom/client";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import "../../App.css";
import Rectangle from "../../images/Rectangle-3.jpg";
import Swal from "sweetalert2";
import { get, post } from "../../utils/http";
import { obtEmpresaUrl } from "../../utils/global_functions";

export default function InfoGeneralCoAcreditado() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const [infoPersonal, setInfoPersonal] = useState("");
  const [infoLaboral, setInfoLaboral] = useState("");
  const [infoMedica, setInfoMedica] = useState("");
  const [infoDocsInicial, setDocsInicial] = useState("");
  const [infoDatosExtras, setInfoDatosExtras] = useState("");
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idResPrecalifica = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idResPrecalifica.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
    verificarSecciones(
      idResPrecalifica.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const verificarSecciones = async (idResPrecalifica) => {
    let dataInfoGeneral = await get(
      "getVerificarSeccionInformacionCoAcreditado",
      cookies.get("id")
    );

    if (dataInfoGeneral.statuscode === 200) {
      //* Info personal
      if (dataInfoGeneral.respuestas.infoPersonal) {
        setInfoPersonal("informacionGeneralCompletado active");
      }

      //* Info laboral
      if (dataInfoGeneral.respuestas.infoLaboral) {
        setInfoLaboral("informacionGeneralCompletado active");
      }

      //* Info medica
      if (dataInfoGeneral.respuestas.infoMedica) {
        setInfoMedica("informacionGeneralCompletado active");
      }

      //* Info documentacion inciial
      if (dataInfoGeneral.respuestas.infoDocInicial) {
        setDocsInicial("informacionGeneralCompletado active");
      }

      //*Info Datos Extras
      if (dataInfoGeneral.respuestas.infoDatosExtras) {
        setInfoDatosExtras("informacionGeneralCompletado active");
      }

      if (
        dataInfoGeneral.respuestas.infoPersonal &&
        dataInfoGeneral.respuestas.infoLaboral &&
        dataInfoGeneral.respuestas.infoMedica &&
        dataInfoGeneral.respuestas.infoDocInicial &&
        dataInfoGeneral.respuestas.infoDatosExtras
      ) {
        if (dataInfoGeneral.respuestas.coAcreditado == true) {
          setBotonDeshabilitado(false);
        }
      }
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    obtInfoCuestionario();
  }, []);

  //Para evaluar todas las secciones antes de enviar.
  const completarProceso = async () => {
    if (!botonDeshabilitado) {
      let data = await post("correoInfoGeneralCompleta", cookies.get("correo"));
      if (data.statuscode === 200) {
        // console.log("correo enviado correctamente");
      }
    } else {
      Swal.fire({
        title: "Faltan datos",
        text: "Faltan datos por llenar",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/home">
          {"<"} Regresar a mis solicitudes hipotecarias
        </a>
        <div className="dosColumns cont_flex">
          <div className="col6">
            <h1 className="titt">
              Antes de continuar te pediremos la siguiente informaci&oacute;n
            </h1>
            <h2 className="titt">Informaci&oacute;n del Coacreditado</h2>
            <p>
              Requerimos de la siguiente información para continuar con tu
              solicitud. En caso de que tengas alguna pregunta o no puedas
              contestar algún campo, no dudes de contactar a nuestros ejecutivos
              a través del icono de cámara en la esquina inferior derecha.
            </p>

            <ul className="check">
              <li className={"linkInfoGeneralCoAcreditado " + infoDocsInicial}>
                <Link to="/informacion/inf-DocumentacionInicial-coAcreditado">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Doc. Inicial
                      Coacreditado{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>

              <li className={"linkInfoGeneralCoAcreditado " + infoPersonal}>
                <Link to="/frmPersonal-coAcreditado">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Información personal
                      Coacreditado{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>

              <li className={"linkInfoGeneralCoAcreditado " + infoLaboral}>
                <Link to="/frmInfoLaboral-coAcreditado">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Información laboral
                      Coacreditado{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>

              <li className={"linkInfoGeneralCoAcreditado " + infoMedica}>
                <Link to="/frmInfoMedica-coAcreditado">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i> Información médica
                      Coacreditado{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
              <li className={"linkInfoGeneralCoAcreditado " + infoDatosExtras}>
                <Link to="/frmDatosExtra">
                  <div className="btn_documentacion">
                    <div className="main_text">
                      <i className="fa fa-circle-check"></i>Datos Extra{" "}
                    </div>
                    <div className="sub_btn_doc">
                      <span>
                        Editar <i className="fa fa-pencil"></i>
                      </span>
                    </div>
                  </div>
                </Link>
              </li>
            </ul>

            <Link
              to={!botonDeshabilitado ? "/informacionEnviada" : "#"}
              className="btn btn_gris width_auto"
              onClick={completarProceso}
              disabled={botonDeshabilitado}
            >
              Enviar documentos
            </Link>
          </div>
          <div className="cont_img col6">
            {" "}
            <img src={Rectangle} alt="img-info-general" />
          </div>
        </div>
      </div>
    </>
  );
}
