import ReactDOM from "react-dom/client";
import { useState, useEffect, useRef } from "react";
import Cookies from "universal-cookie";
import { set, useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import "../../App.css";
import Rectangle from "../../images/Rectangle-3.jpg";
import { DocInicial, DocInicialMultiple } from "../../components/Cards";
import { get, post, put, getCP, postDocs } from "../../utils/http";
import {
  ruleRFC,
  ruleTelefono,
  ruleNacimiento,
  ruleCURP,
  ruleNumeroSeguridad,
} from "../../assets/validateRules";
import { obtEmpresaUrl } from "../../utils/global_functions";

export function PreInformacion() {
  const cookies = new Cookies();
  const [cuestionarioPreCalificas, setcuestionarioPreCalificas] = useState("");
  const [activadoInfoLaboral, setActivadoInfoLaboral] = useState("");
  const [activadInfoInmuebles, setActivadoInfoInmuebles] = useState("");
  const [activadInfoPersonal, setActivadInfoPersonal] = useState("");
  const [activadInfoMedica, setActivadInfoMedica] = useState("");

  const info = async () => {
    // console.log(cookies.get("correo"));
    let data = await get("infoUser", cookies.get("correo"));

    if (data.statuscode === 200) {
      // console.log(data);
      if (data.user.cuestionarioPreCalificas.length > 0) {
        // console.log("si tiene cuestionario precalifica");
      }

      if (data.user.infoPersonals.length > 0) {
        if (
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].numeroSeguroSocial !== null &&
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].correo !== null &&
          data.user.infoPersonals[0].correo !== null
        ) {
        }
        setActivadInfoPersonal("informacionGeneralCompletado active");
      }

      if (data.user.infoMedicas.length > 0) {
        setActivadInfoMedica("informacionGeneralCompletado active");
      }

      if (data.user.infoLaborals.length > 0) {
        // console.log(data.user.infoLaborals);
        if (
          data.user.infoLaborals[0].calle !== null &&
          data.user.infoLaborals[0].colonia != null &&
          data.user.infoLaborals[0].cp != null &&
          data.user.infoLaborals[0].estado != null &&
          data.user.infoLaborals[0].fechaIngreso != null &&
          data.user.infoLaborals[0].fechaIngresoAnt != null &&
          data.user.infoLaborals[0].giro != null &&
          data.user.infoLaborals[0].idInfoLaboral != null &&
          data.user.infoLaborals[0].municipio != null &&
          data.user.infoLaborals[0].nombreEmpresa != null &&
          data.user.infoLaborals[0].nombreEmpresaAnt != null &&
          data.user.infoLaborals[0].numExt != null &&
          data.user.infoLaborals[0].ocupacion != null &&
          data.user.infoLaborals[0].ocupacionAnt != null &&
          data.user.infoLaborals[0].puesto != null &&
          data.user.infoLaborals[0].telefono != null &&
          data.user.infoLaborals[0].telefonoAnt != null &&
          data.user.infoLaborals[0].tipoEmpleo != null &&
          data.user.infoLaborals[0].tipoEmpleoAnt != null &&
          data.user.infoLaborals[0].calle !== "" &&
          data.user.infoLaborals[0].colonia != "" &&
          data.user.infoLaborals[0].cp != "" &&
          data.user.infoLaborals[0].estado != "" &&
          data.user.infoLaborals[0].fechaIngreso != "" &&
          data.user.infoLaborals[0].fechaIngresoAnt != "" &&
          data.user.infoLaborals[0].giro != "" &&
          data.user.infoLaborals[0].idInfoLaboral != "" &&
          data.user.infoLaborals[0].municipio != "" &&
          data.user.infoLaborals[0].nombreEmpresa != "" &&
          data.user.infoLaborals[0].nombreEmpresaAnt != "" &&
          data.user.infoLaborals[0].numExt != "" &&
          data.user.infoLaborals[0].ocupacion != "" &&
          data.user.infoLaborals[0].ocupacionAnt != "" &&
          data.user.infoLaborals[0].puesto != "" &&
          data.user.infoLaborals[0].telefono != "" &&
          data.user.infoLaborals[0].telefonoAnt != "" &&
          data.user.infoLaborals[0].tipoEmpleo != "" &&
          data.user.infoLaborals[0].tipoEmpleoAnt != ""
        ) {
          console.log("si tiene cuestionario info lab");
          setActivadoInfoLaboral("informacionGeneralCompletado active");
        }
      }

      if (data.user.infoInmuebles.length > 0) {
        // console.log(data.user.infoInmuebles);
        if (
          data.user.infoInmuebles[0].aMaternoVendedor !== null &&
          data.user.infoInmuebles[0].aPaternoVendedor !== null &&
          data.user.infoInmuebles[0].calle !== null &&
          data.user.infoInmuebles[0].colonia !== null &&
          data.user.infoInmuebles[0].cp !== null &&
          data.user.infoInmuebles[0].emailVendedor !== null &&
          data.user.infoInmuebles[0].idInfoImbueble !== null &&
          data.user.infoInmuebles[0].idUser !== null &&
          data.user.infoInmuebles[0].municipio !== null &&
          data.user.infoInmuebles[0].noExt !== null &&
          data.user.infoInmuebles[0].noInt !== null &&
          data.user.infoInmuebles[0].nombreVendedor !== null &&
          data.user.infoInmuebles[0].aMaternoVendedor !== "" &&
          data.user.infoInmuebles[0].aPaternoVendedor !== "" &&
          data.user.infoInmuebles[0].calle !== "" &&
          data.user.infoInmuebles[0].colonia !== "" &&
          data.user.infoInmuebles[0].cp !== "" &&
          data.user.infoInmuebles[0].emailVendedor !== "" &&
          data.user.infoInmuebles[0].idInfoImbueble !== "" &&
          data.user.infoInmuebles[0].idUser !== "" &&
          data.user.infoInmuebles[0].municipio !== "" &&
          data.user.infoInmuebles[0].noExt !== "" &&
          data.user.infoInmuebles[0].noInt !== "" &&
          data.user.infoInmuebles[0].nombreVendedor !== ""
        ) {
          // console.log("si tiene cuestionario precalifica");
          setActivadoInfoInmuebles("informacionGeneralCompletado active");
        }
      }
    }
  };

  useEffect(() => {
    info();
    //infoCalifica();
  }, []);

  //Para evaluar todas las secciones antes de enviar.
  const completarProceso = async () => {
    //let data = await get('infoUser', (cookies.get('correo')) );
    let data = await post("enviarCorreo");
    if (data.statuscode === 200) {
      // console.log("correo enviado correctamente");
    }
  };

  return (
    <>
      <div className=" cont_Info">
        <a className="back" href="/cotizaciones">
          {" "}
          {"<"} Regresar a mis solicitudes hipotecarias
        </a>
        <div className="dosColumns cont_flex">
          <div className="col6">
            <h1 className="titt">
              Antes de continuar te pediremos la siguiente información
            </h1>
            <p>
              Requerimos de la siguiente información para continuar con tu
              solicitud. En caso de que tengas alguna pregunta o no puedas
              contestar algún campo, no dudes de contactar a nuestros ejecutivos
              a través del icono de cámara en la esquina inferior derecha.
            </p>

            <ul className="check">
              <li className={activadInfoPersonal}>
                <Link to="/informacion/inf-personal">
                  <i className="fa fa-circle-check"></i> Información personal{" "}
                  <span>
                    Editar <i className="fa fa-pencil"></i>
                  </span>
                </Link>
              </li>
              <li className={activadoInfoLaboral}>
                <Link to="/frmInfoLaboral">
                  <i className="fa fa-circle-check"></i> Información laboral{" "}
                  <span>
                    Editar <i className="fa fa-pencil"></i>
                  </span>
                </Link>
              </li>
              <li className={activadInfoMedica}>
                <Link to="/frmInfoMedica">
                  <i className="fa fa-circle-check"></i> Información médica{" "}
                  <span>
                    Editar <i className="fa fa-pencil"></i>
                  </span>
                </Link>
              </li>

              <li>
                <Link to="/informacion/inf-DocumentacionInicial">
                  <i className="fa fa-circle-check"></i> Documentacion Inicial{" "}
                  <span>
                    Editar <i className="fa fa-pencil"></i>
                  </span>
                </Link>
              </li>
            </ul>

            <Link
              to="/informacionEnviada"
              className="btn btn_gris"
              onClick={completarProceso}
            >
              Enviar documentos
            </Link>
          </div>
          <div className="cont_img col6">
            {" "}
            <img src={Rectangle} />
          </div>
        </div>
      </div>
    </>
  );
}

export function InfoDocumentacionInicial() {
  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [recargarDocumentos, setRecargarDocumentos] = useState(false);

  //Para obtener informacion general del usuario
  const obtInfoCuestionario = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const [documentacionVivienda, setDocumentacionVivienda] = useState({
    documento: null, // Estado para almacenar el archivo seleccionado
  });

  const handleDocumentoCambios = async (event, errorFunction = () => {}) => {
    const files = event.target.files
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;
    //console.log('archivos seleccionados:', selectedFile)
    if (inputName == "2") {
      let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
      const selectedFiles = event.target.files;
      //console.log('Archivos seleccionados1:', selectedFiles);
      if (selectedFiles.length > 0) {
        try {
          let insertDocumento;
          for (let i = 0; i < selectedFiles.length; i++) {
            const formData = new FormData();
            //let file = selectedFiles[i];
            //console.log("Archivos seleccionados2: ", selectedFiles[i]);
            formData.append("Archivo", selectedFiles[i]);
            formData.append("IdUser", cookies.get("id"));
            formData.append(
              "IdResPrecalifica",
              idCuestionario.infoCalifica[0].cuestionarioColeccion[0]
                .resPrecalificas.idResPrecalifica
            );
            formData.append("IdTipoDocInicial", inputName);
            formData.append("escoAcreditado", false); //JPB 14-11-2023 Diferencia si es inforacion de coAcreditado
            insertDocumento = await postDocs("subirArchivo", formData);
          }

          if (insertDocumento.statuscode === 200) {
            setRecargarDocumentos(true);
          } else {
            errorFunction();
            Swal.fire({
              title: "Datos ingresados erróneos",
              text: "Verifica que tus datos sean correctos ",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } catch (error) {
          // Maneja errores aquí
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    } else {
      let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
      if (selectedFile) {
        setDocumentacionVivienda((prevInfo) => ({
          ...prevInfo,
          documento: selectedFile,
        }));

        const formData = new FormData();
        formData.append("Archivo", selectedFile);
        formData.append("IdUser", cookies.get("id"));
        formData.append(
          "IdResPrecalifica",
          idCuestionario.infoCalifica[0].cuestionarioColeccion[0]
            .resPrecalificas.idResPrecalifica
        );
        formData.append("IdTipoDocInicial", inputName);
        formData.append("escoAcreditado", false);
        try {
          let insertDocumento = await postDocs("subirArchivo", formData);
          if (insertDocumento.statuscode === 200) {
            setRecargarDocumentos(true);
            //navigate("/solicitudAceptada");
          } else {
            errorFunction();
            Swal.fire({
              title: "Datos ingresados erroneos",
              text: "Verifica que tus datos sean correctos ",
              icon: "error",
              confirmButtonText: "Aceptar",
            });
          }
        } catch (error) {
          // Maneja errores aqui
        }
      } else {
        // console.log("No se seleccionó ningún archivo");
      }
    }
  };

  //Para verificar si ya tiene un documento
  const verificarDocumento = async (idTipoDocumento) => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    let idRes =
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica;
    let params = {
      idResPrecalifica: idRes,
      idUser: parseInt(cookies.get("id")),
      idTipoDocInicial: idTipoDocumento,
      escoAcreditado: false,
    };
    //console.log('verifica documentos: ', idTipoDocumento);
    var verificarDocumento = null;
    if (idTipoDocumento == 2){
      verificarDocumento = await post("getInfoDocumentoInicialMultiple", params);
    //console.log(params);
    }else{
      verificarDocumento = await post("getInfoDocumentoInicial", params);
    }

    if (verificarDocumento.statuscode === 200) {
      try {
        // console.log("es: " + verificarDocumento.value.esCoAcreditado);
        // console.log("es id: " + verificarDocumento.value.idTipoDocInicial);
        if (idTipoDocumento == 2){
          const sonCoAcreditados = verificarDocumento.value.map((archivo) => archivo.esCoAcreditado === false)
          //console.log(sonCoAcreditados)
          if (!sonCoAcreditados.includes(false)){
            return {
              archivos: verificarDocumento.value,
              info: verificarDocumento.info,
              escoAcreditado: false, //JPB 13-11-2023 Es false ya que estamos subiendo en la seccion de Info Coacreditado  ¿?----------------------
            };
          }else{
            return verificarDocumento.info;
          }
        }else{
          if (verificarDocumento.value.esCoAcreditado === false){
            let cadena = verificarDocumento.value.archivo;
            // Dividir la cadena por las barras invertidas para obtener partes
            const partes = cadena.split("/");

            // Obtener la última parte que contiene el nombre de archivo y extensión
            const nombreArchivoConExtension = partes[partes.length - 1];

            return {
              nombreDoc: nombreArchivoConExtension,
              info: verificarDocumento.info,
              escoAcreditado: false, //JPB 13-11-2023 Es false ya que estamos subiendo en la seccion de Info Coacreditado  ¿?----------------------
            };
          }else{
            return verificarDocumento.info;
          }
        }
      } catch {
        // console.log("catch");
        return verificarDocumento.info;
      }
    } else {
      // console.log("esta vacio");
      return verificarDocumento.info;
    }
  };

  //Para obtener todos los documentos
  var docs;
  const obtDocumentosInicial = async () => {
    let data = await get("getTipoDocumentos", "");
    if (data.statuscode === 200) {
      let documentos = [];
      let arrDocs = data.documentos;
      //console.log(arrDocs);
      // Coloca el card que permite subir múltiples archivos al final
      arrDocs = arrDocs.concat(arrDocs.splice(1, 1));

      // Crear un array de promesas para verificar los documentos
      const verificarPromises = arrDocs.map(async (documento) => {
        try {
          let resVerDoc = await verificarDocumento(documento.idTipoDocInicial);
          //console.log(resVerDoc);
          let parrafo = "";
          let nota = "";
          //localStorage.setItem("idDemoCi", 2);
          //let acti = localStorage.getItem("idDemoCi");
          //JPB 21-09-2023 Extraigo el dato del localstorage para conocer su actividad economica
          //let actividad = parseInt(acti);
          //console.log(actividad);
          let demo = await get("obtenerUltimoIdDemoCI", cookies.get("id"));
          let actividad = (demo.statuscode === 200) ? demo.ultimoIdDemoCI : -1;
          //console.log(documento.idTipoDocInicial);
          switch (documento.idTipoDocInicial) {
            case 1:
              parrafo = "INE o pasaporte";
              nota = "Asegúrate que la identificación sea vigente y legible";
              break;

            case 2:
              parrafo = "Comprobante de Ingresos";
              switch (actividad) {
                case 1:
                  nota = "Anexa los últimos 3 meses de recibos de nomina.";
                  break;

                case 2:
                  nota =
                    "Anexa la ultima declaración de impuestos anual y parcial.";
                  break;

                case 3:
                  nota =
                    "Anexa 6 meses donde se reflejen los ingresos que comprueben tus ingresos.";
                  break;

                case 4:
                  nota =
                    "Anexar los últimos 3 meses de recibos de nomina y carta laboral que justifique ingresos.";
                  break;

                case 5:
                  nota = "Anexar los últimos 3 meses de estados de cuenta.";
                  break;
                case 6:
                  nota =
                    "Anexa la ultima declaración de impuestos anual y parcial.";
                  break;
                case 7:
                  nota = "Anexa el ultimo año de estados financieros";
                  break;

                default:
                  nota = "Opción no válida";
                  break;
              }
              break;

            case 3:
              parrafo = "Comprobante de domicilio";
              nota =
                "Menor a 3 meses de antigüedad - De preferencia CFE, Agua, Predial o Telefonia";
              break;

            default:
              break;
          }

          if (documento.idTipoDocInicial == 2){
            return {
              titulo: documento.nombre,
              parrafo: parrafo,
              tipoDocumento: documento.idTipoDocInicial,
              docVerificado: resVerDoc.info,
              nota: nota,
              archivos: resVerDoc.archivos
            }
          }else{
            return {
              titulo: documento.nombre,
              parrafo: parrafo,
              tipoDocumento: documento.idTipoDocInicial,
              docVerificado: resVerDoc.info,
              nota: nota,
              nombreDocumento: resVerDoc.nombreDoc,
              escoAcreditado: false, //JPB 13-11-2023 Es true ya que estamos subiendo en la seccion de Info Coacreditado
            };
          }
        } catch (error) {
          console.error("Error al verificar el documento", error);
          return null;
        }
      });

      // Esperar a que todas las promesas se completen
      const documentosWithVerificaciones = await Promise.all(verificarPromises);

      // Filtrar los resultados nulos en caso de errores
      const documentosValidos = documentosWithVerificaciones.filter(
        (doc) => doc !== null
      );

      if (documentosWithVerificaciones.length === 0) {
        // console.log("No hay documentos");
      } else {
        docs = documentosWithVerificaciones.map((documento) => {
          if (documento.tipoDocumento == 2) {
            return (<DocInicialMultiple
              coAcreditado={false}
              titulo={documento.titulo}
              descripcion={documento.parrafo}
              tipoDocumento={documento.tipoDocumento}
              onDocumentoChange={handleDocumentoCambios}
              documentoSubido={documento.docVerificado}
              nota={documento.nota}
              archivos={documento.archivos}
            />)
          } else {
            return (<DocInicial
              coAcreditado={false}
              titulo={documento.titulo}
              descripcion={documento.parrafo}
              tipoDocumento={documento.tipoDocumento}
              onDocumentoChange={handleDocumentoCambios}
              documentoSubido={documento.docVerificado}
              nota={documento.nota}
              nombreDocumento={documento.nombreDocumento}
            />)
          }         
          
        });

        const root = ReactDOM.createRoot(
          document.getElementById("documentosRender")
        );

        root.render(docs);
      }
    }
  };

  async function obtDocIdentificacion(){
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    let idRes = idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas.idResPrecalifica;

    let res = await post("registrarINEComoDocInicial", {
      idUser: parseInt(cookies.get("id")),
      idResPrecalifica: idRes,
      esCoAcreditado: false,
    });
    //console.log(res);
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await obtDocIdentificacion();
        await obtInfoCuestionario();
        await obtDocumentosInicial();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
  }, []);

  useEffect(()=>{
    if (recargarDocumentos){
      obtDocumentosInicial();
      setRecargarDocumentos(false);
    }
  }, [recargarDocumentos]);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/informacion-general">
          {"<"} Regresar a documentación
        </a>
        <h1 className="titt">Documentación Inicial</h1>
        <p>
          Aseg&uacute;rate que la identificaci&oacute;n sea vigente y legible
        </p>
        <div className="cont_flex con_flex_wrap cont_subidor_archivos responsivoColumn" id="documentosRender"></div>
        {/* <CompIngresos demostrarIngresos={demostrarIngresos} /> */}
        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <a className="btn btn_bco campo-form " href="/informacion-general">
              Regresar
            </a>
            <a
              className="btn btn_morado campo-form"
              href="/informacion-general"
            >
              Guardar
            </a>
          </div>
        </div>
        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
      </div>
    </>
  );
}

export function InfoLaborar() {
  const cookies = new Cookies();
  const {
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [direccion, setDireccion] = useState("");
  const [exterior, setExterior] = useState("");
  const [interior, setInterior] = useState("");
  const [estado, setEstado] = useState("");
  const [estadoAux, setEstadoAux] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [colonia, setColonia] = useState("");
  const [cp, setCp] = useState("");

  const [validarLongitudTelefono, setValidarLongitudTelefono] = useState();
  const [validarLongitudTelefonoAnt, setValidarLongitudTelefonoAnt] =
    useState();

  const [validarReglaTelefono, setValidarReglaTelefono] = useState();
  const [validarReglaTelefonoAnt, setValidarReglaTelefonoAnt] = useState();
  const [validarReglaNacimiento, setValidarReglaNacimento] = useState();
  const [validarReglaFechaIngresoAnt, setValidarReglaFechaIngresoAnt] =
    useState();
  const [validarReglaFechaSalida, setValidarReglaFechaSalida] = useState();

  const [infoLaboral, setInfoLaboral] = useState({});
  const [idUsuario, setIdUsuario] = useState({});

  const [idResPrecalifica, setIdResPrecalifica] = useState({});

  const infoCalifica = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));

    // console.log(idCuestionario);
    // console.log(
    //   idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
    //     .idResPrecalifica
    // );
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const info = async () => {
    // console.log(cookies.get("id"));
    let data = await get("getInfoLaboral", cookies.get("id"));
    // console.log(data);
    // console.log(data.infoLaboral[0].fechaIngreso);
    // console.log(data.statuscode);
    if (data.statuscode === 200) {
      if (data.infoLaboral[0].fechaIngreso) {
        let fechaIngreso = new Date(data.infoLaboral[0].fechaIngreso);

        if (fechaIngreso.getMonth() + 1 < 10 && fechaIngreso.getDate() < 10) {
          // console.log("entro 1");
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
        } else if (fechaIngreso.getDate() < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-0" +
            fechaIngreso.getDate();
          // console.log("entro 2");
        } else if (fechaIngreso.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-0" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
          // console.log("entro 3");
        } else {
          // console.log(data.infoLaboral[0].fechaIngreso);
          // console.log(fechaIngreso);
          data.infoLaboral[0].fechaIngreso =
            fechaIngreso.getFullYear() +
            "-" +
            (fechaIngreso.getMonth() + 1) +
            "-" +
            fechaIngreso.getDate();
          // console.log("entro 4");
        }
        // validarEndeudamiento(data.infoLaboral[0].fechaIngreso)
        // console.log(data.infoLaboral[0].fechaIngreso);

        let fechaIN =
          data.infoLaboral[0].fechaIngreso.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngreso.substr(0, 4);
        data.infoLaboral[0].fechaIngreso = fechaIN;
        // console.log(fechaIN);
      }

      if (data.infoLaboral[0].fechaIngresoAnt) {
        let fechaIngresoAnt = new Date(data.infoLaboral[0].fechaIngresoAnt);

        if (
          fechaIngresoAnt.getMonth() + 1 < 10 &&
          fechaIngresoAnt.getDate() < 10
        ) {
          // console.log("entro 1");
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
        } else if (fechaIngresoAnt.getDate() < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-0" +
            fechaIngresoAnt.getDate();
          // console.log("entro 2");
        } else if (fechaIngresoAnt.getMonth() + 1 < 10) {
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-0" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
          // console.log("entro 3");
        } else {
          // console.log(data.infoLaboral[0].fechaIngresoAnt);
          // console.log(fechaIngresoAnt);
          data.infoLaboral[0].fechaIngresoAnt =
            fechaIngresoAnt.getFullYear() +
            "-" +
            (fechaIngresoAnt.getMonth() + 1) +
            "-" +
            fechaIngresoAnt.getDate();
          // console.log("entro 4");
        }
        // validarEndeudamiento(data.infoLaboral[0].fechaIngreso)
        // console.log(data.infoLaboral[0].fechaIngresoAnt);

        let fechaIN =
          data.infoLaboral[0].fechaIngresoAnt.substr(8, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(5, 2) +
          "/" +
          data.infoLaboral[0].fechaIngresoAnt.substr(0, 4);
        data.infoLaboral[0].fechaIngresoAnt = fechaIN;
        // console.log(fechaIN);
      }

      setEstado(data.infoLaboral[0].estado);
      setMunicipio(data.infoLaboral[0].municipio);
      setColonia(data.infoLaboral[0].colonia);
      setCp(data.infoLaboral[0].cp);
      setDireccion(data.infoLaboral[0].calle);
      setExterior(data.infoLaboral[0].numExt);
      setInterior(data.infoLaboral[0].numInt);

      setInfoLaboral(() => ({
        ...infoLaboral,
        ...data.infoLaboral[0],
      }));
    }
  };

  useEffect(() => {
    info();
    infoCalifica();
  }, []);

  const obtenerDataCp = async (cp) => {
    let respuestaPorCP = await getCP("info_cp", cp);

    obtenerClaveIso(respuestaPorCP.response.estado);
    listColoniasCP(respuestaPorCP.response.asentamiento);
    setMunicipio(respuestaPorCP.response.municipio);
  };

  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    setEstadoAux(estadoCP);
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso);
    }
  };

  const listColoniasCP = (colonias) => {
    let opcionDefault = (
      <option value="" selected="selected" disabled>
        Seleccione una opción...{" "}
      </option>
    );

    colonias = colonias.map((colonia) => (
      <option value={colonia} key={colonia}>
        {colonia}
      </option>
    ));
    colonias.unshift(opcionDefault);

    const selectcol = document.getElementById("coloniaSelect");
    selectcol.className = "";
    const inputcol = document.getElementById("coloniaInput");
    inputcol.className = "ocultarDiv";

    let root = ReactDOM.createRoot(document.getElementById("coloniaSelect"));
    root.render(colonias);
  };

  let data = {};
  let datauUpdate = {};
  const sendInfoLaboral = async () => {
    const fechaArray = infoLaboral.fechaIngreso.split("/");
    const fechaFormateada = `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;

    const fechaArray2 = infoLaboral.fechaIngresoAnt.split("/");
    const fechaFormateada2 = `${fechaArray[2]}-${fechaArray[1]}-${fechaArray[0]}`;

    datauUpdate = {
      idUser: cookies.get("id"),
      nombreEmpresa: infoLaboral.nombreEmpresa,
      puesto: infoLaboral.puesto,
      telefono: infoLaboral.telefono.replaceAll("-", ""),
      fechaIngreso: fechaFormateada + "T00:00:00.047Z",
      giro: infoLaboral.giro,
      calle: direccion,
      numExt: exterior,
      numInt: interior,
      cp: cp,
      municipio: municipio,
      colonia: colonia,
      nombreEmpresaAnt: infoLaboral.nombreEmpresaAnt,
      puestoAnt: infoLaboral.puestoAnt,
      telefonoAnt: infoLaboral.telefonoAnt.replaceAll("-", ""),
      fechaIngresoAnt: fechaFormateada2 + "T00:00:00.047Z",
      ocupacion: infoLaboral.ocupacion,
      tipoEmpleo: infoLaboral.tipoEmpleo,
      estado: estado,
      ocupacionAnt: infoLaboral.ocupacionAnt,
      tipoEmpleoAnt: infoLaboral.tipoEmpleoAnt,
      escoAcreditado: false, //JPB 13-11-2023 Es true ya que estamos subiendo en la seccion de Info Coacreditado
    };

    data = {
      idUser: cookies.get("id"),
      nombreEmpresa: infoLaboral.nombreEmpresa,
      puesto: infoLaboral.puesto,
      telefono: infoLaboral.telefono,
      fechaIngreso: fechaFormateada + "T00:00:00.047Z",
      giro: infoLaboral.giro,
      calle: direccion, //tengo duda
      numExt: exterior,
      numInt: interior,
      cp: cp, //tengo duda
      municipio: municipio,
      colonia: colonia, //tengo duda
      nombreEmpresaAnt: infoLaboral.nombreEmpresaAnt,
      puestoAnt: infoLaboral.puestoAnt,
      telefonoAnt: infoLaboral.telefonoAnt,
      fechaIngresoAnt: fechaFormateada + "T00:00:00.047Z",
      idResPrecalifica: idResPrecalifica,
      estado: estado,
      ocupacion: infoLaboral.ocupacion,
      tipoEmpleoAnt: infoLaboral.tipoEmpleoAnt,
      ocupacionAnt: infoLaboral.ocupacionAnt,
      tipoEmpleo: infoLaboral.tipoEmpleo,
      escoAcreditado: false, //JPB 13-11-2023 Es true ya que estamos subiendo en la seccion de Info Coacreditado
    };

    let eleccionIU = await get("getInfoLaboral", cookies.get("id"));

    if (eleccionIU.msg === "El usuario no tiene informacion laboral") {
      let insertar = await post("addInfoLaboral", data);

      if (insertar.statuscode === 200) {
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setTimeout(() => {
          window.location.href = "./";
        }, 1000);
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put("updateInfoLaboral", datauUpdate);

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
          setTimeout(() => {
            window.location.href = "./";
          }, 1000);
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  function miFuncion() {
    sendInfoLaboral();
  }

  const DateInput = (event) => {
    // console.log("entro", event);
    let input = event;

    // Remover las diagonales existentes
    //input = input.replace(/\//g, '');
    input = input.replace(/\D/g, "");

    // Insertar las diagonales en la posición correcta
    if (input.length > 2 && input.length < 5) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    } else if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;

    if (regex.test(input)) {
      // console.log("El formato de fecha es válido.");
      // Realizar acciones adicionales si la fecha es válida
    } else {
      // console.log("El formato de fecha no es válido.");
      // Realizar acciones adicionales si la fecha no es válida
    }
    // console.log("salir", input);
    // console.log("valor", infoLaboral.fechaIngreso);
    setInfoLaboral(() => ({ ...infoLaboral, fechaIngreso: input }));

    setValidarReglaNacimento(ruleNacimiento(input));
    //validarEndeudamiento(input)
    // console.log(input);
    let fechaIngresoNF =
      input.substr(6, 4) + "-" + input.substr(3, 2) + "-" + input.substr(0, 2);
    // console.log(fechaIngresoNF);
  };

  const DateInput2 = (event) => {
    // console.log("entro", event);
    let input = event;

    // Remover las diagonales existentes
    //input = input.replace(/\//g, '');
    input = input.replace(/\D/g, "");

    // Insertar las diagonales en la posición correcta
    if (input.length > 2 && input.length < 5) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    } else if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;

    if (regex.test(input)) {
      // console.log("El formato de fecha es válido.");
      // Realizar acciones adicionales si la fecha es válida
    } else {
      // console.log("El formato de fecha no es válido.");
      // Realizar acciones adicionales si la fecha no es válida
    }
    // console.log("salir", input);
    // console.log("valor", infoLaboral.fechaIngresoAnt);
    setInfoLaboral(() => ({ ...infoLaboral, fechaIngresoAnt: input }));

    setValidarReglaFechaIngresoAnt(ruleNacimiento(input));
    //validarEndeudamiento(input)
    // console.log(input);
    let fechaIngresoAntNF =
      input.substr(6, 4) + "-" + input.substr(3, 2) + "-" + input.substr(0, 2);
    // console.log(fechaIngresoAntNF);
  };

  const DateInput3 = (event) => {
    // console.log("entro", event);
    let input = event;

    // Remover las diagonales existentes
    //input = input.replace(/\//g, '');
    input = input.replace(/\D/g, "");

    // Insertar las diagonales en la posición correcta
    if (input.length > 2 && input.length < 5) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    } else if (input.length > 4) {
      input =
        input.slice(0, 2) + "/" + input.slice(2, 4) + "/" + input.slice(4, 8);
    }

    const regex = /^\d{0,2}(\/\d{0,2}(\/\d{0,4})?)?$/;

    if (regex.test(input)) {
      // console.log("El formato de fecha es válido.");
      // Realizar acciones adicionales si la fecha es válida
    } else {
      // console.log("El formato de fecha no es válido.");
      // Realizar acciones adicionales si la fecha no es válida
    }
    // console.log("salir", input);
    // console.log("valor", infoLaboral.fechaSalida);
    setInfoLaboral(() => ({ ...infoLaboral, fechaSalida: input }));

    setValidarReglaFechaSalida(ruleNacimiento(input));
    //validarEndeudamiento(input)
    // console.log(input);
    let fechaSalidaNF =
      input.substr(6, 4) + "-" + input.substr(3, 2) + "-" + input.substr(0, 2);
    // console.log(fechaSalidaNF);
  };

  async function camposLlenos() {
    if (
      infoLaboral.nombreEmpresa === "" ||
      infoLaboral.puesto === "" ||
      infoLaboral.ocupacion === "" ||
      infoLaboral.tipoEmpleo === "" ||
      infoLaboral.telefono === "" ||
      validarLongitudTelefono == false ||
      validarReglaTelefono == false ||
      validarReglaNacimiento == false ||
      infoLaboral.fechaIngreso === "" ||
      infoLaboral.giro === "" ||
      cp === "" ||
      infoLaboral.nombreEmpresaAnt === "" ||
      colonia === "" ||
      cp === "" ||
      infoLaboral.puestoAnt === "" ||
      infoLaboral.tipoEmpleoAnt === "" ||
      validarReglaTelefonoAnt == false ||
      validarLongitudTelefonoAnt == false ||
      validarReglaFechaIngresoAnt == false ||
      infoLaboral.ocupacionAnt == ""
    ) {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Por favor llena todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      miFuncion();
    }
  }

  return (
    <>
      <div className=" cont_Info">
        <a className="back" href="/informacion">
          {"<"} Regresar a documentación
        </a>

        <h1 className="titt">Información Laboral</h1>
        <p>
          Asegurate de poner la información correcta. <br /> Podrás regresar a
          editarlo.
        </p>

        <form onSubmit={handleSubmit(camposLlenos)}>
          <div className="Resum">
            <div className="header">
              <h3>Empleo actual</h3>
            </div>
            <div className="cont_flex cont-form">
              <div className="col3 campo-form">
                <label>Nombre de la empresa</label>
                <input
                  type="text"
                  id="nombreEmpresa"
                  name="nombreEmpresa"
                  value={infoLaboral.nombreEmpresa}
                  onInput={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      nombreEmpresa: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
                {(infoLaboral.nombreEmpresa === "" ||
                  infoLaboral.nombreEmpresa === " ") && (
                  <small class="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Puesto</label>
                <input
                  type="text"
                  id="puesto"
                  name="puesto"
                  value={infoLaboral.puesto}
                  onInput={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      puesto: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
                {(infoLaboral.puesto === "" || infoLaboral.puesto === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Ocupación</label>
                <input
                  type="text"
                  id="ocupacion"
                  name="ocupacion"
                  value={infoLaboral.ocupacion}
                  onInput={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      ocupacion: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
                {(infoLaboral.ocupacion === "" ||
                  infoLaboral.ocupacion === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de empleo</label>
                <select
                  type="text"
                  id="tipoEmpleo"
                  name="tipoEmpleo"
                  value={infoLaboral.tipoEmpleo}
                  onChange={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      tipoEmpleo: ev.target.value,
                    }));
                  }}
                  required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Empleado</option>
                  <option value="2">Negocio propio</option>
                  <option value="3">Otro</option>
                </select>
                {(infoLaboral.tipoEmpleo === "" ||
                  infoLaboral.tipoEmpleo === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de contrato</label>
                <select
                  type="text"
                  id="tipoContrato"
                  name="tipoContrato"
                  value={infoLaboral.tipoEmpleo}
                  onChange={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      tipoEmpleo: ev.target.value,
                    }));
                  }}
                  required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Definido</option>
                  <option value="2">Temporal</option>
                </select>
                {(infoLaboral.tipoContrato === "" ||
                  infoLaboral.tipoContrato === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Teléfono</label>
                <input
                  type="tel"
                  size="14"
                  maxLength={10}
                  name="telefono"
                  id="telefono"
                  placeholder="Teléfono a 10 dígitos"
                  autoComplete="off"
                  value={infoLaboral.telefono}
                  onInput={(ev) => {
                    setValidarReglaTelefono(ruleTelefono(ev.target.value));
                    let tempTelefono = ev.target.value;
                    if (tempTelefono.length < 10) {
                      setInfoLaboral(() => ({
                        ...infoLaboral,
                        telefono: tempTelefono,
                      }));
                      setValidarLongitudTelefono(false);
                    } else {
                      setInfoLaboral(() => ({
                        ...infoLaboral,
                        telefono: tempTelefono,
                      }));
                      setValidarLongitudTelefono(true);
                    }
                  }}
                ></input>
                {(infoLaboral.telefono === "" ||
                  infoLaboral.telefono === null) && (
                  <small className="span">Este campo es requerido</small>
                )}
                {validarReglaTelefono === false && (
                  <small className="span"> Ingresar solo números</small>
                )}
                {errors.telefono?.type === "required" && (
                  <span className="span">Este campo es requeridos</span>
                )}
                {errors.telefono && (
                  <span className="span">
                    {" "}
                    <br />
                    Ingresar solo números
                  </span>
                )}
                {validarLongitudTelefono === false && (
                  <span className="span">Ingresar 10 digitos</span>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Fecha de ingreso</label>
                <input
                  type="text"
                  value={infoLaboral.fechaIngreso}
                  onInput={(ev) => {
                    DateInput(ev.target.value);
                  }}
                />
                {validarReglaNacimiento === false && (
                  <small className="span">
                    {" "}
                    Ingresar en el siguiente formato dd/mm/aaaa <br />
                  </small>
                )}
                {(infoLaboral.fechaIngreso === "" ||
                  infoLaboral.fechaIngreso === null) && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Giro de la empresa</label>
                <select
                  type="text"
                  id="nombreEmpresa"
                  name="nombreEmpresa"
                  value={infoLaboral.giro}
                  onChange={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      giro: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Servicios</option>
                  <option value="2">Construcción</option>
                  <option value="3">Industrial</option>
                  <option value="4">Agricultura / Pesca</option>
                  <option value="5">Otro</option>
                </select>
                {(infoLaboral.giro === "" || infoLaboral.giro === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
            </div>
          </div>
          <div className="Resum">
            <div className="header">
              <h3>Dirección del empleo</h3>
            </div>
            <div className="cont_flex cont-form">
              <div className="col3 campo-form">
                <label htmlFor="cp">Código postal</label>
                <input
                  type="tel"
                  name="cp"
                  id="cp"
                  size={5}
                  minLength={5}
                  maxLength="5"
                  autoComplete="off"
                  value={cp}
                  onInput={(ev) => {
                    if (isNaN(ev.target.value) === true) {
                      setCp("");
                    } else {
                      if (ev.target.value.length === 5) {
                        obtenerDataCp(ev.target.value);
                      }
                      setCp(ev.target.value);
                    }
                  }}
                />
                <small>
                  Al ingresar su código postal correcto, en automático se
                  registrará su estado y municipio.
                </small>
                {cp === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Direccion</label>
                <input
                  type="text"
                  autoComplete="off"
                  value={direccion}
                  onInput={(ev) => {
                    setDireccion(ev.target.value);
                  }}
                ></input>
                {direccion === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Número Ext</label>
                <input
                  type="tel"
                  name="numExt"
                  id="numExt"
                  autoComplete="off"
                  value={exterior}
                  onInput={(ev) => {
                    setExterior(ev.target.value);
                  }}
                />

                {exterior === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Número Int</label>
                <input
                  type="tel"
                  name="numInt"
                  id="numInt"
                  autoComplete="off"
                  value={interior}
                  onInput={(ev) => {
                    setInterior(ev.target.value);
                  }}
                />

                {interior === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>

              <div className="col3 campo-form estado">
                <label htmlFor="estado">Estado</label>
                <input
                  name="estado"
                  id="estado"
                  disabled
                  value={estado !== "" ? estado : estadoAux}
                ></input>
                {estado === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>
              <div className="col3 campo-form colonia">
                <label htmlFor="ciudad">Delegación / Municipio</label>
                <input
                  name="ciudad"
                  id="ciudad"
                  disabled
                  value={municipio}
                ></input>
                {municipio === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>
              <div className="col3 campo-form colonia">
                <label htmlFor="coloniaSelect">Colonia</label>
                <select
                  type="text"
                  className="ocultarDiv"
                  id="coloniaSelect"
                  name="coloniaSelect"
                  onChange={(ev) => {
                    setColonia(ev.target.value);
                  }}
                />
                {(colonia === "" || colonia === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
                <input
                  type="text"
                  id="coloniaInput"
                  name="coloniaInput"
                  disabled
                  value={colonia}
                />
                {colonia === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Empleo anterior</h3>
            </div>
            <div className="cont_flex cont-form">
              <div className="col3 campo-form">
                <label>Nombre de la empresa</label>
                <input
                  type="text"
                  id="nombreEmpresaAnt"
                  name="nombreEmpresaAnt"
                  value={infoLaboral.nombreEmpresaAnt}
                  onInput={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      nombreEmpresaAnt: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
                {(infoLaboral.nombreEmpresaAnt === "" ||
                  infoLaboral.nombreEmpresaAnt === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Puesto</label>
                <input
                  type="text"
                  id="puestoAnt"
                  name="puestoAnt"
                  value={infoLaboral.puestoAnt}
                  onInput={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      puestoAnt: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
                {(infoLaboral.puestoAnt === "" ||
                  infoLaboral.puestoAnt === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Ocupación</label>
                <input
                  type="text"
                  id="ocupacionAnt"
                  name="ocupacionAnt"
                  value={infoLaboral.ocupacionAnt}
                  onInput={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      ocupacionAnt: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
                {(infoLaboral.ocupacionAnt === "" ||
                  infoLaboral.ocupacionAnt === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Tipo de empleo</label>
                <select
                  type="text"
                  id="tipoEmpleoAnt"
                  name="tipoEmpleoAnt"
                  value={infoLaboral.tipoEmpleoAnt}
                  onChange={(ev) => {
                    setInfoLaboral(() => ({
                      ...infoLaboral,
                      tipoEmpleoAnt: ev.target.value,
                    }));
                  }}
                  required
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Empleado</option>
                  <option value="2">Negocio propio</option>
                  <option value="3">Otro</option>
                </select>
                {(infoLaboral.tipoEmpleoAnt === "" ||
                  infoLaboral.tipoEmpleoAnt === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Teléfono</label>
                <input
                  type="tel"
                  size="14"
                  maxLength={10}
                  name="telefonoAnt"
                  id="telefonoAnt"
                  placeholder="Teléfono a 10 dígitos"
                  autoComplete="off"
                  value={infoLaboral.telefonoAnt}
                  onInput={(ev) => {
                    setValidarReglaTelefonoAnt(ruleTelefono(ev.target.value));
                    let tempTelefono = ev.target.value;
                    if (tempTelefono.length < 10) {
                      setInfoLaboral(() => ({
                        ...infoLaboral,
                        telefonoAnt: tempTelefono,
                      }));
                      setValidarLongitudTelefonoAnt(false);
                    } else {
                      setInfoLaboral(() => ({
                        ...infoLaboral,
                        telefonoAnt: tempTelefono,
                      }));
                      setValidarLongitudTelefonoAnt(true);
                    }
                  }}
                ></input>
                {validarReglaTelefonoAnt === false && (
                  <small className="span"> Ingresar solo números</small>
                )}
                {errors.telefonoAnt && (
                  <span className="span">
                    {" "}
                    <br />
                    Ingresar solo números
                  </span>
                )}
                {validarLongitudTelefonoAnt === false && (
                  <span className="span">Ingresar 10 digitos</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Fecha de ingreso</label>
                <input
                  type="text"
                  value={infoLaboral.fechaIngresoAnt}
                  onInput={(ev) => {
                    DateInput2(ev.target.value);
                  }}
                />
                {validarReglaFechaIngresoAnt === false && (
                  <small className="span">
                    {" "}
                    Ingresar en el siguiente formato dd/mm/aaaa <br />
                  </small>
                )}
                {(infoLaboral.fechaIngresoAnt === "" ||
                  infoLaboral.fechaIngresoAnt === null) && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Fecha de salida</label>
                <input
                  type="text"
                  value={infoLaboral.fechaSalida}
                  onInput={(ev) => {
                    DateInput3(ev.target.value);
                  }}
                />
                {validarReglaFechaSalida === false && (
                  <small className="span">
                    {" "}
                    Ingresar en el siguiente formato dd/mm/aaaa <br />
                  </small>
                )}
                {(infoLaboral.fechaSalida === "" ||
                  infoLaboral.fechaSalida === null) && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
            </div>
          </div>

          <div className="cont_flex cont-form">
            <div className="dosCuttonFormularios">
              <a className="btn btn_bco  campo-form " href="/informacion">
                {" "}
                Regresar
              </a>
              <button type="submit" class="btn btn_morado campo-form">
                Guardar
              </button>
            </div>
          </div>
        </form>
        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
        {/* <Link to="/informacion/inf-personal" className="btn btn_morado">Continuar con información personal</Link> */}
      </div>
    </>
  );
}

export function InfoPersonal() {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const cookies = new Cookies();
  const [isLoading, setIsLoading] = useState(true);
  const [idReferenciaProducto, setIdReferenciaProducto] = useState();
  const [idReferencia, setidReferencia] = useState();
  const [infoPersonal, setInfoPersonal] = useState({ sueldo: 0 });
  const [infoReferenciasProductos, setInfoReferenciasProducto] = useState({});
  const [infoReferenciasProductos2, setInfoReferenciasProducto2] = useState({});
  const [infoReferencias, setInfoReferencias] = useState({});
  const [infoReferencias2, setInfoReferencias2] = useState({});
  const [infoBeneficiarios, setInfoBeneficiarios] = useState({});
  const [idResPrecalifica, setIdResPrecalifica] = useState({});
  const [validarReglaCurp, setValidarReglaCurp] = useState({});
  const [validarReglaNumeroSeguridad, setValidarReglaNumeroSeguridad] =
    useState({});

  const infoCalifica = async () => {
    let idCuestionario = await get("getInfoCalifica", cookies.get("id"));
    setIdResPrecalifica(
      idCuestionario.infoCalifica[0].cuestionarioColeccion[0].resPrecalificas
        .idResPrecalifica
    );
  };

  const info = async () => {
    let data = await get("infoUser", cookies.get("correo"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoPersonal(() => ({
        ...infoPersonal,
        ...data.user.infoPersonals[0],
      }));
    }
    //console.log(data.user.infoPersonals[0]);
  };

  const info2 = async () => {
    let data = await get("getInfoReferenciasProductos", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferenciasProducto(() => ({
        ...infoReferenciasProductos,
        ...idReferenciaProducto,
        ...data.infoReferencias[0],
      }));
      setInfoReferenciasProducto2(() => ({
        ...infoReferenciasProductos2,
        ...idReferenciaProducto,
        ...data.infoReferencias[1],
      }));
      // Add more fields as needed
    }
    //console.log(data.infoReferencias[0]);
  };

  // Familiar

  const info3 = async () => {
    let data = await get("getReferencia", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoReferencias(() => ({
        ...infoReferencias,
        ...idReferencia,
        ...data.infoReferencias[0],
      }));
      setInfoReferencias2(() => ({
        ...infoReferencias2,
        ...idReferencia,
        ...data.infoReferencias[1],
      }));
    }
    //console.log(data.infoReferencias[0]);
  };

  const info4 = async () => {
    let data = await get("getInfoBeneficiarios", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoBeneficiarios(() => ({
        ...infoBeneficiarios,
        ...data.infoReferencias[0],
      }));
    }
    //console.log(data.infoReferencias[0]);
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    infoCalifica();
    info();
    info2();
    info3();
    info4();
  }, []);

  function miFuncion() {
    if (infoPersonal.curp !== "") {
      addInfoPersonal();
      addReferenciasProductos();
      addReferencia();
      addInfoBeneficiarios();
    } else {
      Swal.fire({
        position: "top-end",
        icon: "warning",
        title: "Por favor llena todos los campos",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }

  let dataInfoPersonal = {};
  let dataInfoReferenciasProductos = {};
  let dataInfoReferencia = {};

  let dataInfoBeneficiariosInsert = {};
  let dataInfoBeneficiariosUpdate = {};

  const addInfoPersonal = async () => {
    dataInfoPersonal = {
      /*Parte 1*/
      idUser: cookies.get("id"),
      curp: infoPersonal.curp,
      sexo: infoPersonal.sexo,
      numeroSeguroSocial: infoPersonal.numeroSeguroSocial,
      estadoCivil: infoPersonal.estadoCivil,
      gradoMaxEstudios: infoPersonal.gradoMaxEstudios,
      numDepEco: infoPersonal.numDepEco,
      tipoVivienda: infoPersonal.tipoVivienda,
      aniosVivienda: infoPersonal.aniosVivienda,
      idResPrecalifica: idResPrecalifica,

      /*Parte 2*/
      sueldo: infoPersonal.sueldo,
      otroIngreso: infoPersonal.otroIngreso,
      autoValor: infoPersonal.autoValor,
      viviendoActualValor: infoPersonal.viviendoActualValor,
      escoAcreditado: false,
    };
    try {
      const existingInfo = await get("getInfoPersonal", cookies.get("id"));

      if (existingInfo.msg === "El usuario no tiene informacion personal") {
        // Usuario sin información, agregar nueva entrada
        const insertar = await post("addInfoPersonal", dataInfoPersonal);

        if (insertar.statuscode === 200) {
          // console.log("addInfo personal correcto");
        } else {
          // console.log("addInfo personal fallo");
        }
      } else {
        // Usuario con información, actualizar la existente
        const actualizar = await put("updateinfoPersonal", dataInfoPersonal);

        if (actualizar.statuscode === 200) {
          // console.log("updateInfo personal correcto");
        } else {
          // console.log("updateInfo personal fallo");
        }
      }
    } catch (error) {
      // console.error("Error durante la operación:", error);
    }
  };

  /** -------------------------------------------
                Referencia Productos              
        --------------------------------------- **/

  const addReferenciasProductos = async () => {
    dataInfoReferenciasProductos = {
      idReferenciaProducto: infoReferenciasProductos.idReferenciaProducto,
      idResPrecalifica: idResPrecalifica,
      idUser: cookies.get("id"),
      tipoCuenta: infoReferenciasProductos.tipoCuenta,
      numeroCuenta: infoReferenciasProductos.numeroCuenta,
      institucionCredito: infoReferenciasProductos.institucionCredito,
      intitutoCapacitacion: infoReferenciasProductos.intitutoCapacitacion,
      tipoCuentaCapacitacion: infoReferenciasProductos.tipoCuentaCapacitacion,

      idReferenciaProducto2: infoReferenciasProductos2.idReferenciaProducto,
      idResPrecalifica2: idResPrecalifica, // Asigna un valor único
      idUser2: cookies.get("id"),
      tipoCuenta2: infoReferenciasProductos2.tipoCuenta,
      numeroCuenta2: infoReferenciasProductos2.numeroCuenta,
      institucionCredito2: infoReferenciasProductos2.institucionCredito,
      intitutoCapacitacion2: infoReferenciasProductos2.intitutoCapacitacion,
      tipoCuentaCapacitacion2: infoReferenciasProductos2.tipoCuentaCapacitacion,
      escoAcreditado: false,
    };

    let eleccionIU1 = await get(
      "getInfoReferenciasProductos",
      cookies.get("id")
    );

    if (eleccionIU1.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post(
        "addInfoReferenciasProductos",
        dataInfoReferenciasProductos
      );

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        // console.log(
        //   `addInfoReferenciasProductos correcto. IdResPrecalifica: ${idResPrecalifica}`
        // );
      } else {
        // console.log("addInfoReferenciasProductos fallo");
      }
    } else {
      if (eleccionIU1.statuscode === 200) {
        let actualizar = await put(
          "updateInfoReferenciasProductos",
          dataInfoReferenciasProductos
        );

        if (actualizar.statuscode === 200) {
          // console.log("updateInfoReferenciasProductos correcto");
        } else {
          // console.log("updateInfoReferenciasProductos fallo");
        }
      }
    }
  };

  const addReferencia = async () => {
    dataInfoReferencia = {
      idReferencia: infoReferencias.idReferencia,
      idUser: cookies.get("id"),
      nombre: infoReferencias.nombre,
      aPaterno: infoReferencias.aPaterno,
      aMaterno: infoReferencias.aMaterno,
      relacion: infoReferencias.relacion,
      telefono: infoReferencias.telefono,
      email: infoReferencias.email,
      idResPrecalifica: idResPrecalifica,

      idReferencia2: infoReferencias2.idReferencia,
      idUser2: cookies.get("id"),
      nombre2: infoReferencias2.nombre,
      aPaterno2: infoReferencias2.aPaterno,
      aMaterno2: infoReferencias2.aMaterno,
      relacion2: infoReferencias2.relacion,
      telefono2: infoReferencias2.telefono,
      email2: infoReferencias2.email,
      idResPrecalifica2: idResPrecalifica,
      escoAcreditado: false,
    };

    let eleccionIU = await get("getReferencia", cookies.get("id"));
    if (eleccionIU.msg === "El usuario no tiene informacion de referencias") {
      let insertar = await post("addReferencia", dataInfoReferencia);

      if (insertar.statuscode === 200) {
        const idResPrecalifica = insertar.idResPrecalifica;
        // console.log(
        //   `addInfoReferenciasProductos correcto. IdResPrecalifica: ${idResPrecalifica}`
        // );
      } else {
        // console.log("addReferencia perosnal fallo");
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put("updateReferencia", dataInfoReferencia);
        if (actualizar.statuscode === 200) {
          // console.log("updateReferencia correcto");
        } else {
          // console.log("updateReferencia fallo");
        }
      }
    }
  };

  const addInfoBeneficiarios = async () => {
    let eleccionIU = await get("getInfoBeneficiarios", cookies.get("id"));

    if (eleccionIU.statuscode === 404) {
      //Sin datos en Info beneficiario
      dataInfoBeneficiariosInsert = {
        //idBeneficiario: eleccionIU.infoReferencias[0].idBeneficiario,
        idResPrecalifica: idResPrecalifica,
        nombre: infoBeneficiarios.nombre,
        idUser: cookies.get("id"),
        aPaterno: infoBeneficiarios.aPaterno,
        aMaterno: infoBeneficiarios.aMaterno,
        parentesco: infoBeneficiarios.parentesco,
        telefono: infoBeneficiarios.telefono,
        escoAcreditado: false,
      };
      let insertar = await post(
        "addInfoBeneficiarios",
        dataInfoBeneficiariosInsert
      );

      if (insertar.statuscode === 200) {
        Swal.fire({
          title: "Datos insertados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });
        setTimeout(() => {
          window.location.href = "/informacion-general";
        }, 1000);
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        dataInfoBeneficiariosUpdate = {
          idBeneficiario: eleccionIU.infoReferencias[0].idBeneficiario, //-
          idResPrecalifica: idResPrecalifica,
          nombre: infoBeneficiarios.nombre,
          idUser: cookies.get("id"),
          aPaterno: infoBeneficiarios.aPaterno,
          aMaterno: infoBeneficiarios.aMaterno,
          parentesco: infoBeneficiarios.parentesco,
          telefono: infoBeneficiarios.telefono,
          escoAcreditado: false,
        };
        let actualizar = await put(
          "updateInfoBeneficiarios",
          dataInfoBeneficiariosUpdate
        );

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
          setTimeout(() => {
            window.location.href = "/informacion-general";
          }, 1000);
        } else {
          Swal.fire({
            title: "Datos actualizados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  //Formatear números a pesos
  const GetNF = (value) => {
    if (value != undefined) {
      return Number(value).toLocaleString("es-MX");
    } else {
      return "0";
    }
  };

  const handleInputChange = (event, fieldName) => {
    const inputElement = event.target;
    const cursorPosition = inputElement.selectionStart;

    const numericValue = parseFloat(
      event.target.value.replace(/[^0-9.-]/g, "")
    );
    setInfoPersonal((prevInfo) => ({
      ...prevInfo,
      [fieldName]: numericValue,
    }));

    setTimeout(() => {
      inputElement.setSelectionRange(cursorPosition, cursorPosition);
    }, 0);
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <a className="back" href="/informacion-general">
          {"<"} Regresar a documentación
        </a>
        <h1 className="titt">Información Personal</h1>
        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>

        <form onSubmit={handleSubmit(miFuncion)}>
          <div className="Resum">
            <div className="header">
              <h3>Personales</h3>
            </div>

            <hr />

            <div className="cont_flex cont-form formInfoGeneral">
              {/* CURP */}
              <div className="col3 campo-form ">
                <label>CURP *</label>
                <input
                  type="text"
                  id="curp"
                  style={{ textTransform: "uppercase" }}
                  name="curp"
                  value={infoPersonal.curp}
                  maxLength={18}
                  onInput={(ev) => {
                    setValidarReglaCurp(ruleCURP(ev.target.value));
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      curp: ev.target.value,
                    }));
                  }}
                />
                {/* Muestra el mensaje de error si existe */}
                {(infoPersonal.curp === "" ||
                  infoPersonal.curp === " " ||
                  infoPersonal.curp === undefined) && (
                  <small className="span">Este campo es requerido</small>
                )}
                {validarReglaCurp === false && (
                  <small className="span">No cumple con el formato</small>
                )}
              </div>

              {/* GENERO */}
              <div className="col3 campo-form estado marginEspacio label">
                <label>Género *</label>
                <select
                  name="sexo"
                  id="sexo"
                  value={infoPersonal.sexo}
                  onChange={(ev) => {
                    const isMale = ev.target.value === "true"; // Convertir a booleano
                    setInfoPersonal((prevInfo) => ({
                      ...prevInfo,
                      sexo: isMale,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="true">Masculino</option>
                  <option value="false">Femenino</option>
                </select>
                {/* Muestra el mensaje de error si existe */}
                {(infoPersonal.sexo === "" ||
                  infoPersonal.sexo === " " ||
                  infoPersonal.sexo === undefined) && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Número de seguridad social *</label>
                <input
                  type="text"
                  id="numeroSeguroSocial"
                  name="numeroSeguroSocial"
                  maxLength={11}
                  pattern="[0-9]*"
                  value={infoPersonal.numeroSeguroSocial}
                  onInput={(ev) => {
                    setValidarReglaNumeroSeguridad(
                      ruleNumeroSeguridad(ev.target.value)
                    );
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      numeroSeguroSocial: ev.target.value,
                    }));
                  }}
                ></input>
                <small>
                  <a
                    href="https://serviciosdigitales.imss.gob.mx/gestionAsegurados-web-externo/asignacionNSS;JSESSIONIDASEGEXTERNO=QVtxJ5QWWr9Y0NGI725WkKIErOaz4Rr8OtbSIHTlf91Hl7neHlaW!806955352"
                    target="_blank" // Agregamos este atributo para abrir en una nueva pestaña
                    rel="noopener noreferrer" // Añadimos estos atributos de seguridad
                  >
                    Si no lo recuerdas, dar click aquí.
                  </a>
                </small>
                {(infoPersonal.numeroSeguroSocial === "" ||
                  infoPersonal.numeroSeguroSocial === " " ||
                  infoPersonal.numeroSeguroSocial === undefined ||
                  infoPersonal.numeroSeguroSocial === null) && (
                  <small className="span">Este campo es requerido</small>
                )}
                {validarReglaNumeroSeguridad === false && (
                  <small className="span">No cumple con el formato</small>
                )}
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Estado civil *</label>
                <select
                  name="estadoCivil>"
                  id="estadoCivil"
                  value={infoPersonal.estadoCivil}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      estadoCivil: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Soltero</option>
                  <option value="2">Unión libre</option>
                  <option value="3">Sociedad de Convivencia</option>
                  <option value="4">Casado (separación de Bienes)</option>
                  <option value="5">Casado (Sociedad Coyugal)</option>
                  <option value="6">Viudo</option>
                  <option value="7">Divorciado</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Grado máximo de estudios *</label>
                <select
                  name="gradoMaxEstudios>"
                  id="gradoMaxEstudios"
                  value={infoPersonal.gradoMaxEstudios}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      gradoMaxEstudios: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Primaria</option>
                  <option value="2">Secundaria</option>
                  <option value="3">Preparatoria</option>
                  <option value="4">Técnico</option>
                  <option value="5">Licenciatura</option>
                  <option value="6">Postgrado</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>Número de dependientes económicos *</label>
                <select
                  name="numDepEco>"
                  id="numDepEco"
                  value={infoPersonal.numDepEco}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      numDepEco: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6 o más</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿Que tipo de vivienda habita? *</label>
                <select
                  name="tipoVivienda>"
                  id="tipoVivienda"
                  value={infoPersonal.tipoVivienda}
                  onChange={(ev) => {
                    setInfoPersonal(() => ({
                      ...infoPersonal,
                      tipoVivienda: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  <option value="1">Propia</option>
                  <option value="2">Hipotecada</option>
                  <option value="3">Rentada</option>
                  <option value="4">De un familiar</option>
                </select>
              </div>

              <div className="col3 campo-form estado marginEspacio">
                <label>¿Años que a vivido en dicha vivienda? *</label>
                <select
                  name="aniosVivienda"
                  id="aniosVivienda"
                  value={infoPersonal.aniosVivienda}
                  onChange={(ev) => {
                    setInfoPersonal((prevInfo) => ({
                      ...prevInfo,
                      aniosVivienda: ev.target.value,
                    }));
                  }}
                >
                  <option value="">Seleccione una opción.....</option>
                  {/* Array para opciones */}
                  {Array.from({ length: 50 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>
                      {index + 1}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias de Productos</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              {/* ------------------------------------
                        Referencia de crédito 1
             -------------------------------------------*/}

              <form>
                <div className="header">
                  <h3>Referencia de crédito 1</h3>
                  <input
                    id="idReferenciaProducto"
                    value={infoReferenciasProductos.idReferenciaProducto}
                    onChange={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        idReferenciaProducto: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>

                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo cuenta *</label>
                  <select
                    name="tipoCuenta"
                    id="tipoCuenta"
                    value={infoReferenciasProductos.tipoCuenta}
                    onChange={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        tipoCuenta: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Hipoteca</option>
                    <option value="2">Crédito Auto</option>
                    <option value="3">Crédito Personal</option>
                    <option value="4">Tarjeta de Crédito</option>
                  </select>
                </div>

                <div className="col3 campo-form">
                  <label>Numero de Tarjeta o Crédito *</label>
                  <input
                    type="number"
                    id="numeroCuenta"
                    name="numeroCuenta"
                    value={infoReferenciasProductos.numeroCuenta}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        numeroCuenta: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) del Crédito *</label>
                  <input
                    type="text"
                    id="institucionCredito"
                    name="institucionCredito"
                    value={infoReferenciasProductos.institucionCredito}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        institucionCredito: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="header">
                  <h3>Referencias de producto de débito </h3>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) de la cuenta *</label>
                  <input
                    type="text"
                    id="intitutoCapacitacion"
                    name="intitutoCapacitacion"
                    value={infoReferenciasProductos.intitutoCapacitacion}
                    onInput={(ev) => {
                      setInfoReferenciasProducto(() => ({
                        ...infoReferenciasProductos,
                        intitutoCapacitacion: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {
                  <div className="col3 campo-form estado marginEspacio">
                    <label>Tipo de cuenta de débito *</label>
                    <select
                      name="tipoCuentaCapacitacion>"
                      id="tipoCuentaCapacitacion"
                      value={infoReferenciasProductos.tipoCuentaCapacitacion}
                      onChange={(ev) => {
                        setInfoReferenciasProducto(() => ({
                          ...infoReferenciasProductos,
                          tipoCuentaCapacitacion: ev.target.value,
                        }));
                      }}
                      required
                    >
                      <option value="">Seleccione una opción.....</option>
                      <option value="1">Inversiones</option>
                      <option value="2">Depósitos</option>
                    </select>
                  </div>
                }

                {/* ------------------------------------
                        Referencia de crédito 2
             -------------------------------------------*/}
                <div className="header">
                  <h3>Referencia de crédito 2</h3>
                  <input
                    id="idReferenciaProducto2"
                    value={infoReferenciasProductos2.idReferenciaProducto}
                    onChange={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        idReferenciaProducto: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>

                <div className="col3 campo-form estado marginEspacio">
                  <label>Tipo cuenta</label>
                  <select
                    name="tipoCuenta2>"
                    id="tipoCuenta2"
                    value={infoReferenciasProductos2.tipoCuenta}
                    onChange={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        tipoCuenta: ev.target.value,
                      }));
                    }}
                    // required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Hipoteca</option>
                    <option value="2">Crédito Auto</option>
                    <option value="3">Crédito Personal</option>
                    <option value="4">Tarjeta de Crédito</option>
                  </select>
                </div>

                <div className="col3 campo-form">
                  <label>Numero de Tarjeta o Crédito</label>
                  <input
                    type="number"
                    id="numeroCuenta2"
                    name="numeroCuenta2"
                    value={infoReferenciasProductos2.numeroCuenta}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        numeroCuenta: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) del Crédito</label>
                  <input
                    type="text"
                    id="institucionCredito2"
                    name="institucionCredito2"
                    value={infoReferenciasProductos2.institucionCredito}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        institucionCredito: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                <div className="header">
                  <h3>Referencias de producto de débito </h3>
                </div>

                <div className="col3 campo-form">
                  <label>Institución (Banco) de la cuenta</label>
                  <input
                    type="text"
                    id="intitutoCapacitacion2"
                    name="intitutoCapacitacion2"
                    value={infoReferenciasProductos2.intitutoCapacitacion}
                    onInput={(ev) => {
                      setInfoReferenciasProducto2(() => ({
                        ...infoReferenciasProductos2,
                        intitutoCapacitacion: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {
                  <div className="col3 campo-form estado marginEspacio">
                    <label>Tipo de cuenta de débito</label>
                    <select
                      name="tipoCuentaCapacitacion2>"
                      id="tipoCuentaCapacitacion2"
                      value={infoReferenciasProductos2.tipoCuentaCapacitacion}
                      onChange={(ev) => {
                        setInfoReferenciasProducto2(() => ({
                          ...infoReferenciasProductos2,
                          tipoCuentaCapacitacion: ev.target.value,
                        }));
                      }}
                      // required
                    >
                      <option value="">Seleccione una opción.....</option>
                      <option value="1">Inversiones</option>
                      <option value="2">Depósitos</option>
                    </select>
                  </div>
                }
              </form>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Referencias</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              {/* ------------------------------------
                        Referencia Familiar
             -------------------------------------------*/}

              <form>
                <div className="header">
                  <h3>Referencia Familiar</h3>
                  <input
                    id="idReferencia"
                    name="idReferencia"
                    value={infoReferencias.idReferencia}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        idReferencia: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre"
                    name="nombre"
                    value={infoReferencias.nombre}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno"
                    name="aPaterno"
                    value={infoReferencias.aPaterno}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    id="aMaterno"
                    name="aMaterno"
                    value={infoReferencias.aMaterno}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="relacion>"
                    id="relacion"
                    value={infoReferencias.relacion}
                    onChange={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        relacion: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form" id="delegacion">
                  <label>Tel&eacute;fono *</label>
                  <input
                    type="number"
                    id="telefono"
                    name="telefono"
                    value={infoReferencias.telefono}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        telefono: ev.target.value,
                      }));
                    }}
                    required
                  ></input>
                </div>
                <div className="col3 campo-form" id="colonia">
                  <label>E-mail (opcional)</label>
                  <input
                    type="text"
                    id="email"
                    name="email"
                    value={infoReferencias.email}
                    onInput={(ev) => {
                      setInfoReferencias(() => ({
                        ...infoReferencias,
                        email: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>

                {/* ------------------------------------
                        Referencia No Familiar
             -------------------------------------------*/}

                <div className="header">
                  <h3>Referencia No Familiar </h3>
                  <input
                    id="idReferencia2"
                    name="idReferencia2"
                    value={infoReferencias2.idReferencia}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        idReferencia: ev.target.value,
                      }));
                    }}
                    hidden
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre2"
                    name="nombre2"
                    value={infoReferencias2.nombre}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno2"
                    name="aPaterno2"
                    value={infoReferencias2.aPaterno}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Materno *</label>
                  <input
                    type="text"
                    id="aMaterno2"
                    name="aMaterno2"
                    value={infoReferencias2.aMaterno}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="relacion2>"
                    id="relacion2"
                    value={infoReferencias2.relacion}
                    onChange={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        relacion: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form" id="delegacion">
                  <label>Teléfono *</label>
                  <input
                    type="number"
                    id="telefono2"
                    name="telefono2"
                    value={infoReferencias2.telefono}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        telefono: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form" id="colonia">
                  <label>E-mail (opcional)</label>
                  <input
                    type="email"
                    id="email2"
                    name="email2"
                    value={infoReferencias2.email}
                    onInput={(ev) => {
                      setInfoReferencias2(() => ({
                        ...infoReferencias2,
                        email: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
              </form>
            </div>
          </div>

          <div className="Resum">
            <div className="header">
              <h3>Ingresos y patrimonio</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <form>
                <div className="header">
                  <h3>Ingresos</h3>
                </div>
                <div className="col3 campo-form">
                  <label>Ingresos Mensuales Brutos *</label>
                  <input
                    type="text"
                    id="sueldo"
                    name="sueldo"
                    value={"$" + GetNF(infoPersonal.sueldo)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        sueldo: numericValue,
                      }));
                    }}
                  />
                </div>

                <div className="col3 campo-form">
                  <label>Otros ingresos *</label>
                  <input
                    type="text"
                    id="otroIngreso"
                    name="otroIngreso"
                    value={"$" + GetNF(infoPersonal.otroIngreso)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        otroIngreso: numericValue,
                      }));
                    }}
                  />
                </div>
                <div className="col3 campo-form">
                  <label>Valor de Automoviles $$ *</label>
                  <input
                    type="text"
                    id="autoValor"
                    name="autoValor"
                    value={"$" + GetNF(infoPersonal.autoValor)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        autoValor: numericValue,
                      }));
                    }}
                  />
                </div>

                <div className="col3 campo-form">
                  <label>Valor de la vivienda Actual $$ *</label>
                  <input
                    type="text"
                    id="viviendoActualValor"
                    name="viviendoActualValor"
                    value={"$" + GetNF(infoPersonal.viviendoActualValor)}
                    onInput={(ev) => {
                      // Elimina cualquier símbolo no numérico antes de actualizar el estado
                      const numericValue = ev.target.value.replace(
                        /[^0-9.-]/g,
                        ""
                      );
                      setInfoPersonal(() => ({
                        ...infoPersonal,
                        viviendoActualValor: numericValue,
                      }));
                    }}
                  />
                </div>
              </form>
            </div>
          </div>

          {/* Beneficiario */}

          <div className="Resum">
            <div className="header">
              <h3>Beneficiario</h3>
            </div>
            <hr />
            <div className="cont_flex cont-form">
              <form>
                <div className="col3 campo-form">
                  <label>Nombre *</label>
                  <input
                    type="text"
                    id="nombre_bef"
                    name="nombre_bef"
                    value={infoBeneficiarios.nombre}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        nombre: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido Paterno *</label>
                  <input
                    type="text"
                    id="aPaterno_bef"
                    name="aPaterno_bef"
                    value={infoBeneficiarios.aPaterno}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        aPaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form">
                  <label>Apellido materno *</label>
                  <input
                    type="text"
                    id="aMaterno_bef"
                    name="aMaterno_bef"
                    value={infoBeneficiarios.aMaterno}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        aMaterno: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
                <div className="col3 campo-form estado marginEspacio">
                  <label>Parentesco *</label>
                  <select
                    name="parentesco_bef>"
                    id="parentesco_bef"
                    value={infoBeneficiarios.parentesco}
                    onChange={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        parentesco: ev.target.value,
                      }));
                    }}
                    required
                  >
                    <option value="">Seleccione una opción.....</option>
                    <option value="1">Padre</option>
                    <option value="2">Madre</option>
                    <option value="3">Hijo</option>
                    <option value="4">Pareja</option>
                    <option value="5">Otro</option>
                  </select>
                </div>
                <div className="col3 campo-form">
                  <label>Teléfono *</label>
                  <input
                    type="number"
                    id="telefono_bef"
                    name="telefono_bef"
                    value={infoBeneficiarios.telefono}
                    onInput={(ev) => {
                      setInfoBeneficiarios(() => ({
                        ...infoBeneficiarios,
                        telefono: ev.target.value,
                      }));
                    }}
                  ></input>
                </div>
              </form>
            </div>
          </div>

          <div className="cont_flex cont-form">
            <div className="dosCuttonFormularios">
              <a
                className="btn btn_bco col3 campo-form "
                href="/informacion-general"
              >
                {" "}
                Regresar
              </a>
              {/*<a class="btn btn_morado col3 campo-form" onClick={() => {miFuncion()}} >Guardar</a>*/}
              <button className="btn btn_morado col3 campo-form" type="submit">
                Guardar
              </button>
            </div>
          </div>

          <div className="header">
            <h3>
              Si realizaste algun cambio, recuerda <br />{" "}
              <span className="morado"> guardar </span> antes de salir.
            </h3>
          </div>
        </form>
      </div>
    </>
  );
}

export function InfoMedica() {
  const cookies = new Cookies();
  const [infoMedica, setInfoMedica] = useState({});
  const [infoEstupefaciente, setInfoEstupefaciente] = useState({});
  const [deportesAr, setDeportesAr] = useState({});
  const [consumoAlcohol, setConsumoAlcohol] = useState("No"); // Valor inicial
  const [consumoDrogas, setConsumoDrogas] = useState("No"); // Valor inicial
  const [consumoTabaco, setConsumoTabaco] = useState("No"); // Valor inicial
  const [deporteAltoRiezgo, setDeporteAltoRiezgo] = useState("No"); // Valor inicial
  const [tieneEnfermedad, setTieneEnfermedad] = useState("No"); // Valor inicial

  const handleRadioChangeAlchol = (event) => {
    setConsumoAlcohol(event.target.value);
    // console.log("target ", event.target);
  };

  const handleRadioChangeDrogas = (event) => {
    setConsumoDrogas(event.target.value);
    // console.log("target ", event.target);
  };

  const handleRadioChangeTabaco = (event) => {
    setConsumoTabaco(event.target.value);
    // console.log("target ", event.target);
  };

  const handleRadioChangeDeporteAltoRiezgo = (event) => {
    setDeporteAltoRiezgo(event.target.value);
    setInfoMedica(() => ({ ...infoMedica, deportesAr: event.target.value }));
    // console.log("target ", event.target);
  };

  const handleRadioChangeTieneEnfermedad = (event) => {
    setTieneEnfermedad(event.target.value);
    setInfoMedica(() => ({
      ...infoMedica,
      tieneEnfermedad: event.target.value,
    }));
    // console.log("target ", event.target);
  };

  const info = async () => {
    let data = await get("getInfoMedica", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoMedica(() => ({
        ...infoMedica,
        ...data.infoMedica[0],
      }));
      //console.log(data.infoMedica[0]);
      if (data.infoMedica[0].deportesAr == " ") {
        setDeporteAltoRiezgo("No");
      } else {
        setDeporteAltoRiezgo("Si");
      }

      if (data.infoMedica[0].tieneEnfermedad === 0) {
        setTieneEnfermedad("Si");
      } else {
        setTieneEnfermedad("No");
      }
    }
    //setDeportesAr(dataInfoMedica[0].deportesAr);
  };

  const info2 = async () => {
    let data = await get("getInfoEstupefaciente", cookies.get("id"));
    //console.log(data);
    if (data.statuscode === 200) {
      setInfoEstupefaciente(() => ({
        ...infoEstupefaciente,
        ...data.infoMedica,
      }));

      // console.log(infoEstupefaciente[0]);

      if (data.infoMedica[0].idCatEstupefaciente === 1) {
        setConsumoAlcohol(1);
      } else {
        setConsumoAlcohol(1003);
      }
      if (data.infoMedica[1].idCatEstupefaciente === 2) {
        setConsumoDrogas(2);
      } else {
        setConsumoDrogas(1003);
      }
      if (data.infoMedica[2].idCatEstupefaciente === 3) {
        setConsumoTabaco(3);
      } else {
        setConsumoTabaco(1003);
      }
    }
    // console.log(consumoAlcohol);
    // console.log(consumoDrogas);
    // console.log(consumoTabaco);
  };

  function miFuncion() {
    addInfoMedica();
    addEstupefaciente();
  }

  let dataInfoMedica = {};
  let dataInfoEstupefaciente = {};
  let dataInfoEstupefacienteDrogas = {};
  let dataInfoEstupefacienteTabaco = {};

  const addInfoMedica = async () => {
    dataInfoMedica = {
      idUser: cookies.get("id"),
      peso: infoMedica.peso,
      estatura: infoMedica.estatura,
      deportesAr: infoMedica.deportesAr,
      tieneEnfermedad: infoMedica.tieneEnfermedad,
      enfermedadDescripcion: infoMedica.enfermedadDescripcion,
      idResPrecalifica: 294,
      escoAcreditado: false, //JPB 13-11-2023 Es true ya que estamos subiendo en la seccion de Info Coacreditado
    };

    let eleccionIU = await get("getInfoMedica", cookies.get("id"));
    if (eleccionIU.msg === "El usuario no tiene informacion medica") {
      let insertar = await post("addInfoMedica", dataInfoMedica);

      if (insertar.statuscode === 200) {
        // console.log("addInfoMedica  correcto");
      } else {
        // console.log("addInfoMedica  fallo");
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put("updateInfoMedica", dataInfoMedica);

        if (actualizar.statuscode === 200) {
          // console.log("updateInfoMedica correcto");
        } else {
          // console.log("updateInfoMedica fallo");
        }
      }
    }
  };

  const addEstupefaciente = async () => {
    dataInfoEstupefaciente = {
      idEstupefacienteUser: 1,
      idCatEstupefaciente: parseInt(consumoAlcohol),
      idUser: cookies.get("id"),
      idResPrecalifica: 1608,
    };

    dataInfoEstupefacienteDrogas = {
      idEstupefacienteUser: 2,
      idCatEstupefaciente: parseInt(consumoDrogas),
      idUser: cookies.get("id"),
      idResPrecalifica: 1608,
    };
    dataInfoEstupefacienteTabaco = {
      idEstupefacienteUser: 3,
      idCatEstupefaciente: parseInt(consumoTabaco),
      idUser: cookies.get("id"),
      idResPrecalifica: 1608,
    };

    let eleccionIU = await get("getInfoEstupefaciente", cookies.get("id"));
    if (eleccionIU.msg === "El usuario no tiene informacion medica") {
      let insertar = await post(
        "AddInfoEstupefaciente",
        dataInfoEstupefaciente
      );
      let insertarDrogas = await post(
        "AddInfoEstupefaciente",
        dataInfoEstupefacienteDrogas
      );
      let insertarTabaco = await post(
        "AddInfoEstupefaciente",
        dataInfoEstupefacienteTabaco
      );

      if (
        insertar.statuscode === 200 &&
        insertarDrogas.statuscode === 200 &&
        insertarTabaco.statuscode === 200
      ) {
        Swal.fire({
          title: "Datos agregados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });

        // setTimeout(() => {
        //     window.location.href='./'
        // }, 1000);
      } else {
        Swal.fire({
          title: "Datos agregados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put(
          "updateInfoEstupefaciente",
          dataInfoEstupefaciente
        );
        let actualizarDrogas = await put(
          "updateInfoEstupefaciente",
          dataInfoEstupefacienteDrogas
        );
        let actualizarTabaco = await put(
          "updateInfoEstupefaciente",
          dataInfoEstupefacienteTabaco
        );

        if (
          actualizar.statuscode === 200 &&
          actualizarDrogas.statuscode === 200 &&
          actualizarTabaco.statuscode === 200
        ) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });

          // setTimeout(() => {
          //     window.location.href='./'
          // }, 1000);
        } else {
          Swal.fire({
            title: "Datos actualizados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  useEffect(() => {
    info();
    info2();
  }, []);
  return (
    <>
      <div className=" cont_Info">
        <a className="back" href="/informacion">
          {"<"} Regresar a documentación
        </a>
        <h1 className="titt">Información Médica</h1>
        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>
        <div className="Resum">
          <div className="header">
            <h3>Empleo actual</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>¿Peso? (kg)</label>
                <input
                  type="text"
                  id="peso"
                  name="peso"
                  value={infoMedica.peso}
                  onInput={(ev) => {
                    setInfoMedica(() => ({
                      ...infoMedica,
                      peso: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>¿Estatura? (mt)</label>
                <input
                  type="text"
                  id="estatura"
                  name="estatura"
                  value={infoMedica.estatura}
                  onInput={(ev) => {
                    setInfoMedica(() => ({
                      ...infoMedica,
                      estatura: ev.target.value,
                    }));
                  }}
                ></input>
              </div>

              <div className="col3 campo-form">
                <label>¿Deportes de alto riezgo?</label>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deporteAltoRiezgo"
                      id="inlineRadio1_deporteAltoRiezgo"
                      value="Si"
                      checked={infoMedica.deportesAr === "Si"}
                      onChange={handleRadioChangeDeporteAltoRiezgo}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_deporteAltoRiezgo"
                    >
                      Si
                    </label>
                  </div>
                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="deporteAltoRiezgo"
                      id="inlineRadio2_deporteAltoRiezgo"
                      value="No"
                      checked={infoMedica.deportesAr === "No"}
                      onChange={handleRadioChangeDeporteAltoRiezgo}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_deporteAltoRiezgo"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col3 campo-form">
                <label>¿Consumo de alcohol?</label>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consumoAlcohol"
                      id="inlineRadio1_alcohol"
                      value={1}
                      checked={consumoAlcohol == 1}
                      onChange={handleRadioChangeAlchol}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_alcohol"
                    >
                      Si
                    </label>
                  </div>
                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consumoAlcohol"
                      id="inlineRadio2_alcohol"
                      value={1003}
                      checked={consumoAlcohol == 1003}
                      onChange={handleRadioChangeAlchol}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_alcohol"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col3 campo-form">
                <label>¿Consumo de drogas?</label>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consumoDrogas"
                      id="inlineRadio1_drogas"
                      value={2}
                      checked={consumoDrogas == 2}
                      onChange={handleRadioChangeDrogas}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_drogas"
                    >
                      Si
                    </label>
                  </div>
                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consumoDrogas"
                      id="inlineRadio2_drogas"
                      value={1003}
                      checked={consumoDrogas == 1003}
                      onChange={handleRadioChangeDrogas}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_drogas"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col3 campo-form">
                <label>¿Consumo de cigarro?</label>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consumoTabaco"
                      id="inlineRadio1_tabaco"
                      value={3}
                      checked={consumoTabaco == 3}
                      onChange={handleRadioChangeTabaco}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_tabaco"
                    >
                      Si
                    </label>
                  </div>
                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="consumoTabaco"
                      id="inlineRadio2_tabaco"
                      value={1003}
                      checked={consumoTabaco == 1003}
                      onChange={handleRadioChangeTabaco}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_tabaco"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <hr />

              <div className="justificado">
                <label>
                  ¿Padece o ha padecido alguna de las siguientes enfermedades?{" "}
                </label>
                <p>
                  ¿Leucemia, sida, insuficiencia renal, diabetes, epilepsia,
                  enfermedades del hígado (por ejemplo hepatitis crónica,
                  cirrosis, insuficiencia hepática), enfermedades del corazón
                  (por ejemplo angina de pecho, infarto al miocardio, cirugía
                  del corazón), hipertensión, tumores malignos o cáncer,
                  hemorragias o tumor cerebral, padecimientos psiquiátricos,
                  cirugías de columna, glaucoma, sordera, enfermedad pulmonar
                  obstructiva crónica?
                </p>
                <div className="form-check form-check-inline">
                  <div className="opcionesRadios">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tieneEnfermedad"
                      id="inlineRadio1_tieneEnfermedad"
                      value={1}
                      checked={infoMedica.tieneEnfermedad == 1}
                      onChange={handleRadioChangeTieneEnfermedad}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio1_tieneEnfermedad"
                    >
                      Si
                    </label>
                  </div>
                  <div className="opcionesRadios centradoRadiosButon">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="tieneEnfermedad"
                      id="inlineRadio2_tieneEnfermedad"
                      value={0}
                      checked={infoMedica.tieneEnfermedad == 0}
                      onChange={handleRadioChangeTieneEnfermedad}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="inlineRadio2_tieneEnfermedad"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <div className="col3 campo-form">
                <label>En caso de cualquier si, por favor especifique</label>
                <input
                  type="text"
                  id="cualEnfermedad"
                  name="cualEnfermedad"
                  value={infoMedica.enfermedadDescripcion}
                  onInput={(ev) => {
                    setInfoMedica(() => ({
                      ...infoMedica,
                      enfermedadDescripcion: ev.target.value,
                    }));
                  }}
                ></input>
              </div>
            </form>
          </div>
        </div>
        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <a className="btn btn_bco col3 campo-form " href="/informacion">
              {" "}
              Regresar
            </a>
            <a
              className="btn btn_morado col3 campo-form"
              onClick={() => {
                miFuncion();
              }}
            >
              Guardar
            </a>
          </div>
        </div>
        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
      </div>
    </>
  );
}

export function InfoInmueble() {
  const cookies = new Cookies();

  const {
    formState: { errors },
    handleSubmit,
  } = useForm();

  const [direccion, setDireccion] = useState("");
  const [exterior, setExterior] = useState("");
  const [interior, setInterior] = useState("");
  const [estado, setEstado] = useState("");
  const [estadoAux, setEstadoAux] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [colonia, setColonia] = useState("");
  const [cp, setCp] = useState("");

  const [validarReglaTelefono, setValidarReglaTelefono] = useState();
  const [validarReglaNacimiento, setValidarReglaNacimento] = useState();

  const [infoInmueble, setInfoInmueble] = useState({});
  const [idUsuario, setIdUsuario] = useState({});

  const info = async () => {
    // console.log(cookies.get("id"));
    let data = await get("getInfoInmueble", cookies.get("id"));

    // console.log(data.infoInmuebles[0].nombreVendedor);

    if (data.statuscode === 200) {
      setEstado(data.infoInmuebles[0].estado);
      setMunicipio(data.infoInmuebles[0].municipio);
      setColonia(data.infoInmuebles[0].colonia);
      setCp(data.infoInmuebles[0].cp);
      setDireccion(data.infoInmuebles[0].calle);
      setExterior(data.infoInmuebles[0].noExt);
      setInterior(data.infoInmuebles[0].noInt);

      setInfoInmueble(() => ({
        ...infoInmueble,
        ...data.infoInmuebles[0],
      }));
    }
  };

  useEffect(() => {
    info();
  }, []);

  const obtenerDataCp = async (cp) => {
    let respuestaPorCP = await getCP("info_cp", cp);
    obtenerClaveIso(respuestaPorCP.response.estado);
    listColoniasCP(respuestaPorCP.response.asentamiento);
    setMunicipio(respuestaPorCP.response.municipio);
  };

  const obtenerClaveIso = async (estadoCP) => {
    let obtenerClave = await get("obtClaveISOPorEstado", estadoCP);
    setEstadoAux(estadoCP);
    if (obtenerClave.statuscode === 200) {
      setEstado(obtenerClave.iso);
    }
  };

  const listColoniasCP = (colonias) => {
    let opcionDefault = (
      <option value="" selected="selected" disabled>
        Seleccione una opción...{" "}
      </option>
    );

    colonias = colonias.map((colonia) => (
      <option value={colonia} key={colonia}>
        {colonia}
      </option>
    ));
    colonias.unshift(opcionDefault);

    const selectcol = document.getElementById("coloniaSelect");
    selectcol.className = "";
    const inputcol = document.getElementById("coloniaInput");
    inputcol.className = "ocultarDiv";

    let root = ReactDOM.createRoot(document.getElementById("coloniaSelect"));
    root.render(colonias);
  };

  let data = {};
  let datauUpdate = {};
  const sendInfoInmueble = async () => {
    datauUpdate = {
      idUser: cookies.get("id"),
      nombreVendedor: infoInmueble.nombreVendedor,
      aPaternoVendedor: infoInmueble.aPaternoVendedor,
      aMaternoVendedor: infoInmueble.aMaternoVendedor,
      emailVendedor: infoInmueble.emailVendedor,
      calle: direccion,
      noExt: exterior,
      noInt: interior,
      cp: cp,
      municipio: municipio,
      colonia: colonia,
      estado: estado,
      idResPrecalifica: 294,
    };

    data = {
      idUser: cookies.get("id"),
      nombreVendedor: infoInmueble.nombreVendedor,
      aPaternoVendedor: infoInmueble.aPaternoVendedor,
      aMaternoVendedor: infoInmueble.aMaternoVendedor,
      emailVendedor: infoInmueble.emailVendedor,
      calle: direccion,
      noExt: exterior,
      noInt: interior,
      cp: cp,
      municipio: municipio,
      colonia: colonia,
      estado: estado,
      idResPrecalifica: 294,
    };

    let eleccionIU = await get("getInfoInmueble", cookies.get("id"));

    if (eleccionIU.msg === "El usuario no tiene informacion de inmueble") {
      let insertar = await post("addInfoInmueble", data);

      if (insertar.statuscode === 200) {
        Swal.fire({
          title: "Datos guardados correctamente",
          icon: "success",
          confirmButtonText: "Aceptar",
        });

        setTimeout(() => {
          window.location.href = "./";
        }, 1000);
      } else {
        Swal.fire({
          title: "Datos ingresados erroneos",
          text: "Verifica que tus datos sean correctos ",
          icon: "error",
          confirmButtonText: "Aceptar",
        });
      }
    } else {
      if (eleccionIU.statuscode === 200) {
        //let presendInfoLaboral = await post("addInfoLaboral", data)
        let actualizar = await put("updateInfoInmueble", datauUpdate);

        if (actualizar.statuscode === 200) {
          Swal.fire({
            title: "Datos actualizados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });

          setTimeout(() => {
            window.location.href = "./";
          }, 1000);
        } else {
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    }
  };

  function miFuncion() {
    sendInfoInmueble();
  }

  return (
    <>
      <div className=" cont_Info">
        <a className="back" href="/informacion">
          {"<"} Regresar a documentación
        </a>
        <h1 className="titt">Información del inmueble y Vendedor</h1>
        <p>
          Asegurate de poner la información correcta. Podrás regresar a
          editarlo.
        </p>
        <div className="Resum">
          <div className="header">
            <h3>Datos del Vendedor</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              {/* <div className="col3 campo-form estado marginEspacio label">
                                <label>Genero</label>
                                <select name="tipo" id="sexo" value={infoInmueble.sexo}
                                    onChange={(ev) => {
                                        const isMale = ev.target.value === "true"; // Convertir a booleano
                                        setInfoInmueble((prevInfo) => ({ ...prevInfo, sexo: isMale }));
                                    }} required>
                                    <option value="">Seleccione una opción.....</option>
                                    <option value="true">Masculino</option>
                                    <option value="false">Femenino</option>
                                </select>
                            </div> */}
              <div className="col3 campo-form">
                <label>Nombre</label>
                <input
                  type="text"
                  id="nombreVendedor"
                  name="nombreVendedor"
                  value={infoInmueble.nombreVendedor}
                  onInput={(ev) => {
                    setInfoInmueble(() => ({
                      ...infoInmueble,
                      nombreVendedor: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Paterno</label>
                <input
                  type="text"
                  id="aPaternoVendedor"
                  name="aPaternoVendedor"
                  value={infoInmueble.aPaternoVendedor}
                  onInput={(ev) => {
                    setInfoInmueble(() => ({
                      ...infoInmueble,
                      aPaternoVendedor: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Apellido Materno</label>
                <input
                  type="text"
                  id="aMaternoVendedor"
                  name="aMaternoVendedor"
                  value={infoInmueble.aMaternoVendedor}
                  onInput={(ev) => {
                    setInfoInmueble(() => ({
                      ...infoInmueble,
                      aMaternoVendedor: ev.target.value.replace(
                        /[^A-Za-zÁÉÍÓÚáéíóúÜüÑñ ]/g,
                        ""
                      ),
                    }));
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>E-Mail</label>
                <input
                  type="email"
                  value={infoInmueble.emailVendedor}
                  autoComplete="off"
                  name="emailVendedor"
                  id="emailVendedor"
                  onChange={(ev) => {
                    let tempCorreo = ev.target.value;
                    setInfoInmueble(() => ({
                      ...infoInmueble,
                      emailVendedor: tempCorreo,
                    }));
                  }}
                />
                {(infoInmueble.emailVendedor === "" ||
                  infoInmueble.emailVendedor === " ") && (
                  <small className="span">Este campo es requerido</small>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="Resum">
          <div className="header">
            <h3>Direccion de Inmueble</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label htmlFor="cp">Código postal</label>
                <input
                  type="tel"
                  name="cp"
                  id="cp"
                  size={5}
                  maxLength="5"
                  autoComplete="off"
                  value={cp}
                  onInput={(ev) => {
                    if (isNaN(ev.target.value) === true) {
                      setCp("");
                    } else {
                      if (ev.target.value.length === 5) {
                        obtenerDataCp(ev.target.value);
                      }
                      setCp(ev.target.value);
                    }
                  }}
                />
                <small>
                  Al ingresar su código postal correcto, en automático se
                  registrará su estado y municipio.
                </small>
                {cp === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Direccion</label>
                <input
                  type="text"
                  autoComplete="off"
                  value={direccion}
                  onInput={(ev) => {
                    setDireccion(ev.target.value);
                  }}
                ></input>
              </div>
              <div className="col3 campo-form">
                <label>Número Ext</label>
                <input
                  type="tel"
                  name="numExt"
                  id="numExt"
                  autoComplete="off"
                  value={exterior}
                  onInput={(ev) => {
                    setExterior(ev.target.value);
                  }}
                />
              </div>
              <div className="col3 campo-form">
                <label>Número Int</label>
                <input
                  type="tel"
                  name="numInt"
                  id="numInt"
                  autoComplete="off"
                  value={interior}
                  onInput={(ev) => {
                    setInterior(ev.target.value);
                  }}
                />
              </div>
              <div className="col3 campo-form" id="delegacion">
                <label htmlFor="estado">Estado</label>
                <input
                  name="estado"
                  id="estado"
                  disabled
                  value={estado !== "" ? estado : estadoAux}
                ></input>
              </div>
              <div className="col3 campo-form" id="colonia">
                <label htmlFor="ciudad">Delegación / Municipio</label>
                <input
                  name="ciudad"
                  id="ciudad"
                  disabled
                  value={municipio}
                ></input>
              </div>
              <div className="col3 campo-form" id="colonia">
                <label htmlFor="coloniaSelect">Colonia</label>
                <select
                  type="text"
                  className="ocultarDiv"
                  id="coloniaSelect"
                  name="coloniaSelect"
                  onChange={(ev) => {
                    setColonia(ev.target.value);
                  }}
                />
                <input
                  type="text"
                  id="coloniaInput"
                  name="coloniaInput"
                  disabled
                  value={colonia}
                />
                {colonia === "" && (
                  <small className="span">Este campo es requerido </small>
                )}
              </div>
            </form>
          </div>
        </div>

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <a className="btn btn_bco  campo-form " href="/informacion">
              {" "}
              Regresar
            </a>
            <button
              type="submit"
              className="btn btn_morado campo-form"
              onClick={() => {
                miFuncion();
              }}
            >
              Guardar
            </button>
          </div>
        </div>

        <div className="header">
          <h3>
            Si realizaste algun cambio, recuerda <br />{" "}
            <span className="morado"> guardar </span> antes de salir.
          </h3>
        </div>
      </div>
    </>
  );
}

export function DocumentosEnviados() {
  return (
    <>
      <div className=" cont_Info">
        <a className="back" href="/informacion-general">
          {"<"} Regresar a documentación
        </a>
        <div className="dosColumns cont_flex">
          <div className="col6 bg_verde">
            <h1 className="titt">
              Tus documentos se han enviado correctamente
            </h1>
            <p>
              Tus documentos serán evaluados por uno de nuestros agentes.{" "}
              <b>
                Recibirás un correo electrónico en un lapso menor a 2 días con
                respuesta a tu solicitud.
              </b>
            </p>
            {/* <!--<a hrf="#" className="btn btn_morado">Listos cuando tu lo estés</a>--> */}
            <Link to="/home" className="btn btn_azul">
              Regresar a tu resumen
            </Link>
          </div>
          <div className="cont_img col6">
            {" "}
            <img src={Rectangle} />{" "}
          </div>
        </div>
      </div>
    </>
  );
}
