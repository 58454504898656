import "../../App.css";
import * as ReactDOM from "react-dom/client";
import { get, post, put } from "../../utils/http";
import { useState, useEffect} from "react";
import { Link, useNavigate  } from "react-router-dom";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { obtEmpresaUrl } from "../../utils/global_functions";
import { textoAMoneda, agergarDecimales } from "../../utils/operaciones_formulario";
import { ruleNacimiento } from "../../assets/validateRules";
import Cookies from "universal-cookie";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <-------------------------------------------- Pantalla con datos básicos del usuario -------------------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function MisDatosHipoteca() {
  const [isLoading, setIsLoading] = useState(true);
  const [capitalizaciones, setCapitalizaciones] = useState([]);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const {
    register,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      montoOriginal: "$ 0",
      valorCompraTotal: "$ 0",
      costoSeguroVida: "$ 0",
      costoSeguroDanios: "$ 0",
      saldoCredito: "$ 0",
      pagoMensual: "$ 0",
    }
  });

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        await renderizarDropdowns();
        await cargarInfo();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
  }, []);

  const formatoMoneda = (numero) => `$ ${new Intl.NumberFormat('es-MX', {minimumFractionDigits: 2, maximumFractionDigits: 2}).format(numero)}`;

  const formatoFechaDMA = (fecha) => {
    const partes = fecha.split("T")[0].split('-');
    return `${partes[2]}/${partes[1]}/${partes[0]}`
  }
  const formatoFechaAMD = (fecha) => {
    const partes = fecha.split('/');
    return `${partes[2]}-${partes[1]}-${partes[0]}T00:00:00`
  }

  async function renderizarDropdowns(){
    let root;
    let opcionDefault = (
      <option value="" selected disabled>
        Seleccione una opción...{" "}
      </option>
    );

    let catalogos = await get("getCatalogosHipoteca", "");
    if (catalogos.statuscode === 200) {
      //console.log("catalogos: ", catalogos);

      let bancos = catalogos.respuesta.bancosHipoteca.map((banco) => (
        <option value={banco.idBanco} key={banco.idBanco}>
          {banco.nombreBanco}
        </option>
      ));
      bancos.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectBancosHipoteca"));
      root.render(bancos);

      setCapitalizaciones(catalogos.respuesta.catCapitalizaciones);
      let capitalizaciones = catalogos.respuesta.catCapitalizaciones.map((capitalizacion) => (
        <option value={capitalizacion.idCapitalizacion} key={capitalizacion.idCapitalizacion}>
          {capitalizacion.nombreCapitalizacion}
        </option>
      ));

      capitalizaciones.unshift(opcionDefault);
      root = ReactDOM.createRoot(document.getElementById("selectCapitalizacionHipoteca"));
      root.render(capitalizaciones);
    }
    
  }

  async function cargarInfo(){
    const idHipoteca = localStorage.getItem("idHipoteca");
    //console.log("idHipoteca", idHipoteca);
    if(idHipoteca != null){
      const respuesta = await post("getInfoHipoteca", {
        idUser: parseInt(cookies.get("id")),
        idHipoteca: parseInt(idHipoteca)
      })

      if(respuesta.statuscode === 200){
        const infoHipoteca = respuesta.respuesta;
        //console.log(infoHipoteca);
        //console.log(infoHipoteca.idCatBanco);
        setValue("identificador", infoHipoteca.identificador);
        setValue("banco", infoHipoteca.idCatBanco);
        setValue("tasaAnual", infoHipoteca.tasaAnual);
        setValue("montoOriginal", formatoMoneda(infoHipoteca.montoOriginalCredito));
        setValue("fechaApertura", formatoFechaDMA(infoHipoteca.fechaApertura));
        setValue("plazoCredito", infoHipoteca.plazoCredito);
        setValue("capitalizacion", infoHipoteca.idCatCapitalizacion);
        setValue("periodosTotales", infoHipoteca.periodosTotales);
        setValue("costoSeguroVida", formatoMoneda(infoHipoteca.costoSeguroVida !== null ? infoHipoteca.costoSeguroVida : 0));
        setValue("costoSeguroDanios", formatoMoneda(infoHipoteca.costoSeguroDanios !== null ? infoHipoteca.costoSeguroDanios : 0));
        setValue("valorCompraTotal", formatoMoneda(infoHipoteca.valorCompraTotal));
        setValue("saldoCredito", formatoMoneda(infoHipoteca.saldoCredito));
        setValue("periodosFaltantes", infoHipoteca.periodosFaltantes);
        setValue("pagoMensual", formatoMoneda(infoHipoteca.pagoMensual));
      }else{
        
      }
    }
    
  }

  const enviar = async (datos) => {
    //console.log("DATOS: ", datos);
    const idHipoteca = localStorage.getItem("idHipoteca");

    const datosHipoteca = {
      idUser: parseInt(cookies.get("id")),
      identificador: datos.identificador,
      idCatBanco: Number(datos.banco),
      tasaAnual: Number(datos.tasaAnual),
      montoOriginalCredito: Number(datos.montoOriginal.replace(/[^0-9.]/g, "")),
      fechaApertura: formatoFechaAMD(datos.fechaApertura),
      plazoCredito: Number(datos.plazoCredito),
      idCatCapitalizacion: Number(datos.capitalizacion),
      periodosTotales: Number(datos.periodosTotales),
      costoSeguroVida: Number(datos.costoSeguroVida.replace(/[^0-9.]/g, "")),
      costoSeguroDanios: Number(datos.costoSeguroDanios.replace(/[^0-9.]/g, "")),
      valorCompraTotal: Number(datos.valorCompraTotal.replace(/[^0-9.]/g, "")),
      saldoCredito: Number(datos.saldoCredito.replace(/[^0-9.]/g, "")),
      periodosFaltantes: Number(datos.periodosFaltantes),
      pagoMensual: Number(datos.pagoMensual.replace(/[^0-9.]/g, ""))
    }
      
    //console.log("Datos enviados: ", datosHipoteca);

    if(idHipoteca != null){
      const respuesta = await put("updateHipoteca", {
        ...datosHipoteca,
        idHipoteca: parseInt(idHipoteca)
      });

      localStorage.removeItem("idHipoteca");
      navigate("/miHipoteca");
    }else{
      const respuesta = await post("addHipoteca", datosHipoteca);
      if(respuesta.statuscode === 200){
        localStorage.removeItem("idHipoteca");
        navigate("/miHipoteca");
      }else{
        Swal.fire({
          icon: "error",
          title: "Error al crear hipoteca",
          text: respuesta.respuesta ? respuesta.respuesta : "Inténtelo nuevamente más tarde.",
  
          showCancelButton: false,
          confirmButtonText: "Aceptar",
        }).then((result) => {});
      }
      
    }

  }

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/miHipoteca" className="link linkGrande back" 
          onClick={() => {
            localStorage.removeItem("idHipoteca");
          }}
        >
          {" "}
          {"<"} Regresar a Mis Hipotecas
        </Link>
        <br></br>
        <h1 className="titt">Los datos de mi hipoteca</h1>
        <p>
          Asegúrate poner los datos como aparecen en tu estado de cuenta 
          para que podamos asesorarte en como pagar tu hipoteca antes de tiempo.
        </p>

        <div className="Resum">
          <div className="cont_flex cont-form">
            <form onSubmit={handleSubmit(enviar)}>
              <div className="header">
                <h3>Datos</h3>
              </div>
              <div className="col3 campo-form">
                <label>Nombre Identificador*</label>
                <input
                  type="text"
                  maxLength={15}
                  autoComplete="off"
                  {...register("identificador", { required: true })}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^A-Za-z0-9ÁÉÍÓÚáéíóúÜüÑñ# ]/g, "");
                  }}
                />
                <small>
                  *Nombre con el que recuerdes esta hipoteca
                </small>
                {errors.identificador?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form estado marginEspacio">
                <label>Banco*</label>
                <select
                  id="selectBancosHipoteca"
                  {...register("banco", { required: true })}
                >
                </select>
                {errors.banco?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Tasa Anual%*</label>
                <input
                  type="text"
                  autoComplete="off"
                  {...register("tasaAnual", { required: true})}
                  onInput={(e) => {
                    let porcentaje = e.target.value.replace(/[^0-9.]/g, "");
                    const partes = porcentaje.split(".");

                    if (partes.length > 2) {
                      porcentaje = partes[0] + "." + partes[1];
                    }
                    
                    if (partes[1] && partes[1].length > 2) {
                      porcentaje = partes[0] + "." + partes[1].substring(0, 2);
                    }

                    e.target.value = porcentaje;
                  }}
                  onBlur={(e) => {
                    if(e.target.value.endsWith(".")){
                      e.target.value = e.target.value.replace(".", "");
                    }
                  }}
                />
                {errors.tasaAnual?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="col3 campo-form">
                <label>Monto Original del Crédito</label>
                <input
                  type="text"
                  autoComplete="off"
                  {...register("montoOriginal", {required: true})}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}               
                />
                {errors.montoOriginal?.type === "validate" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Fecha de Apertura</label>
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="dd/mm/aaaa"
                  {...register("fechaApertura", {required: true, validate: fecha => ruleNacimiento(fecha)})}
                  onInput={(e) =>{
                    let fecha = e.target.value.replace(/\D/g, "");
                    // Insertar las diagonales en la posición correcta
                    if (fecha.length > 2 && fecha.length < 5) {
                      fecha = fecha.slice(0, 2) + "/" + fecha.slice(2);
                    } else if (fecha.length > 4) {
                      fecha = fecha.slice(0, 2) + "/" + fecha.slice(2, 4) + "/" + fecha.slice(4, 8);
                    }
                    e.target.value = fecha;
                  }}
                />
                {(errors.fechaApertura?.type === "required" || errors.fechaApertura?.type === "validate") && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Plazo del crédito (años)</label>
                <input
                  type="text"
                  {...register("plazoCredito", {required: true})}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  onChange={(e) =>{
                    if(getValues("capitalizacion")){
                      const capitalizacion = capitalizaciones.find(cap => cap.idCapitalizacion === Number(getValues("capitalizacion")));
                      setValue("periodosTotales", capitalizacion !== undefined ? capitalizacion.valorAnual * Number(e.target.value) : 0);
                    }
                  }}
                />
                {errors.plazoCredito?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              
              <div className="col3 campo-form estado marginEspacio">
                <label>Capitalización*</label>
                <select
                  id="selectCapitalizacionHipoteca"
                  {...register("capitalizacion", { required: true })}
                  onChange={(e) =>{
                    if(getValues("plazoCredito")){
                      const capitalizacion = capitalizaciones.find(cap => cap.idCapitalizacion === Number(e.target.value));
                      setValue("periodosTotales", capitalizacion !== undefined ? Number(getValues("plazoCredito")) * capitalizacion.valorAnual : 0 );
                    }
                  }}
                >
                </select>
                {errors.capitalizacion?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Periodos Totales</label>
                <input
                  disabled
                  type="text"
                  {...register("periodosTotales")}
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
              </div>
              <div className="col3 campo-form">
                <label>Costo Seguro Vida (Opcional)</label>
                <input
                  type="text"
                  {...register("costoSeguroVida")}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}
                />
              </div>

              <div className="col3 campo-form">
                <label>Costo Seguro Daños (Opcional)</label>
                <input
                  type="text"
                  {...register("costoSeguroDanios")}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}
                />
              </div>
              <div className="col3 campo-form">
                <label>Valor de Compra Total (Opcional)</label>
                <input
                  type="text"
                  {...register("valorCompraTotal")}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}
                />
              </div>

              <br/><br/><br/>
              <div className="header">
                <h3>Datos Situación Actual</h3>
              </div>

              <div className="col3 campo-form">
                <label>Saldo del Crédito*</label>
                <input
                  type="text"
                  {...register("saldoCredito", {required: true, validate: pago => pago.replace(/\D/g, "") > 0})}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}
                />
                {errors.saldoCredito?.type === "validate" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Periodos Faltantes*</label>
                <input
                  type="text"
                  {...register("periodosFaltantes", { required: true })}
                  onInput={(e) =>{
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                />
                <small>
                  *Los meses o quincenas que faltan por terminar tu crédito
                </small>
                {errors.periodosFaltantes?.type === "required" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>
              <div className="col3 campo-form">
                <label>Pago Mensual*</label>
                <input
                  type="text"
                  {...register("pagoMensual", {required: true, validate: pago => pago.replace(/\D/g, "") > 0})}
                  onInput={(e) => {
                    e.target.value = textoAMoneda(e.target.value);
                  }}
                  onBlur={(e) => {
                    e.target.value = agergarDecimales(e.target.value);
                  }}
                />
                <small>
                  *Solo debes de contemplar el monto de intereses más el capital. Debes de excluir el costo de los seguros.
                </small>
                {errors.pagoMensual?.type === "validate" && (
                  <span className="span">Este campo es requerido</span>
                )}
              </div>

              <div className="dosCuttonFormularios">
                  <Link
                    to="/miHipoteca"
                    className="btn btn_bco"
                    onClick={() => {
                      localStorage.removeItem("idHipoteca");
                    }}
                  >
                    Cancelar
                  </Link>

                  <button 
                    type="submit" 
                    className="btn btn_morado"
                  >
                    Guardar
                  </button>
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
