import "../../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, put } from "../../../utils/http";
import * as ReactDOM from "react-dom/client";
import { Link } from "react-router-dom";
import { TablaInvitaciones } from "../../../components/Tablas";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { obtEmpresaUrl } from "../../../utils/global_functions";
import { ModalInicioSocios } from "../../../components/Modals"

export default function InvitacionesSocios() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  const idSocios = cookies.get("idSocio");
  const [dataInvitaciones, setDataInvitaciones] = useState("");
  const [empresaLogo, setEmpresaLogo] = useState("");
  const [habilitarCarrusel, setHabilitarCarrusel] = useState(false);

  const cierraCarrusel = () => {
    setHabilitarCarrusel(false);
    localStorage.setItem("carruselInicioSesion", false);
  }

  //Obtenemos información general
  const obtInformacion = async () => {
    let data = await get("getInvitaciones", idSocios);
    if (data.statuscode === 200) {
      setDataInvitaciones(data.invitaciones);
    } else {
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        const empresa = await obtEmpresaUrl();

        setEmpresaLogo(empresa.configuracion.img);

        if (empresa.configuracion.nombre.toLowerCase() === "remax"){
          if (localStorage.getItem("carruselInicioSesion") === null){
            const respuesta = await get("primerInicioSesion", cookies.get("idSocio"));
            if (respuesta.statuscode === 200){
              setHabilitarCarrusel(!respuesta.primerInicioSesion);
              localStorage.setItem("carruselInicioSesion", !respuesta.primerInicioSesion);
              if (!respuesta.primerInicioSesion){
                put("primerInicioSesion", {idSocio: cookies.get("idSocio"), primerInicioSesion: true});
              }
            }
          }else{
            setHabilitarCarrusel(localStorage.getItem("carruselInicioSesion") === "true" ? true : false);
          }
        }

        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    obtInformacion();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}

      {
        habilitarCarrusel && (
          <ModalInicioSocios logo={empresaLogo} onClose={cierraCarrusel}/>
        )
      }

      <div className=" cont_Info">
        <h1 className="titt">Invitaciones​</h1>
        <p>Aquí puedes visualizar y crear invitaciones para tus clientes.</p>

        <div className="cont_flex cont-form">
          <div className="dosCuttonFormularios">
            <Link
              className="btn btn_bco col3 campo-form "
              to="/frmNuevoCliente"
            >
              Enviar nueva invitación a cliente
            </Link>
          </div>
        </div>
        <TablaInvitaciones arrData={dataInvitaciones}></TablaInvitaciones>
      </div>
    </>
  );
}
