import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { InfoCotizacion, PasosAseguir } from "../../components/Cards";
import { Link } from "react-router-dom";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { TarjetaSubirDocumentacion } from "../../components/Cards";
import { TarjetaCalculoDias } from "../../components/Cards";
import { TarjetaDocumentosVivienda } from "../../components/Cards";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import { get, post, put, getCP, postDocs } from "../../utils/http";
import Swal from "sweetalert2";
import { guardarPaso, obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";

// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial documentos de vivienda --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function InsExterna() {
  const [isLoading, setIsLoading] = useState(true);
  const cookies = new Cookies();
  const navigate = useNavigate();
  let idResPrecalifica = localStorage.getItem("idResPrecalifica");
  let idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);
  const [documentacionVivienda, setDocumentacionVivienda] = useState({
    documento: null, // Estado para almacenar el archivo seleccionado
  });

  const [recargarDocumentos, setRecargarDocumentos] = useState(false);

  //Para saber si ya ha subido el docuemtnos
  const [documentoSubido, setDocumentoSubido] = useState(false);

  useEffect(()=>{
    if (recargarDocumentos){
      obtDocumentos();
      setRecargarDocumentos(false);
    }
  }, [recargarDocumentos]);

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 5, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      // var urlActual = window.location.pathname;
      // let credito = localStorage.getItem("idCredito");
      // let nombrePantalla  = (credito > 0)  ? "6.Institución Externa" : "";

      // let  pantallaActualMenu = <PasosAseguir
      //     nombrePantalla ={nombrePantalla}
      //     urlActual = {urlActual}
      //     credito = {credito}
      // />

      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = "6. ";
      let descripcionPaso = "Institución Externa";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    console.log(pantallasFlujoBanco[idPantallaUrlActual].paso);
    console.log(pantallasFlujoBanco[idPantallaActual].paso);
    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  const [instExterna, setInstExterna] = useState({
    documento: null, // Estado para almacenar el archivo seleccionado
  });

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtPantallaActual();
    obtDocumentos();
  }, []);

  /*const handleDocumentoChange = (event) => {
        const selectedFile = event.target.files[0];
        setInstExterna((prevInfo) => ({
            ...prevInfo,
            documento: selectedFile,
        }));
    };*/

  const handleDocumentoChange = async (event, errorCB = () => {}) => {
    const selectedFile = event.target.files[0];
    const inputName = event.target.name;

    if (selectedFile) {
      setDocumentacionVivienda((prevInfo) => ({
        ...prevInfo,
        documento: selectedFile,
      }));
      //console.log();
      const formData = new FormData();
      formData.append("Archivo", selectedFile);
      formData.append("IdCatDocumento", inputName);
      formData.append("IdCuestionario", idCuestionarioSolicitud);
      formData.append("IdResPrecalifica", idResPrecalifica);
      try {
        let insertDocumento = await postDocs("subirDocumento", formData);

        if (insertDocumento.statuscode === 200) {
          setRecargarDocumentos(true);
          /*Swal.fire({
            title: "Datos guardados correctamente",
            icon: "success",
            confirmButtonText: "Aceptar",
          });*/
        } else {
          errorCB();
          Swal.fire({
            title: "Datos ingresados erroneos",
            text: "Verifica que tus datos sean correctos ",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      } catch (error) {
        // Maneja errores aquí
      }
    } else {
      // console.log("No se seleccionó ningún archivo");
    }
  };

  const guardarInformacion = async () => {
    /*const formData = new FormData();
        formData.append('Archivo', instExterna.documento);
        formData.append('IdCatDocumento', 15);
        //formData.append('IdCuestionario', 134);
        //formData.append('IdResPrecalifica', 294);
        formData.append('IdCuestionario', idCuestionarioSolicitud );
        formData.append('IdResPrecalifica', idResPrecalifica);

        let insertDocumento = await postDocs("subirDocumento", formData);
        if(insertDocumento.statuscode === 200){
            Swal.fire({
                title: 'Datos guardados correctamente',  
                icon: 'success',
                confirmButtonText: 'Aceptar'
            })
            */
    /*setTimeout(() => {
                siguiente();
            }, 1500);*/
    /*} else {
            Swal.fire({
                title: 'Datos ingresados erroneos',
                text: 'Verifica que tus datos sean correctos ',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            })
        }*/
  };

  const siguiente = async () => {
    if (documentoSubido) {
      dispararCorreo();
      dispararCorreoCliente();
      let dataActualizarPantalla = {};

      dataActualizarPantalla = {
        idResPrecalifica: localStorage.getItem("idResPrecalifica"),
        idCuestionario: localStorage.getItem("idCuestionarioSolicitud"),
        idCatPantallas: 6,
      };

      let actualizarPantallaAct = await put(
        "updatePantallaActual",
        dataActualizarPantalla
      );
      window.location.href = "/formalizacionFirma";
    } else {
      Swal.fire({
        title: "Datos ingresados erroneos",
        text: "Primero debe subir su documento",
        icon: "error",
        confirmButtonText: "Aceptar",
      });
    }
  };

  const dispararCorreo = async () => {
    //Ejecutivo
    let paramsEjecutivo = {
      email: localStorage.getItem("correoEjecutivo"),
      tipo: 19,
    };
    // console.log(paramsEjecutivo);
    let mandarCorreo = await post("enviarCorreos", paramsEjecutivo);
    if (mandarCorreo.statuscode === 200)
      // console.log("Correo enviado a ejecutivo");
      console.log("");
  };

  const dispararCorreoCliente = async () => {
    //Cliente
    let paramsCliente = {
      email: cookies.get("correo"),
      tipo: 18,
    };
    console.log(paramsCliente);
    let mandarCorreoCliente = await post("enviarCorreos", paramsCliente);
    if (mandarCorreoCliente.statuscode === 200)
      console.log("Correo enviado a cliente");
  };

  const borrarDocumento = async (event) => {
    const selectedFileName = event;
    //console.log(selectedFileName);
  };

  //Para borrar el archivo en caso de existir
  const handleClick = () => {
    //console.log('El componente fue clicado');
    // Puedes realizar cualquier acción que desees aquí
  };

  //Para verificar si ya tiene un documento
  const verificarDocumento = async (idCatDocumento) => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idCatDocumento: idCatDocumento,
    };

    let verificarDocumento = await post("getInfoDocumento", params);
    //console.log(idCatDocumento);
    //console.log(params);
    //console.log(verificarDocumento);
    if (verificarDocumento.statuscode === 200) {
      let cadena = verificarDocumento.value.archivo;
      // Dividir la cadena por las barras invertidas para obtener partes
      const partes = cadena.split("/");

      // Obtener la última parte que contiene el nombre de archivo y extensión
      const nombreArchivoConExtension = partes[partes.length - 1];
      setDocumentoSubido(true);
      return {
        nombreDoc: nombreArchivoConExtension,
        info: verificarDocumento.info,
      };
    } else {
      return verificarDocumento.info;
    }
  };

  //Para obtener todos los documentos
  var docs;
  const obtDocumentos = async () => {
    let data = await get("getDocumentosByPantalla", "5");
    if (data.statuscode === 200) {
      let documentos = [];
      let arrDocsCat = data.documentos[0].documentos;
      // console.log(arrDocsCat);
      // Crear un array de promesas para verificar los documentos
      const verificarPromises = arrDocsCat.map(async (documento) => {
        try {
          let resVerDoc = await verificarDocumento(documento.idCatDocumentos);
          return {
            titulo: documento.nombre,
            parrafo:
              documento.idCatDocumentos === "3" ||
              documento.idCatDocumentos === "4"
                ? "Nota de recibo"
                : "",
            link: documento.idCatDocumentos !== "2" ? false : true,
            enlace:
              documento.idCatDocumentos !== "2"
                ? ""
                : "https://saturn5.mx/contrato-ejemplo.docx",
            onDocumentoChange: { handleDocumentoChange },
            tipoDoc: documento.idCatDocumentos,
            eventoBorrar: { borrarDocumento },
            docVerificado: resVerDoc.info, // Guardar el resultado de la verificación
            nombreDocumento: resVerDoc.nombreDoc,
          };
        } catch (error) {
          console.error("Error al verificar el documento", error);
          return null;
        }
      });

      // Esperar a que todas las promesas se completen
      const documentosWithVerificaciones = await Promise.all(verificarPromises);

      // Filtrar los resultados nulos en caso de errores
      const documentosValidos = documentosWithVerificaciones.filter(
        (doc) => doc !== null
      );

      if (documentosWithVerificaciones.length === 0) {
        // console.log("No hay documentos");
      } else {
        //console.log(documentosWithVerificaciones);
        docs = documentosWithVerificaciones.map((documento) => (
          <TarjetaSubirDocumentacion
            titulo={documento.titulo}
            parrafo={documento.parrafo}
            link={documento.link}
            enlace={documento.enlace}
            onDocumentoChange={handleDocumentoChange}
            tipoDoc={documento.tipoDoc}
            del={true}
            handleClick={handleClick}
            documentoSubido={documento.docVerificado}
            notas={
              documento.titulo === "Contrato de compraventa"
                ? "Asegura que este firmado, vigente, legible y asegura que tenga la información correcta de la propiedad a adquirir."
                : documento.titulo === "Recibo de Pago de Agua"
                ? "Asegura que sea legible, y sea reciente."
                : documento.titulo === "Recibo de Pago de Predial"
                ? "Asegura que sea legible y reciente."
                : documento.titulo === "Titulo de propiedad"
                ? "Asegura que el documento este completo, sea legible y cuente con la hoja de inscripción al registro público de la propiedad."
                : documento.titulo === "Otros documentos"
                ? "Asegura que sean legibles y vigentes."
                : "Asegúrate de que sea legible y esté vigente."
            }
            nombreDocumento={documento.nombreDocumento}
          />
        ));

        const root = ReactDOM.createRoot(
          document.getElementById("documentosRender")
        );

        root.render(docs);
      }
    }
  };

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/home" className="link linkGrande back">
          {" "}
          {"<"} Regresar a mis solicitudes
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>

        <h1>Instituci&oacute;n Externa</h1>
        <p>
          En esta etapa se requiere conseguir los documentos del Infonavit o
          Fovissste para la aplicaci&oacute;n de ellos a tu cr&eacute;dito.
        </p>

        {/*<TarjetaSubirDocumentacion titulo = "Documentos institución externa" notas="Aseg&uacute;rese de que sea legible." onDocumentoChange={handleDocumentoChange}></TarjetaSubirDocumentacion>
         */}

        <div className="cont_flex con_flex_wrap" id="documentosRender"></div>

        <h3 className="titt">Notas</h3>
        <hr />
        <div id="notas"></div>
        <br />
        <a href="/notariaAsignada" className="btn ">
          Regresar
        </a>
        <a
          onClick={() => {
            siguiente();
          }}
          className="btn btn_morado"
        >
          Guardar y avanzar
        </a>

        <p>Si realizaste un cambio recuerda guardar antes de salir.</p>
      </div>
    </>
  );
}
