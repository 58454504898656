import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";

import { get, put, post } from "../../utils/http";
import { InfoCotizacion, PasosAseguir } from "../../components/Cards";
import { Link } from "react-router-dom";
//import * as ReactDOM from 'react-dom';
import * as ReactDOM from "react-dom/client";
import { TarjetaSubirDocumentacion } from "../../components/Cards";
import { TarjetaCalculoDias } from "../../components/Cards";
import { TarjetaDocumentosVivienda } from "../../components/Cards";
import {
  NotasSolicitud,
  SinNotas,
  CertificadosNotaria,
} from "../../components/Msjs";
import { guardarPaso, obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";
// <-------------------------------------------------------------------------------------------------------------------------------->
// <---------------------------------- Pantalla inicial documentos de vivienda --------------------------------->
// <-------------------------------------------------------------------------------------------------------------------------------->
export default function NotariaAsignada() {
  const cookies = new Cookies();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [descargarSolicitud, setDescargarSolicitud] = useState({});
  let idResPrecalifica = localStorage.getItem("idResPrecalifica");
  let idCuestionarioSolicitud = localStorage.getItem("idCuestionarioSolicitud");
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 11, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");
      let numeroPaso = "5. ";
      let descripcionPaso = "Notaria";
      let nombrePantalla = ` ${descripcionPaso}`;
      let numeroPantalla = `${numeroPaso}`;

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={
            <>
              <span className="numero-paso">{numeroPantalla}</span>
            </>
          }
          nombrePantalla={
            <span className="descripcion-paso">{nombrePantalla}</span>
          }
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    console.log(pantallasFlujoBanco[idPantallaUrlActual].paso);
    console.log(pantallasFlujoBanco[idPantallaActual].paso);
    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  const [notariaAsignada, setNotariaAsignada] = useState({});

  //Obtenemos información general
  const obtInformacion = async () => {
    let data = await get("getNotariaAsignada", idResPrecalifica);
    //console.log(data);
    if (data.statuscode === 200) {
      setNotariaAsignada(() => ({
        ...notariaAsignada,
        ...data.notariaAsignada[0],
      }));
    }

    //let idNotariaAsignada = data.notariaAsignada[0].estado;
    let idNotariaAsignada = data.notariaAsignada[0].idNotariaAsignada;
    // console.log(idNotariaAsignada);
    certificadosNotariaAsignada(idNotariaAsignada);
  };

  const siguiente = async () => {
    await guardarPaso(6, idCuestionarioSolicitud, idResPrecalifica);
    let credito = localStorage.getItem("idCredito");
    if (credito > 0) {
      window.location.href = "/institucionExterna";
    } else {
      window.location.href = "/formalizacionFirma";
    }
  };

  const descargarArchivosNotaria = async () => {
    const idCatDocumentos = [19, 20, 21, 22]; // Lista de valores de idCatDocumento

    for (let i = 0; i < idCatDocumentos.length; i++) {
      let params = {
        idCatDocumento: idCatDocumentos[i],
        idCuestionario: idCuestionarioSolicitud,
        idResPrecalifica: idResPrecalifica,
      };

      let dataPost = await post("getDocumento", params);
      if (dataPost.statuscode === 200) {
        setDescargarSolicitud(() => ({
          ...descargarSolicitud,
          ...dataPost.value,
        }));
      } else {
        // console.log(
        //   "No se encontró el archivo con idCatDocumento " + idCatDocumentos[i]
        // );
      }
    }
  };

  const certificadosNotariaAsignada = async (idNotariaAsignada) => {
    let dataCertificadoNotariaAsignada = await get(
      "getCertificadosNotaria",
      idNotariaAsignada
    );
    //console.log(dataCertificadoNotariaAsignada.statuscode);
    if (dataCertificadoNotariaAsignada.statuscode == 200) {
      // console.log(dataCertificadoNotariaAsignada);
      // console.log("ggg", dataCertificadoNotariaAsignada.certificado);

      if (dataCertificadoNotariaAsignada.certificado.length > 0) {
        let certificadosNotaria;
        let estatus = dataCertificadoNotariaAsignada.certificado.map(
          (certificado) => certificado.estatus
        );

        certificadosNotaria = <CertificadosNotaria idEstatus={estatus} />;

        const root = ReactDOM.createRoot(
          document.getElementById("certificadoNotarias")
        );
        root.render(certificadosNotaria);
      }
    }
  };

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };
    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    obtInformacion();
    descargarArchivosNotaria();
    //certificadosNotariaAsignada();
    obtPantallaActual();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      <div className=" cont_Info">
        <Link to="/home" className="link linkGrande back">
          {" "}
          {"<"} Regresar a mis solicitudes
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>
        <h1>Notar&iacute;a Asignada</h1>
        <p>
          Se ha asignado una notar&iacute;a y se ha comenzado a trabajar en la
          solicitud de los certificados.
        </p>

        <h3 className="titt">Notas</h3>
        <hr />
        <div id="notas"></div>
        <br />

        <div className="Resum">
          <div className="header">
            <h3>Datos de la notar&iacute;a</h3>
          </div>
          <div className="cont_flex cont-form">
            <form>
              <div className="col3 campo-form">
                <label>Estado </label>
                <input type="text" value={notariaAsignada.estado} />
              </div>

              <div className="col3 campo-form">
                <label>Notar&iacute;a</label>
                <input type="text" value={notariaAsignada.notaria} />
              </div>

              <div className="col3 campo-form">
                <label>Nombre de notario </label>
                <input type="text" value={notariaAsignada.nombreNotario} />
              </div>

              <div className="col3 campo-form">
                <label>Abogado </label>
                <input type="text" value={notariaAsignada.abogado} />
              </div>

              <div className="col3 campo-form">
                <label>Tel&eacute;fono</label>
                <input type="text" value={notariaAsignada.telefono} />
              </div>

              <div className="col3 campo-form">
                <label>Direcci&oacute;n </label>
                <input type="text" value={notariaAsignada.direccion} />
              </div>
            </form>
          </div>
        </div>

        {/* <div className="Resum">
                    <div className="header">
                        <h3>Certificados</h3>
                    </div>

                    <div className="cont_flex con_flex_wrap">
                        <div className="cont_loop">
                            <h3>Agua</h3>
                            <p>icono</p>
                            <p>Pendiente</p>
                        </div>

                        <div className="cont_loop">
                            <h3>Predio</h3>
                            <p>icono</p>
                            <p>Valido 01/02/2023</p>
                        </div>

                        <div className="cont_loop">
                            <h3>Libre gravemen</h3>
                            <p>icono</p>
                            <p>Pendiente</p>
                        </div>

                        <div className="cont_loop">
                            <h3>Constancia de zonificación</h3>
                            <p>icono</p>
                            <p>No valido</p>
                        </div>
                    </div>

                </div> */}

        <div id="certificadoNotarias"></div>

        {
          /* 
            <div className="grupo-botones">
              <a href="/avaluo" className="btn btn_big ">
                Regresar
              </a>
              <a
                onClick={() => {
                  siguiente();
                }}
                className="btn   btn_big"
              >
                Guardar y avanzar
              </a>
              {<a onClick={descargarArchivosNotaria} className="btn btn_big">
                Descargar documentos
              </a> }
            </div>

            <p>Si realizaste un cambio recuerda guardar antes de salir.</p>
          */
        }
	
      </div>
    </>
  );
}
