import "../../App.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import { get, put, post } from "../../utils/http";
import { PasosAseguir } from "../../components/Cards";
import { Link } from "react-router-dom";
import * as ReactDOM from "react-dom/client";
import { TarjetaDocumentosVivienda } from "../../components/Cards";
import { NotasSolicitud, SinNotas } from "../../components/Msjs";
import { guardarPaso, obtEmpresaUrl, pantallasFlujoBanco } from "../../utils/global_functions";
import { ModalInformacionSubida } from "../../components/Modals";
//TODO: COMPONENTE DE PROCESOS
//TODO VERIFICACIÓN DE TODOS LOS PASOS

export default function SolicitudAceptada() {
  const [isLoading, setIsLoading] = useState(true);
  const [openInfoSubida, setOpenInfoSubida] = useState(false);
  const navigate = useNavigate();
  const cookies = new Cookies();
  //IdResPrecalifica
  const idResPrecalifica = localStorage.getItem("idResPrecalifica");
  //IdCuestionarioSolicitud
  const idCuestionarioSolicitud = localStorage.getItem(
    "idCuestionarioSolicitud"
  );
  // Definimos un estado para idPantallaActual
  const [idPantallaActual, setIdPantallaActual] = useState(null);
  //Para comprobar el estado del acceso
  const [acceso, setAcceso] = useState(false);
  //Para habilitar o deshabilitar el boton de enviar
  const [botonDeshabilitado, setBotonDeshabilitado] = useState(true);

  //*Para comprobar si se ha iniciado el proceso
  const comprobarAcceso = () => {
    if (
      idResPrecalifica !== undefined &&
      idResPrecalifica !== null &&
      idCuestionarioSolicitud !== undefined &&
      idCuestionarioSolicitud !== null
    ) {
      setAcceso(true);
    }
  };

  //Obtenemos la pantalla actual 20-09-23
  const obtPantallaActual = async () => {
    let dataPantalla = await get("getPantallaActual", idResPrecalifica);
    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas !== null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        setIdPantallaActual(dataPantalla.infoProcesoPantalla.idCatPantallas);

        obtNotasPantallaActual();

        obtProgresoPantalla(dataPantalla);
      } else {
        setIdPantallaActual(0);
      }
    }
  };

  //Obtenemos las notas de la pantalla actual 20-09-23
  const obtNotasPantallaActual = async () => {
    let params = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idPantallaActual: 3, //Asignamos directo ya que no esta respetando la función asincrona por lo que al usar idPantallaActual no lo identifica
    };

    let dataNotasPantalla = await post("getNotas", params);

    // Obtén el div con la identificación 'notas'
    const notasDiv = document.getElementById("notas");

    // Crea una instancia de createRoot
    const rootNotas = ReactDOM.createRoot(notasDiv);

    if (dataNotasPantalla.statuscode === 200) {
      // Renderizar el contenido de dataNotasPantalla en el componente de notas
      rootNotas.render(
        <div>
          {
            <NotasSolicitud
              numNota={dataNotasPantalla.infoNotas.idNotas}
              nota={dataNotasPantalla.infoNotas.nota1}
            />
          }
        </div>
      );
    } else {
      rootNotas.render(<SinNotas></SinNotas>);
    }
  };

  //Componente de progreso
  const obtProgresoPantalla = async (dataPantalla) => {
    if (
      dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
      dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
    ) {
      var urlActual = window.location.pathname;
      let credito = localStorage.getItem("idCredito");

      let nombrePantalla = " Vivienda";
      let numeroPantalla = "3. ";

      let pantallaActualMenu = (
        <PasosAseguir
          numeroPantalla={<span className="numero-paso">{numeroPantalla}</span>}
          nombrePantalla={<span className="descripcion-paso">{nombrePantalla}</span>}
          urlActual={urlActual}
          credito={credito}
        />
      );

      const root1 = ReactDOM.createRoot(
        document.getElementById("MenuRenderizado")
      );
      root1.render(pantallaActualMenu);

      validarUltimoProgreso(dataPantalla);
    }
  };

  const buscarIdPantalla = (objeto, valor) => {
    // Función para buscar la clave (propiedad) por el valor dado

    for (var clave in objeto) {
      if (objeto.hasOwnProperty(clave) && objeto[clave] === valor) {
        return clave;
      }
    }
    return null; // Si el valor no se encuentra, puedes devolver un valor predeterminado o null
  };

  function eliminarDiagonalPrincipalEnURL(url) {
    // Convierte la URL en un arreglo de caracteres para que sea más fácil manipular
    var caracteres = url.split("");
    var longitud = url.length;

    // Recorre la URL y elimina los caracteres en la diagonal principal ("/")
    for (var i = 0; i < caracteres.length; i++) {
      if (caracteres[i] === "/") {
        caracteres[i] = ""; // Elimina el carácter de diagonal
      }
    }

    // Une los caracteres nuevamente en una cadena
    var nuevaURL = caracteres.join("");
    return nuevaURL;
  }

  //Para verificar que no entre a una pantalla que no le corresponde segun el tipo de credito
  const validarUltimoProgreso = (dataPantalla) => {
    //Ultima pantalla
    let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
    //URL actual
    let urlActual = eliminarDiagonalPrincipalEnURL(window.location.pathname);

    //Si la url actual pertenece a una pantalla superior a la que tenemos detectada en bd entonces reedirigira
    //Hacia la ultima pantalla donde se quedo
    let idPantallaUrlActual = Object.keys(pantallasFlujoBanco).find(key => pantallasFlujoBanco[key].pantalla === urlActual);

    if (pantallasFlujoBanco[idPantallaUrlActual].paso > pantallasFlujoBanco[idPantallaActual].paso){
      navigate("/" + pantallasFlujoBanco[idPantallaActual].pantalla);
    }
  }

  //Para verificar si ya estan completas todas las secciones
  //TODO falta
  const verificarSeccionesCompletas = () => {
    const elementos = document.querySelectorAll("div"); // Selecciona todos los elementos con la clase "elemento"
    const claseBuscada = "documentoSubido"; // La clase que deseas verificar

    // Verifica si la clase buscada existe en al menos uno de los elementos
    const claseExiste = Array.from(elementos).some((elemento) =>
      elemento.classList.contains(claseBuscada)
    );

    if (claseExiste) {
      // console.log(
      //   `La clase ${claseBuscada} existe en al menos uno de los elementos.`
      // );
    } else {
      // console.log(
      //   `La clase ${claseBuscada} no existe en ninguno de los elementos.`
      // );
    }
  };

  const infoPantalla = async () => {
    let dataPantalla = await get(
      "getPantallaActual",
      localStorage.getItem("idResPrecalifica")
    );

    if (dataPantalla.statuscode === 200) {
      if (
        dataPantalla.infoProcesoPantalla.idCatPantallas != null ||
        dataPantalla.infoProcesoPantalla.idCatPantallas !== ""
      ) {
        let idPantallaActual = dataPantalla.infoProcesoPantalla.idCatPantallas;
        let nombrePantalla = ` ${descripcionPaso}`;
        let numeroPantalla = `${numeroPaso}`;
        let numeroPaso;
        let descripcionPaso;

        switch (idPantallaActual) {
          case 1:
            numeroPaso = "1.";
            descripcionPaso = " Descargar solicitud";
            break;
          case 2:
            numeroPaso = "2.";
            descripcionPaso = " Aceptar solicitud";
            break;
          case 3:
            numeroPaso = "3.";
            descripcionPaso = " Vivienda";
            break;
          case 4:
            numeroPaso = "4.";
            descripcionPaso = " Notaria";
            break;
          case 5:
            numeroPaso = "5.";
            descripcionPaso = " Institución Externa";
            break;
          case 6:
            numeroPaso = "6.";
            descripcionPaso = " Cierre y formalización";
            break;
          case 7:
            numeroPaso = "7.";
            descripcionPaso = " Felicidades";
            break;
          default:
            break;
        }

        const root = ReactDOM.createRoot(
          document.getElementById("MenuRenderizado")
        );

        const pantallaActualMenu = (
          <PasosAseguir
            numeroPantalla={
              <span className="numero-paso">{numeroPantalla}</span>
            }
            nombrePantalla={
              <span className="descripcion-paso">{nombrePantalla}</span>
            }
            idPantalla={idPantallaActual}
          />
        );
        root.render(pantallaActualMenu);

        // console.log(dataPantalla.infoProcesoPantalla.idPantallaActual);
      }
    }
  };

  const siguiente = async () => {
    
    let params2 = {
      idResPrecalifica: parseInt(idResPrecalifica),
      idCuestionario: parseInt(idCuestionarioSolicitud),
      idUser: parseInt(cookies.get("id"))
    };
    let enviarInformacion = await post("enviarInformacionCompletaViviendaOnbase", params2);

    if(enviarInformacion.statuscode === 200){
      dispararCorreo();
      dispararCorreoCliente();
      //await guardarPaso(4, idCuestionarioSolicitud, idResPrecalifica);
      window.location.href = "/informacionEnviada";
    }
    
  };

  const dispararCorreo = async () => {
    //Ejecutivo
    let paramsEjecutivo = {
      email: localStorage.getItem("correoEjecutivo"),
      tipo: 13,
    };
    console.log(paramsEjecutivo);
    let mandarCorreo = await post("enviarCorreos", paramsEjecutivo);
    if (mandarCorreo.statuscode === 200) console.log("");
  };

  const dispararCorreoCliente = async () => {
    //Cliente
    let paramsCliente = {
      email: cookies.get("correo"),
      tipo: 18,
    };
    // console.log(paramsCliente);
    let mandarCorreoCliente = await post("enviarEmail", paramsCliente);
    if (mandarCorreoCliente.statuscode === 200) console.log("");
  };


  //JPB Verifica Las secciones subidas
  const evaluarInfoSecciones = async () => {
    let documentacionVivienda = await get(
      "getVerificarDocumentacionVivienda",
      localStorage.getItem("idResPrecalifica")
    );
    let vendedorVivienda = await get(
      "getVerificarVendedorVivienda",
      localStorage.getItem("idResPrecalifica")
    );
    let pagoAvaluo = await get(
      "getVerificarPagoAvaluo",
      localStorage.getItem("idResPrecalifica")
    );
    let pantallaAcutal = await get(
      "getPantallaActual",
      localStorage.getItem("idResPrecalifica")
    );

    if (documentacionVivienda.statuscode === 200 && documentacionVivienda.completo === true &&
      pagoAvaluo.statuscode === 200 && pagoAvaluo.completo === true &&
      vendedorVivienda.statuscode === 200 && vendedorVivienda.completo === true &&
      pantallaAcutal.infoProcesoPantalla.idCatPantallas === 3) {
      setOpenInfoSubida(true)
    }
    else {
      setOpenInfoSubida(false)
    }
  }

  useEffect(() => {
    const cargarDatos = async () => {
      try {
        await obtEmpresaUrl();
        evaluarInfoSecciones();
        setIsLoading(false);
      } catch (error) {
        console.error("Error al cargar datos:", error);
        setIsLoading(false);
      }
    };

    cargarDatos();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    infoPantalla();
    verificarSeccionesCompletas();
    obtPantallaActual();
    comprobarAcceso();
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner"></div>
          <div className="loading-text">Cargando...</div>
        </div>
      )}
      {/* esto agregue pero marca mal el menu */}
      <div className=" cont_Info ">
        <Link to="/home" className="link linkGrande back">
          {" "}
          {"<"} Regresar a mis solicitudes
        </Link>

        <div id="MenuRenderizado" className="MenuRenderizado"></div>

        <h1>Documentos de vivienda</h1>

        <p>
          Hemos notificado al banco tu decisi&oacute;n de continuar y ahora
          necesitamos que nos compartas los siguientes documentos de la vivienda
          que deseas adquirir.
        </p>

        <div className=" cont_Info  docVivienda">
          <div className="cont_flex con_flex_wrap responsivoColumn">
            .
            <TarjetaDocumentosVivienda
              nombre={"Información y documentación del vendedor"}
              enlace={"infoVendedorVivienda"}
              tipo={1}
            ></TarjetaDocumentosVivienda>
            <TarjetaDocumentosVivienda
              nombre={"Documentacion de la vivienda"}
              enlace={"documentacionViviendaDocs"}
              tipo={2}
            ></TarjetaDocumentosVivienda>
            <TarjetaDocumentosVivienda
              nombre={"Pago de avaluo"}
              enlace={"pagoEvaluo"}
              tipo={3}
            ></TarjetaDocumentosVivienda>
          </div>

          <h3 className="titt notas">Notas</h3>
          <hr />

          <div id="notas"></div>
          <br />

          <a
            className="btn "
            onClick={() => {
              siguiente();
            }}
            disabled={botonDeshabilitado}
          >
            Enviar{" "}
          </a>
        </div>
      </div>
      {
        //<ModalInformacionSubida open={openInfoSubida} close={() => setOpenInfoSubida(false)} />
      }
    </>
  );
}
